import "./onboarding-shell.scss";

import { Routes, Route } from "react-router-dom";

import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router";

import Homepage from "../homepage/homepage";
import SplashScreen from "../splash-screen/splash-screen";
import WhatYouNeed from "../what-you-need/what-you-need";
import Walkthrough from "../walkthrough/walkthrough";
import ResumeEvaluation from "../resume-evaluation/resume-evaluation";
import Start from "../start/start";
import Impact from "../impact/impact";
import More from "../more/more";
import ContactUs from "../onboarding/feature/contact-us/contact-us";
import FAQ from "../faq/faq";
import OurStory from "../our-story/our-story";
import OurManifesto from "../our-manifesto/our-manifesto";
import Languages from "../languages/languages";
import PrivacyPolicy from "../privacy-policy/privacy-policy";
import ThankYou from "../thank-you/thank-you";
import TermsOfServices from "../terms-of-services/terms-of-services";
import DeviceOwnership from "../device-ownership/device-ownership";
import Login from "../login/login";
import DeviceInfo from "../device-info/device-info";
import IMEI from "../../../evaluation/feature/imei/imei";

export function OnboardingShell() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const getMode = urlSearchParams.get("mode");

    if (getMode !== null && getMode === "standalone") {
      const walkthroughKey = localStorage.getItem("walkthrough-complete");
      if (walkthroughKey === "1") navigate("/start", { replace: true });
      else navigate("/walkthrough", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route path="/" element={<SplashScreen />} />
      <Route path="/from-session/:sessionId" element={<SplashScreen />} />
      <Route path={`/imei`} element={<IMEI />} />
      <Route path="/start" element={<Start />} />
      <Route path="/login" element={<Login />} />
      <Route path="/device-info" element={<DeviceInfo />} />
      <Route path="/home" element={<Homepage />} />
      <Route path="/what-you-need" element={<WhatYouNeed />} />
      <Route path="/resume-evaluation" element={<ResumeEvaluation />} />
      <Route path="/walkthrough" element={<Walkthrough />} />
      <Route path="/impact" element={<Impact />} />
      <Route path="/more" element={<More />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/faq" element={<FAQ />} />
      <Route path="/our-story" element={<OurStory />} />
      <Route path="/our-manifesto" element={<OurManifesto />} />
      <Route path="/languages" element={<Languages />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-services" element={<TermsOfServices />} />
      <Route path="/device-ownership" element={<DeviceOwnership />} />
      <Route path="/thank-you" element={<ThankYou />} />
    </Routes>
  );
}

export default OnboardingShell;
