import { useContext } from "react";
import { AuthContext } from "../../../utils/context";
import "./steps-progress.scss";
export interface StepsProgressProps {
  step: number;
  total: number;
}

export function StepsProgress({ total, step }: StepsProgressProps) {
  const { configuration } = useContext(AuthContext);

  const BACKGROUND_PROGRESS = `data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='${configuration.colors.primary.replace(
    "#",
    "%23"
  )}' stroke-width='14' stroke-dasharray='${
    (252 / total) * step
  }%2c 640' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e`;

  return (
    <div
      className="steps-progress-container"
      style={{ backgroundImage: `url("${BACKGROUND_PROGRESS}")` }}
    >
      <p className="steps-progress-content">
        {step}
        <span>/{total}</span>
      </p>
    </div>
  );
}

export default StepsProgress;
