import "./image-preview.scss";

import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { ButtonAction } from "@greenpanda/ui/button-action";
import {
  lastStepIndexSelector,
  frontCameraSelector,
  backCameraSelector,
} from "../../data-access/evaluation-reducer";
import { uploadPhotoOnAmazonS3 } from "../../data-access/evaluation-service";
import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { ButtonBack } from "@greenpanda/ui/button-back";
import { useTranslation } from "react-i18next";
import { nextStep } from "../../../utils/next-step";
import { AuthContext } from "../../../utils/context";
import { goToStartPage } from "../../../utils/go-to-start-page";

export function ImagePreview() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { configuration, sessionId } = useContext(AuthContext);

  const LAST_INDEX = useSelector(lastStepIndexSelector);

  const lastStepIndex = useSelector(lastStepIndexSelector);
  const frontCamera = useSelector(frontCameraSelector);
  const backCamera = useSelector(backCameraSelector);

  useEffect(() => {
    if (lastStepIndex === -1)
      navigate(goToStartPage(configuration, sessionId), { replace: true });
  }, [lastStepIndex, navigate, configuration, sessionId]);

  const handleRetake = (): void => {
    if (lastStepIndex === configuration.assessments.indexOf("front-camera") + 1)
      navigate("../evaluate-front-camera");
    else if (
      lastStepIndex ===
      configuration.assessments.indexOf("back-camera") + 1
    )
      navigate("../evaluate-back-camera");
  };

  const handleSend = (): void => {
    if (
      lastStepIndex === configuration.assessments.indexOf("front-camera") + 1 &&
      typeof frontCamera.photoURL !== "undefined"
    ) {
      uploadPhotoOnAmazonS3(frontCamera.photoURL, sessionId);
      navigate(nextStep(configuration, LAST_INDEX));
    } else if (
      lastStepIndex === configuration.assessments.indexOf("back-camera") + 1 &&
      typeof backCamera.photoURL !== "undefined"
    ) {
      uploadPhotoOnAmazonS3(backCamera.photoURL, sessionId);
      navigate(nextStep(configuration, LAST_INDEX));
    }
  };

  return (
    <div id="image-preview-page">
      <div className="container">
        <NavbarHeader>
          <ButtonBack
            backButtonType="link"
            to="../evaluate-front-camera"
            iconType="close"
            imageLink={configuration.images_link}
          />
        </NavbarHeader>
        <div id="content">
          <h3>
            {t("image_preview_page.heading")}{" "}
            <span className="tooltip">
              <img
                src="/assets/images/icons/info-icon.svg"
                alt={t("image_preview_page.tooltip_alt")}
              />
              <span className="tooltip-text">
                {t("image_preview_page.tooltip_message")}
              </span>
            </span>{" "}
          </h3>
          {lastStepIndex ===
            configuration.assessments.indexOf("front-camera") + 1 && (
            <img
              className="image-taken"
              src={frontCamera.photoURL}
              alt="Preview for front camera"
            />
          )}
          {lastStepIndex ===
            configuration.assessments.indexOf("back-camera") + 1 && (
            <img
              className="image-taken"
              src={backCamera.photoURL}
              alt="Preview for back camera"
            />
          )}
          <div id="buttons">
            <ButtonAction
              onClick={() => handleSend()}
              primary={true}
              buttonType="button"
              outline={false}
            >
              {t("image_preview_page.save")}
            </ButtonAction>
            <ButtonAction
              onClick={() => handleRetake()}
              primary={false}
              buttonType="button"
              outline={false}
            >
              {t("image_preview_page.retake")}
            </ButtonAction>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImagePreview;
