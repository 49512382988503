import { getWebQuote, postWebQuoteParts } from "./tradein-service";
import { createWebQuoteParts } from "./create-web-quote-parts";
import { Offer } from "./tradein-reducer";
import { ConfigurationResponse } from "../../configuration/configuration-response";
import { DeviceAssessmentsData } from "../../evaluation/data-access/evaluation-service";

export const calculateWebQuote = async (
  configuration: ConfigurationResponse,
  offer: Offer,
  deviceAssessmentsData: DeviceAssessmentsData
) => {
  let webQuote;
  if (configuration.payment_options.parts)
    webQuote = await postWebQuoteParts({
      brand: offer.brand,
      model: offer.model,
      flow: configuration.partner.flow,
      category: "smartphone",
      capacity: offer.capacity!,
      parts: createWebQuoteParts(deviceAssessmentsData, configuration),
    });
  else
    webQuote = await getWebQuote({
      brand: offer.brand.toLowerCase()!,
      model: offer.model.toLowerCase()!,
      capacity: offer.capacity!,
      condition: offer.condition!,
      flow: configuration.partner.flow,
      sort_order: "max",
    });

  if (webQuote.offers) {
    const cashOffers = webQuote.offers
      ? webQuote.offers.filter((el) => el.type === "cash")
      : [];
    cashOffers.sort((a, b) => (a.price > b.price ? -1 : 1));

    const voucherOffers = webQuote.offers
      ? webQuote.offers.filter((el) => el.type === "voucher")
      : [];
    voucherOffers.sort((a, b) => (a.price > b.price ? -1 : 1));
    return { voucher: voucherOffers[0].price, cash: cashOffers[0].price };
  } else {
    return null;
  }
};
