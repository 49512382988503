import "./evaluation-shell.scss";

import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";

import FrontCamera from "../front-camera/front-camera";
import AllowCamera from "../allow-camera/allow-camera";
import SuccessMessage from "../success-message/success-message";
import BackCamera from "../back-camera/back-camera";
import TestCamera from "../test-camera/test-camera";
import TestBackCamera from "../test-back-camera/test-back-camera";
import Digitizer from "../digitizer/digitizer";
import TestDigitizer from "../test-digitizer/test-digitizer";
import SoundPerformance from "../sound-peformance/sound-performance";
import AllowMicrophone from "../allow-microphone/allow-microphone";
import TestSoundPerformance from "../test-sound-performance/test-sound-performance";
import AllowFaceId from "../allow-face-id/allow-face-id";
import FaceId from "../face-id/face-id";
import TestFaceId from "../test-face-id/test-face-id";
import EvaluationReport from "../evaluation-report/evaluation-report";
import IMEI from "../imei/imei";
import GetQuote from "../get-quote/get-quote";
import StatusMessage from "../status-message/status-message";
import ImagePreview from "../image-preview/image-preview";

import { tradeInOfferSelector } from "../../../tradein/data-access/tradein-reducer";
import { useTranslation } from "react-i18next";
import LoadingPage from "../imei/loading";
import Screen from "../screen/screen";
import BodyFrame from "../body-frame/body-frame";
import TestBodyFrame from "../test-body-frame/test-body-frame";
import TestScreen from "../test-screen/test-screen";
import TestYellowings from "../test-yellowings/test-yellowings";
import DeviceMotion from "../device-motion/device-motion";

import UserInfo from "../user-info/user-info";
import { lazy, useContext } from "react";
import { AuthContext } from "../../../utils/context";

const TestDeviceMotion = lazy(
  () => import("../test-device-motion/test-device-motion")
);

export function EvaluationShell() {
  const { t } = useTranslation();
  const { configuration } = useContext(AuthContext);

  const tradeInOffer = useSelector(tradeInOfferSelector);

  const STATUS_MESSAGE_1 = t("status_message_shell.message_1");
  const STATUS_MESSAGE_2 = t("status_message_shell.message_2");

  return (
    <Routes>
      <Route path="/allow-camera" element={<AllowCamera />} />
      <Route path="/allow-microphone" element={<AllowMicrophone />} />
      <Route path="/allow-face-id" element={<AllowFaceId />} />
      <Route path="/front-camera" element={<FrontCamera />} />
      <Route path="/back-camera" element={<BackCamera />} />
      <Route path="/digitizer" element={<Digitizer />} />
      <Route path="/sound-performance" element={<SoundPerformance />} />
      <Route path="/face-id" element={<FaceId />} />
      <Route path="/device-motion" element={<DeviceMotion />} />
      <Route path="/screen" element={<Screen />} />
      <Route path="/evaluate-yellowings" element={<TestYellowings />} />
      <Route path="/evaluate-screen" element={<TestScreen />} />
      <Route path="/body-frame" element={<BodyFrame />} />
      <Route path="/evaluate-body-frame" element={<TestBodyFrame />} />
      <Route path="/imei" element={<IMEI />} />
      {tradeInOffer && (
        <Route
          path="/device-identified"
          element={
            <StatusMessage
              link="../getting-quote"
              title={STATUS_MESSAGE_1}
              imgSrc={`${configuration.images_link}/images/device-identified.svg`}
              imgDefault="/assets/images/device-identified.svg"
              device={`${tradeInOffer.brand} ${tradeInOffer.model}`}
              specs={`${
                tradeInOffer.capacity === null ? "" : tradeInOffer.capacity
              } ${
                typeof tradeInOffer.color === "undefined"
                  ? ""
                  : tradeInOffer.color
              }`}
              marginTop={86}
              timeout={3000}
            />
          }
        />
      )}
      <Route
        path="/getting-quote"
        element={
          <StatusMessage
            link={`${
              configuration.email_submission
                ? "../get-quote"
                : `/${configuration.partner.code}/trade-in/offer`
            }`}
            title={STATUS_MESSAGE_2}
            videoSrc={`${configuration.images_link}/animations/like-heart.mp4`}
            videoDefault="/assets/animations/like-heart.mp4"
            timeout={3000}
          />
        }
      />
      <Route path="/get-quote" element={<GetQuote />} />
      <Route path="/user-info" element={<UserInfo />} />
      <Route path="/evaluate-front-camera" element={<TestCamera />} />
      <Route path="/evaluate-back-camera" element={<TestBackCamera />} />
      <Route path="/evaluate-digitizer" element={<TestDigitizer />} />
      <Route path="/loading" element={<LoadingPage />} />

      <Route
        path="/evaluate-sound-performance"
        element={<TestSoundPerformance />}
      />
      <Route path="/evaluate-face-id" element={<TestFaceId />} />
      <Route path="/evaluate-device-motion" element={<TestDeviceMotion />} />
      <Route
        path="/device-motion-success"
        element={
          <SuccessMessage
            emoji="👏"
            text={t("success_messages.device_motion")}
            timeout={3000}
          />
        }
      />
      <Route
        path="/front-camera-success"
        element={
          <SuccessMessage
            emoji="😁"
            text={t("success_messages.front_camera")}
            reverse={configuration.mirror ? true : false}
            timeout={3000}
          />
        }
      />
      <Route
        path="/back-camera-success"
        element={
          <SuccessMessage
            emoji="🙌"
            text={t("success_messages.back_camera")}
            timeout={3000}
          />
        }
      />
      <Route
        path="/digitizer-success"
        element={
          <SuccessMessage
            emoji="👏"
            text={t("success_messages.digitizer")}
            timeout={3000}
          />
        }
      />
      <Route
        path="/sound-performance-success"
        element={
          <SuccessMessage
            emoji="🤘"
            text={t("success_messages.sound")}
            timeout={3000}
          />
        }
      />
      <Route
        path="/face-id-success"
        element={
          <SuccessMessage
            emoji="😃"
            text={t("success_messages.face_id")}
            timeout={3000}
          />
        }
      />
      <Route
        path="/device-condition-success"
        element={
          <SuccessMessage
            emoji="🙌"
            text={t("success_messages.device_condition")}
            timeout={3000}
          />
        }
      />
      <Route path="/evaluation-report" element={<EvaluationReport />} />
      <Route path="/image-preview" element={<ImagePreview />} />
    </Routes>
  );
}

export default EvaluationShell;
