import { BBox, lengthToDegrees } from "@turf/helpers";

import { isStabilized } from "../../evaluation/utils/is-stabilized";

export default function isStabilizedGuard(
  { bbox }: { bbox: [number, number, number, number] },
  notify?: (status: string) => void
): boolean {
  const bboxMap: BBox = bbox.map((val) =>
    lengthToDegrees(val, "kilometers")
  ) as BBox;
  const value: boolean = isStabilized(bboxMap);

  notify?.("is_stabilized_guard");

  return value;
}
