import { useTranslation } from "react-i18next";

export function RevealImeiHelpList() {
  const { t } = useTranslation();

  const helpList = [
    {
      step: 1,
      text: t("reveal_imei_page.help_list.step_1"),
    },
    { step: 2, text: t("reveal_imei_page.help_list.step_2") },
  ];
  return (
    <div className="help-container">
      <div>
        <p className="next-steps">{t("reveal_imei_page.help_list.title")}</p>
        {helpList.map((element, index) => {
          return (
            <div key={index} className="step-container">
              <div>
                <p className="step">{element.step}</p>
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: element.text,
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default RevealImeiHelpList;
