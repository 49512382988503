import "./privacy-message.scss";
export interface PrivacyMessageProps {
  message: string;
  customIcon?: string;
}

export function PrivacyMessage({ message, customIcon }: PrivacyMessageProps) {
  return (
    <div className="privacy-message">
      <img
        src={customIcon ? customIcon : "/assets/images/icons/privacy-icon.svg"}
        alt=""
      />
      <p>{message}</p>
    </div>
  );
}

export default PrivacyMessage;
