import "./test-screen.scss";

import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import DeviceCondition from "../../ui/device-condition/device-condition";
import { useNavigate } from "react-router-dom";
import { evaluationActions } from "../../data-access/evaluation-reducer";
import { useDispatch } from "react-redux";
import TagManager from "react-gtm-module";
import { AuthContext } from "../../../utils/context";

const tagManagerArgs = {
  dataLayer: {
    event: "Step Evaluation Screen",
  },
};

export function TestScreen() {
  TagManager.dataLayer(tagManagerArgs);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { configuration } = useContext(AuthContext);

  const [yellowings, setYellowings] = useState<boolean | undefined>(undefined);

  const handleContinue = () => {
    if (!yellowings) dispatch(evaluationActions.setScreenPassed(true));

    dispatch(
      evaluationActions.setCurrentStepIndex(
        configuration.assessments.indexOf("screen") + 1
      )
    );
    navigate("../device-condition-success");
  };

  return (
    <DeviceCondition
      title={t("test_screen_page.title")}
      perfectButtonOnClick={() => setYellowings(false)}
      damagedButtonOnClick={() => setYellowings(true)}
      perfectButtonDescription={t("test_screen_page.perfect_description")}
      damageButtonDescription={t("test_screen_page.damaged_description")}
      checked={yellowings}
      onClick={handleContinue}
      disabled={yellowings === undefined}
      tryAgain
    />
  );
}

export default TestScreen;
