import "./test-yellowings.scss";

import { useEffect, useState } from "react";
import Instructions from "../../ui/instructions/instructions";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

export function TestYellowings() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const initialMinute = 0;
  const initialSeconds = 10;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  const [instructions, setInstructions] = useState(true);

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (!instructions && seconds > 0) setSeconds(seconds - 1);

      if (seconds === 0)
        if (minutes === 0) {
          clearInterval(myInterval);
          navigate("../evaluate-screen");
        } else {
          setMinutes(minutes - 1);
          setSeconds(0);
        }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const handleCountdown = () => {
    setSeconds(9);
    setInstructions(false);
  };

  return (
    <div className="feature-page test-yellowings">
      <div className="container">
        <div id="timer">
          <p>
            {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </p>
        </div>
        {instructions && (
          <div className="screen-check" onClick={handleCountdown}>
            <img src="/assets/images/looking-glass.svg" alt="" />
            <Instructions
              title={t("test_yellowings_page.title")}
              description={t("test_yellowings_page.description")}
            />
            <p>{t("test_yellowings_page.check_yellowings")}</p>
          </div>
        )}
        {!instructions && (
          <div className="button-container">
            <Link to="../evaluate-screen" style={{ textAlign: "center" }}>
              {t("test_yellowings_page.end_evaluation")}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default TestYellowings;
