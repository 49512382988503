import { ConfigurationResponse } from "../configuration/configuration-response";

export const goToStartPage = (
  configuration: ConfigurationResponse,
  sessionId: string
) => {
  if (configuration.at_store_flow)
    return `/${configuration.partner.code}/${sessionId}`;
  else return `/${configuration.partner.code}`;
};
