export default function calculateBrushRadius(
  window: { width: number; height: number },
  mouse: { X: number; Y: number }
): number {
  const topLimit = (window.height * 10) / 100;
  const bottomLimit = (window.height * 90) / 100;
  const leftLimit = (window.width * 10) / 100;
  const rightLimit = (window.width * 90) / 100;
  const centerLimit = ((window.height - topLimit * 2) * 70) / 100;

  let brushRadius =
    window.width > window.height ? window.width / 100 : window.height / 100;

  if (
    mouse.Y > topLimit &&
    mouse.Y < bottomLimit &&
    mouse.X > leftLimit &&
    mouse.X < rightLimit
  ) {
    if (mouse.Y < centerLimit)
      // Top center limit
      brushRadius *= 8;
    else if (mouse.Y > centerLimit)
      // Bottom center limit
      brushRadius *= 4;
  } else if (
    mouse.Y < topLimit &&
    mouse.X > leftLimit &&
    mouse.X < rightLimit
  ) {
    // Top limit
    brushRadius *= 4;
  } else if (mouse.X > rightLimit) {
    // Right limit
    brushRadius *= 4;
  } else if (
    mouse.Y > bottomLimit &&
    mouse.X > leftLimit &&
    mouse.X < rightLimit
  ) {
    // Bottom limit
    brushRadius *= 4;
  } else if (mouse.X < leftLimit) {
    // Left limit
    brushRadius *= 4;
  }

  return brushRadius;
}
