export const userAddress = (
  components: Array<google.maps.GeocoderAddressComponent>,
  position: { latitude: number; longitude: number }
) => {
  const name =
    components
      .filter(
        (c) =>
          !(
            c.types.includes("postal_code") ||
            c.types.includes("country") ||
            c.types.includes("locality")
          )
      )
      .map((c) => c.long_name)
      .join(" ") ?? "";
  return {
    postalCode:
      components.find((c) => c.types.includes("postal_code"))?.long_name ?? "",
    country:
      components.find((c) => c.types.includes("country"))?.long_name ?? "",
    city: components.find((c) => c.types.includes("locality"))?.long_name ?? "",
    coordinates: position,
    name,
  };
};
