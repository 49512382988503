export interface Position {
  x: number;
  y: number;
}

export default function getBrushPosition(
  canvasRefCurrent: HTMLCanvasElement,
  boundingClientRect: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  },
  client: Position
): Position {
  return {
    x: Math.floor(
      ((client.x - boundingClientRect.left) /
        (boundingClientRect.right - boundingClientRect.left)) *
        canvasRefCurrent.width
    ),
    y: Math.floor(
      ((client.y - boundingClientRect.top) /
        (boundingClientRect.bottom - boundingClientRect.top)) *
        canvasRefCurrent.height
    ),
  };
}
