import { Upload } from "@aws-sdk/lib-storage";
import { S3Client } from "@aws-sdk/client-s3";
import { AMAZON_S3 } from "../../../constants";

export interface UploadPhotoOnServer {
  url: string;
}

export interface DeviceAssessmentsData {
  sessionId: string;
  imei: string;
  brand: string;
  model: string;
  capacity: string;
  partner: string;
  digitizer: number | null;
  sound: number | null;
  biometrics: number | null;
  screenCondition: number | null;
  bodyFrame: number | null;
  frontCamera: number | null;
  backCamera: number | null;
  frontCosmetics?: number;
  backCosmetics?: number;
  sideCosmetics?: number;
  cameraCosmetics?: number;
  userInput?: string | null;
  metadata?: { [key: string]: string };
}

export interface DeviceAssessmentsResponse {
  result: "success" | "error";
}
export interface EmailOfferData {
  email: string;
  brand: string;
  model: string;
  price: number;
  cashPrice: number;
  voucherPrice: number;
  promo: number;
  condition: string;
  capacity: string;
  lang: string;
  partner: string;
  category: string;
  link: string;
}
export interface EmailOfferResponse {
  success: 1;
}

async function uploadBlobOnAmazonS3(
  blob: Blob,
  extension: string,
  sessionId: string
) {
  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  const date = new Date();
  const fullYear = date.getFullYear().toString();
  const month = months[date.getMonth()];
  const day = date.getDate().toString();
  try {
    const amazonS3Client = new S3Client({
      region: AMAZON_S3.region,
      credentials: {
        accessKeyId: AMAZON_S3.accessKeyId,
        secretAccessKey: AMAZON_S3.secretAccessKey,
      },
    });

    const amazonS3Directory = `${fullYear}-${month}-${day}-${sessionId}`;

    const parallelUploads3 = new Upload({
      client: amazonS3Client,
      params: {
        Bucket: AMAZON_S3.bucket,
        Key: `${
          amazonS3Directory !== null
            ? `${amazonS3Directory.split("-").join("/")}/`
            : ""
        }${Date.now()}.${extension}`,
        Body: blob,
      },
    });

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    parallelUploads3.on("httpUploadProgress", () => {});

    await parallelUploads3.done();
  } catch (e) {
    return e;
  }
}

export async function uploadPhotoOnAmazonS3(photo: string, sessionId: string) {
  const fetchImageUrl = await fetch(photo);
  const fetchImageUrlBlob = await fetchImageUrl.blob();

  uploadBlobOnAmazonS3(fetchImageUrlBlob, "png", sessionId);
}

export async function uploadSoundWavOnAmazonS3(
  audioBlob: Blob,
  sessionId: string
) {
  uploadBlobOnAmazonS3(audioBlob, "wav", sessionId);
}

export async function postDeviceAssessments(
  data: Partial<DeviceAssessmentsData>
): Promise<DeviceAssessmentsResponse> {
  const response = await fetch(
    `${process.env["NX_IMEI_SERVICE_URL"]}/device-assessments`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );

  return response.json();
}

export async function postEmailOffer(
  data: EmailOfferData,
  flow: string
): Promise<EmailOfferResponse> {
  const response = await fetch(
    `${process.env["NX_COMMUNICATION_SERVICE_URL"]}/webquote/${flow}/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );

  return response.json();
}
