import "./what-you-need.scss";

import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { ButtonBack } from "@greenpanda/ui/button-back";
import { TopTitle } from "@greenpanda/ui/top-title";
import { ListItem } from "@greenpanda/ui/list-item";
import { ButtonAction } from "@greenpanda/ui/button-action";
import { useDispatch, useSelector } from "react-redux";
import {
  evaluationActions,
  lastStepIndexSelector,
} from "../../../evaluation/data-access/evaluation-reducer";
import { useContext, useEffect } from "react";
import TagManager from "react-gtm-module";
import { WURFL } from "../../../../constants";
import { AuthContext } from "../../../utils/context";

const tagManagerArgs = {
  dataLayer: {
    event: "Step What You Will Need",
  },
};

export function WhatYouNeed() {
  TagManager.dataLayer(tagManagerArgs);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { configuration } = useContext(AuthContext);
  const LAST_INDEX = useSelector(lastStepIndexSelector);

  useEffect(() => {
    dispatch(evaluationActions.setCurrentStepIndex(0));
  }, [dispatch]);

  return (
    <div className="what-you-need-page">
      <div className="container">
        <NavbarHeader>
          <ButtonBack
            iconType="back"
            backButtonType="button"
            onClick={() => navigate(-1)}
            imageLink={configuration.images_link}
          />
        </NavbarHeader>
        <TopTitle
          subtitle={t("what_you_need_page.subtitle")}
          title={`${t("what_you_need_page.title")} ${WURFL.brand_name} ${
            WURFL.model_name
          }`}
        />
        <div className="list-container">
          <ListItem
            imgSrc="/assets/images/icons/fingerprint-icon.svg"
            title={t("what_you_need_page.list_item_1.title")}
          />
          {configuration.mirror && (
            <ListItem
              imgSrc="/assets/images/icons/mirror-icon.svg"
              title={t("what_you_need_page.list_item_2.title")}
              description={t("what_you_need_page.list_item_2.description")}
            />
          )}
          <ListItem
            imgSrc="/assets/images/icons/fast-icon.svg"
            title={t("what_you_need_page.list_item_3.title")}
          />
          <ListItem
            imgSrc="/assets/images/icons/disconnect-bluetooth-icon.svg"
            title={t("what_you_need_page.list_item_4.title")}
          />
        </div>
        <div className="button-container">
          <ButtonAction
            primary={true}
            buttonType="link"
            to={`../evaluation/${configuration.assessments[LAST_INDEX]}`}
          >
            {t("what_you_need_page.lets_start")}
          </ButtonAction>
          <div className="footer-text">
            <p
              dangerouslySetInnerHTML={{
                __html: t("what_you_need_page.footer_text"),
              }}
            />
            <Link to="../privacy-policy" title="Privach Policy">
              {t("what_you_need_page.privacy_policy_link")}
            </Link>
            .
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhatYouNeed;
