import { useEffect } from "react";

export const prevent = (event: Event) => {
  event.preventDefault();
};

export function usePreventContextMenu() {
  useEffect(() => {
    document.addEventListener("contextmenu", prevent, false);

    return () => {
      document.removeEventListener("contextmenu", prevent, false);
    };
  }, []);
}
