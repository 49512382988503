import "./modal.scss";

import { ButtonAction } from "@greenpanda/ui/button-action";

export interface ModalProps {
  buttonPrimaryContent?: undefined | string;
  buttonLinkContent?: string;
  buttonPrimaryType?: undefined | "button" | "link";
  buttonLinkType?: undefined | "button" | "link";
  buttonPrimaryLink?: string;
  buttonPrimaryHandler?: () => void;
  buttonLinkLink?: string;
  buttonLinkHandler?: () => void;
  firstButtonIsPrimary?: boolean;
  firstButtonIsOutline?: boolean;
  secondButtonIsPrimary?: boolean;
  secondButtonIsOutline?: boolean;
  children: JSX.Element | Array<JSX.Element> | string | Array<string>;
}

export function Modal({
  buttonPrimaryContent,
  buttonLinkContent,
  buttonPrimaryType,
  buttonLinkType,
  buttonPrimaryLink,
  buttonPrimaryHandler,
  buttonLinkLink,
  buttonLinkHandler,
  firstButtonIsPrimary = true,
  firstButtonIsOutline = false,
  secondButtonIsPrimary = true,
  secondButtonIsOutline = true,
  children,
}: ModalProps) {
  return (
    <div className="modal">
      <div className="backdrop" />
      <div className="modal-container">
        <div className="content">{children}</div>
        <div className="footer">
          <div className="button-container">
            {typeof buttonPrimaryType !== "undefined" &&
              typeof buttonPrimaryContent !== "undefined" && (
                <ButtonAction
                  buttonType={buttonPrimaryType}
                  // @ts-ignore
                  to={buttonPrimaryLink}
                  onClick={buttonPrimaryHandler}
                  primary={firstButtonIsPrimary}
                  outline={firstButtonIsOutline}
                >
                  {buttonPrimaryContent}
                </ButtonAction>
              )}
            {buttonLinkContent && buttonLinkType && (
              <ButtonAction
                primary={secondButtonIsPrimary}
                onClick={buttonLinkHandler}
                // @ts-ignore
                to={buttonLinkLink}
                buttonType={buttonLinkType}
                outline={secondButtonIsOutline}
                style={{ marginTop: 13 }}
              >
                {buttonLinkContent}
              </ButtonAction>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
