export const BROWSER_VERSION_URL =
  "https://session-service.greenpanda.io/v1/browsers/latest";
export const ENCRYPT_PASSWORD = "w4xaY#$4Jpdc83IL";
export const AMAZON_S3 = {
  region: "eu-central-1",
  accessKeyId: "AKIASINBVZO3DYXTYNXD",
  secretAccessKey: "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",
  bucket: "pandas-filerepo",
};
export const WURFL =
  process.env["NX_ENVIRONMENT"] === "develop"
    ? ({
        brand_name: process.env.NX_WURFL_BRAND_NAME,
        model_name: process.env.NX_WURFL_MODEL_NAME,
        advertised_device_os: process.env["NX_WURFL_ADVERTISED_DEVICE_OS"],
        advertised_browser: process.env["NX_WURFL_ADVERTISED_BROWSER"],
        is_mobile: process.env["NX_WURFL_IS_MOBILE"] === "true",
      } as typeof window.WURFL)
    : ({
        ...window.WURFL,
      } as typeof window.WURFL);
export const GOOGLE_API_KEY = process.env["NX_GOOGLE_API_KEY"];
