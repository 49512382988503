import "./evaluation-item.scss";
export interface EvaluationItemProps {
  featureIcon: string;
  featureName: string;
  statusMessage: string;
  statusType: string;
}

export function EvaluationItem({
  featureIcon,
  featureName,
  statusMessage,
  statusType,
}: EvaluationItemProps) {
  return (
    <div className="evaluation-item">
      <img className="feature-icon" src={featureIcon} alt={featureName} />
      <div className="details">
        <p className="name">{featureName}</p>
        <p className={"status " + statusType}>{statusMessage}</p>
      </div>
      <img
        className="status-icon"
        src={"/assets/images/report-icons/status-" + statusType + ".svg"}
        alt={statusType}
      />
    </div>
  );
}

export default EvaluationItem;
