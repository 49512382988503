import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination } from "swiper";

import "./walkthrough.scss";

import CarouselItem from "../../ui/carousel-item/carousel-item";
import { ButtonAction } from "@greenpanda/ui/button-action";
import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { ButtonBack } from "@greenpanda/ui/button-back";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { AuthContext } from "../../../utils/context";

export function Walkthrough() {
  const { t } = useTranslation();
  const { configuration } = useContext(AuthContext);

  return (
    <div className="walkthrough-page">
      <NavbarHeader>
        <ButtonBack
          backButtonType="link"
          to="../start"
          iconType="back"
          imageLink={configuration.images_link}
        />
        <div className="title">{t("walkthrough_page.title_page")}</div>
      </NavbarHeader>
      <div className="swiper-container">
        <Swiper
          modules={[FreeMode, Pagination]}
          spaceBetween={20}
          effect={"coverflow"}
          slidesPerView={"auto"}
          freeMode={false}
          slidesOffsetAfter={20}
          pagination={{ clickable: true }}
        >
          <SwiperSlide>
            <CarouselItem
              adjustVideoSize={true}
              bgColor="#DBFAE7"
              videoSrc="/assets/animations/carousel-evaluation.mp4"
              title={t("walkthrough_page.slide_1.title")}
              description={t("walkthrough_page.slide_1.content")}
            />
          </SwiperSlide>
          <SwiperSlide>
            <CarouselItem
              bgColor="#FBEEA9"
              videoSrc="/assets/animations/carousel-trade-in.mp4"
              title={t("walkthrough_page.slide_2.title")}
              description={t("walkthrough_page.slide_2.content")}
            />
          </SwiperSlide>
          <SwiperSlide>
            <CarouselItem
              bgColor="#CE90FF80"
              videoSrc="/assets/animations/carousel-dropoff.mp4"
              title={t("walkthrough_page.slide_3.title")}
              description={t("walkthrough_page.slide_3.content")}
            />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="button-container">
        <ButtonAction primary={true} buttonType="link" to="../what-you-need">
          {t("common.start")}
        </ButtonAction>
      </div>
    </div>
  );
}

export default Walkthrough;
