import { ConfigurationResponse } from "../configuration-response";

export const configurationData = () => {
  const data: ConfigurationResponse = {
    partner: {
      name: "greenpanda",
      flow: "pandas",
      country: "gr",
      code: "mini-kiosk-pandas",
    },
    assessments: [
      "digitizer",
      "sound-performance",
      "face-id",
      "front-camera",
      "back-camera",
      "imei",
    ],
    max_capacity_price: false,
    payment_options: {
      parts: false,
      payment_methods: [],
      currency: {
        abbreviation: "€",
        code: "EUR",
      },
      promo: true,
    },
    at_store_flow: true,
    language: {
      default_lng: "en",
      endpoint: "languages-pandasgr",
    },
    colors: { primary: "#1A1A1A" },
    email_submission: false,
    tag_manager_code: "GTM-NK29H7F",
    drop_off_options: [],
    mirror: false,
    tutorial: true,
    impact: true,
    manifesto: true,
    our_story: true,
    faq: true,
    contact_us: true,
    contact_us_email:
      "https://hook.eu1.make.com/ee2twfux18a5pg1ei7n0kkzmd4h4agrz",
    social_media: {
      instagram: "https://www.instagram.com/pandas.io/",
      facebook: "https://www.facebook.com/pandasHQ/",
      youtube: "https://www.youtube.com/channel/UCZlgTN_61nkmUTS9LLi9yEg/",
      tiktok: "https://www.tiktok.com/@pandas_io/",
      linkedin: "https://www.linkedin.com/company/wearepandas/",
    },
    images_link: "/assets",
  };
  return data;
};
