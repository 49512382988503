import "./application-requirements.scss";
import { Modal } from "@greenpanda/ui/modal";
import { ConfirmMessage } from "@greenpanda/ui/confirm-message";
import CopyToClipboard from "react-copy-to-clipboard";
import QRCode from "react-qr-code";
import { ButtonAction } from "@greenpanda/ui/button-action";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../utils/context";
import { Case, requirementCases } from "./requirement-cases";

export function ApplicationRequirements() {
  const { t } = useTranslation();
  const { configuration } = useContext(AuthContext);
  const [currentCase, setCase] = useState<Case | null>(null);
  const [isOpen, openModal] = useState(false);
  const [copyLinkText, setCopyLinkText] = useState<
    "browser_fallback.mobile.button" | "browser_fallback.mobile.copied"
  >("browser_fallback.mobile.button");

  useEffect(() => {
    const checkCases = async () => {
      for (const c of requirementCases(configuration))
        if (await c.condition()) {
          setCase(c);
          openModal(true);
          break;
        }
    };

    if (process.env["NX_ENVIRONMENT"] !== "develop") checkCases();
  }, [configuration]);

  if (!isOpen || !currentCase) return null;

  return (
    <Modal>
      <ConfirmMessage
        iconLink={currentCase.iconLink}
        title={t(currentCase.message.title)}
        description={t(currentCase.message.description)}
      />
      {currentCase.hasQRCode ? (
        <QRCode
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px auto",
          }}
          size={128}
          value={window.location.href}
        />
      ) : (
        <span />
      )}
      {currentCase.hasClipboard ? (
        <CopyToClipboard
          text={window.location.href}
          onCopy={() => setCopyLinkText("browser_fallback.mobile.copied")}
        >
          <ButtonAction
            buttonType="button"
            primary={true}
            style={{
              marginTop: 14,
            }}
          >
            {t(copyLinkText)}
          </ButtonAction>
        </CopyToClipboard>
      ) : (
        <span />
      )}
      {currentCase.pricingButton ? (
        <a href="https://www.pandas.io/pricing/pandas">
          <ButtonAction buttonType="button" primary={true}>
            {t("browser_fallback.android.button")}
          </ButtonAction>
        </a>
      ) : (
        <span />
      )}
      {currentCase.canOpenStore ? (
        <a
          href={
            window.WURFL.advertised_device_os === "iOS"
              ? "https://www.apple.com/app-store/"
              : "https://play.google.com/store/apps"
          }
        >
          <ButtonAction
            buttonType="button"
            onClick={() => openModal(false)}
            primary={true}
          >
            {t("browser_update.update")}
          </ButtonAction>
        </a>
      ) : (
        <span />
      )}
      {currentCase.canSkip ? (
        <ButtonAction
          buttonType="button"
          onClick={() => window.location.reload()}
          primary={true}
          outline
        >
          {t("browser_update.updated")}
        </ButtonAction>
      ) : (
        <span />
      )}
    </Modal>
  );
}
