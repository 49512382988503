import "./button-action.scss";

import { ComponentProps } from "react";
import { Link, LinkProps } from "react-router-dom";

export interface ButtonActionCommonProps {
  outline?: boolean;
  small?: boolean;
  children: JSX.Element | Array<JSX.Element> | string | Array<string>;
  primary: boolean;
}

export interface ButtonActionProps extends ComponentProps<"button"> {
  buttonType: "button";
}

export interface LinkActionProps extends LinkProps {
  buttonType: "link";
}

export type Props = ButtonActionCommonProps &
  (ButtonActionProps | LinkActionProps);

export function ButtonAction({
  buttonType,
  outline,
  children,
  primary,
  small,
  ...restProps
}: Props) {
  const BUTTON_LINK_CLASS = primary
    ? "button-primary" + (outline ? " outline" : "") + (small ? " small" : "")
    : "button-secondary";

  return buttonType === "link" ? (
    <Link {...(restProps as LinkProps)} className={BUTTON_LINK_CLASS}>
      {children}
    </Link>
  ) : (
    <button
      {...(restProps as ComponentProps<"button">)}
      className={BUTTON_LINK_CLASS}
    >
      {children}
    </button>
  );
}

export default ButtonAction;
