import "./allow-face-id.scss";

import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { ButtonBack } from "@greenpanda/ui/button-back";
import { ConfirmMessage } from "@greenpanda/ui/confirm-message";
import { ButtonAction } from "@greenpanda/ui/button-action";
import PrivacyMessage from "../../ui/privacy-message/privacy-message";

import {
  evaluationActions,
  lastStepIndexSelector,
} from "../../data-access/evaluation-reducer";
import { useTranslation } from "react-i18next";
import { nextStep } from "../../../utils/next-step";
import { useContext, useState } from "react";
import { Modal } from "@greenpanda/ui/modal";
import { AuthContext } from "../../../utils/context";

export function AllowFaceId() {
  const [showFailModal, setShowFailModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { configuration } = useContext(AuthContext);
  const LAST_INDEX = useSelector(lastStepIndexSelector);

  const toggleShowModal = () => setShowFailModal((prev) => !prev);

  const skipFaceIDHandler = () => {
    dispatch(evaluationActions.setFaceIDPassed(false));
    navigate(nextStep(configuration, LAST_INDEX + 1));
  };

  const allowFaceIDHandler = async (): Promise<void> => {
    try {
      const isAvailable =
        await window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();

      if (isAvailable) navigate("../evaluate-face-id");
      else toggleShowModal();
    } catch (error) {
      toggleShowModal();
    }
  };

  return (
    <div className="allow-face-id-page">
      <div className="container">
        <NavbarHeader>
          <ButtonBack
            backButtonType="link"
            to="../face-id"
            iconType="back"
            imageLink={configuration.images_link}
          />
        </NavbarHeader>
        {showFailModal && (
          <Modal
            buttonPrimaryContent={t("common.try_again")}
            buttonLinkContent={t("common.skip_step")}
            buttonPrimaryHandler={() => {
              toggleShowModal();
              allowFaceIDHandler();
            }}
            buttonLinkType="button"
            buttonPrimaryType="button"
            buttonLinkHandler={skipFaceIDHandler}
          >
            <ConfirmMessage
              emoji="😔"
              title={t("test_face_id_page.skip_modal.title")}
              description={`${t("common.evaluation_failed")}. ${t(
                "test_face_id_page.skip_modal.description"
              )}`}
            />
          </Modal>
        )}
        <ConfirmMessage
          title={t("allow_face_id_page.title")}
          description={t("allow_face_id_page.description")}
        >
          <img
            src={`${configuration.images_link}/images/face-id-small.svg`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = "/assets/images/face-id-small.svg";
            }}
            alt="face-id-icon"
          />
        </ConfirmMessage>
        <div className="button-container">
          <ButtonAction
            primary={true}
            buttonType="button"
            onClick={allowFaceIDHandler}
          >
            {t("common.allow_access")}
          </ButtonAction>
        </div>
        <div className="privacy-container">
          <PrivacyMessage message={t("allow_face_id_page.privacy_message")} />
        </div>
      </div>
    </div>
  );
}

export default AllowFaceId;
