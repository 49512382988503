import { Loader } from "@greenpanda/ui/loader";
import { createContext, useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { ConfigurationResponse } from "../configuration/configuration-response";
import { configurationData } from "../configuration/partners/pandas";

interface AuthContextType {
  configuration: ConfigurationResponse;
  sessionId: string;
}

interface Props {
  children: React.ReactNode;
}

export const AuthContext = createContext<AuthContextType>({
  configuration: configurationData(),
  sessionId: "",
});

const AuthProvider: React.FC<Props> = ({ children }) => {
  const randomSessionId = Math.random().toString(36).slice(2);
  const [configuration, setConfiguration] =
    useState<ConfigurationResponse | null>(null);
  const [sessionId, setSessionId] = useState<string | null>(randomSessionId);
  const partnerPathname = window.location.pathname.split("/")[1];
  const sessionIdPathname = window.location.pathname.split("/")[2];

  useEffect(() => {
    if (partnerPathname) {
      const fetchConfiguration = async () => {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const partneFile = require(`../configuration/partners/${partnerPathname}`);
        try {
          const response = await partneFile.configurationData();
          setConfiguration(response);
          sessionStorage.setItem("partner-config", JSON.stringify(response));
          const tagManagerArgs = {
            gtmId: response.tag_manager_code,
          };

          TagManager.initialize(tagManagerArgs);
          if (response.at_store_flow)
            if (sessionIdPathname) {
              setSessionId(sessionIdPathname);
              sessionStorage.setItem("session-id", sessionIdPathname);
            } else {
              setSessionId(null);
            }
          else if (sessionId) sessionStorage.setItem("session-id", sessionId);
        } catch (error) {
          setConfiguration(null);
          sessionStorage.setItem("partner-config", JSON.stringify(null));
        }
      };
      fetchConfiguration();
    }
  }, [partnerPathname, sessionIdPathname, sessionId]);

  if (!configuration || !sessionId)
    return <Loader title="NO PARTNER OR SESSIONID" />;
  else
    return (
      <AuthContext.Provider
        value={{
          configuration: configuration as ConfigurationResponse,
          sessionId: sessionId,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
};

export default AuthProvider;
