import i18next from "i18next";
import { WURFL } from "../../constants";
import { ConfigurationResponse } from "../configuration/configuration-response";

declare global {
  interface Window {
    partner: string;
    language: string;
    brand: string;
    model: string;
    country: string;
  }
}

export const tagManagerVariables = (configuration: ConfigurationResponse) => {
  window.partner = configuration.partner.name;
  window.language = i18next.language;
  window.brand = WURFL.brand_name;
  window.model = WURFL.model_name;
  window.country = configuration.partner.country;
};
