import "./back-camera.scss";

import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { ButtonBack } from "@greenpanda/ui/button-back";
import StepsProgress from "../../ui/steps-progress/steps-progress";
import { TopTitle } from "@greenpanda/ui/top-title";
import { ButtonAction } from "@greenpanda/ui/button-action";
import { Modal } from "@greenpanda/ui/modal";
import { UiModalMessage } from "@greenpanda/ui/modal-message";

import {
  evaluationActions,
  lastStepIndexSelector,
} from "../../data-access/evaluation-reducer";
import { lastStepSelector } from "../../data-access/evaluation-reducer";
import { useTranslation } from "react-i18next";
import { nextStep } from "../../../utils/next-step";
import { AuthContext } from "../../../utils/context";
import ExitPopup from "../../../components/exit-popup/exit-popup";

export function BackCamera() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { configuration } = useContext(AuthContext);
  const LAST_INDEX = useSelector(lastStepIndexSelector);
  const LAST_STEP = useSelector(lastStepSelector);

  const [exitModalOpen, setExitModalOpen] = useState(false);
  const [skipModalOpen, setSkipModalOpen] = useState(false);

  const toggleExitModalHandler = () => {
    setExitModalOpen(!exitModalOpen);
  };

  const toggleSkipModalHandler = () => {
    setSkipModalOpen(!skipModalOpen);
  };

  const skipBackCameraHandler = () => {
    dispatch(evaluationActions.setBackCameraURL(null));
    navigate(nextStep(configuration, LAST_INDEX + 1));
  };

  useEffect(() => {
    dispatch(
      evaluationActions.setCurrentStepIndex(
        configuration.assessments.indexOf("back-camera")
      )
    );
  }, [dispatch, configuration]);

  return (
    <div className="feature-page">
      <div className="container">
        {exitModalOpen && (
          <ExitPopup toggleExitModalHandler={toggleExitModalHandler} />
        )}
        {skipModalOpen && (
          <Modal
            buttonPrimaryContent={t("common.cancel")}
            buttonLinkContent={t("common.continue")}
            buttonPrimaryHandler={() => toggleSkipModalHandler()}
            buttonLinkType="button"
            buttonPrimaryType="button"
            buttonLinkHandler={() => skipBackCameraHandler()}
            firstButtonIsOutline={true}
            secondButtonIsOutline={false}
          >
            <UiModalMessage
              icon="/assets/images/icons/warning-icon.svg"
              title={t("back_camera_page.skip_modal_title")}
              description={t("common.offer_warning")}
            />
          </Modal>
        )}
        <NavbarHeader>
          <ButtonBack
            backButtonType="button"
            iconType="close"
            onClick={toggleExitModalHandler}
            imageLink={configuration.images_link}
          />
          <StepsProgress
            step={LAST_STEP}
            total={configuration.assessments.length}
          />
        </NavbarHeader>
        <TopTitle
          subtitle={t("common.lets_evaluate")}
          title={t("back_camera_page.evaluate_title")}
          description={
            configuration.mirror
              ? t("back_camera_page.evaluate_description")
              : t("back_camera_page.evaluate_description_store")
          }
        />

        <div className="image-container">
          {configuration.mirror ? (
            <img
              src={`${configuration.images_link}/images/back-camera.svg`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = "/assets/images/back-camera.svg";
              }}
              alt=""
            />
          ) : (
            <img
              src={`${configuration.images_link}/images/back-camera-store.svg`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = "/assets/images/back-camera-store.svg";
              }}
              alt=""
            />
          )}
        </div>

        <div className="button-container">
          <ButtonAction
            primary={true}
            buttonType="link"
            to="../evaluate-back-camera"
          >
            {t("common.start_now")}
          </ButtonAction>
          <ButtonAction
            buttonType="button"
            primary={false}
            onClick={toggleSkipModalHandler}
          >
            {t("back_camera_page.footer_message")}
          </ButtonAction>
        </div>
      </div>
    </div>
  );
}

export default BackCamera;
