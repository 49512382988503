import "./allow-camera.scss";

import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { ButtonBack } from "@greenpanda/ui/button-back";
import { ConfirmMessage } from "@greenpanda/ui/confirm-message";
import { ButtonAction } from "@greenpanda/ui/button-action";
import PrivacyMessage from "../../ui/privacy-message/privacy-message";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { AuthContext } from "../../../utils/context";

export function AllowCamera() {
  const { t } = useTranslation();
  const { configuration } = useContext(AuthContext);

  return (
    <div className="allow-camera-page">
      <div className="container">
        <NavbarHeader>
          <ButtonBack
            backButtonType="link"
            to="../front-camera"
            iconType="back"
            imageLink={configuration.images_link}
          />
        </NavbarHeader>
        <ConfirmMessage
          title={t("allow_camera_page.title")}
          description={
            configuration.mirror
              ? t("allow_camera_page.description")
              : t("allow_camera_page.description_store")
          }
        >
          <img
            src={`${configuration.images_link}/images/camera.svg`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = "/assets/images/camera.svg";
            }}
            alt="allow camera"
          />
        </ConfirmMessage>
        <div className="button-container">
          <ButtonAction
            primary={true}
            buttonType="link"
            to="../evaluate-front-camera"
          >
            {t("common.allow_access")}
          </ButtonAction>
        </div>
        <div className="privacy-container">
          <PrivacyMessage
            message={
              configuration.mirror
                ? t("allow_camera_page.privacy_message")
                : t("allow_camera_page.privacy_message_store")
            }
          />
        </div>
      </div>
    </div>
  );
}

export default AllowCamera;
