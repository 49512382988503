import imageBrightness from "../../evaluation/utils/image-brightness";

export default function imageBrightnessGuard(
  { imageData }: { imageData: ImageData },
  notify?: (status: string) => void
): boolean {
  const value = imageBrightness(imageData);

  if (value <= 0) {
    notify?.("image_brightness_guard_dark");

    return false;
  } else if (value >= 1) {
    notify?.("image_brightness_guard_bright");

    return false;
  }

  return true;
}
