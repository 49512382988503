import "./object-detection.scss";

import {
  CSSProperties,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import imageBrightnessGuard from "./guards/image-brightness-guard";
import boxEdgesGuard, { BoxEdgesContext } from "./guards/box-edges-guard";
import objectDetectedPercentageGuard from "./guards/object-detected-percentage-guard";
import isStabilizedGuard from "./guards/is-stabilized-guard";
import { ConfigResponse } from "../data-access/app-service";
import getPercentage from "../utils/get-percentage";
import { Loader } from "@greenpanda/ui/loader";
import { useTranslation } from "react-i18next";
import { useObservable } from "@greenpanda/utils/observables";
import { createResizableUserMediaStream } from "./observables/user-media.observable";
import {
  BehaviorSubject,
  combineLatest,
  defer,
  distinctUntilKeyChanged,
  exhaustMap,
  filter,
  map,
  merge,
  of,
  delay,
  Subject,
  switchMap,
  take,
  tap,
  timer,
} from "rxjs";
import { DetectionService } from "./detection/detection.service";
import { attachStreamToVideo } from "./operators/attach-media-stream-to-video";
import { grabVideoFrame } from "./operators/grab-video-frame";
import Instructions from "../evaluation/ui/instructions/instructions";
import { WURFL } from "../../constants";
import { AuthContext } from "../utils/context";

export type FacingModeType =
  | "user"
  | "environment"
  | Omit<MediaTrackConstraints["facingMode"], "user" | "environment">;

export interface ObjectDetectionProps {
  facingMode: FacingModeType;
  appConfig: ConfigResponse | null;
  photoCallback: (photo?: string) => void;
  instructionsCallback: (startDetection?: boolean) => JSX.Element;
}

const videoEl$ = new BehaviorSubject<HTMLVideoElement | null>(null);

type MessageContext = BoxEdgesContext;

type Message = {
  message: string;
  context?: MessageContext;
};

const messages$ = new Subject<Message>();

export function ObjectDetection(props: ObjectDetectionProps) {
  const { t } = useTranslation();
  const { configuration } = useContext(AuthContext);

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const detectionStatusEl = useRef<HTMLDivElement | null>(null);
  const [loaded, setLoaded] = useState(false);
  const detectionService = useMemo(() => new DetectionService(), []);
  const [hasDetectedObject, setHasDetectedObject] = useState(false);
  const [aiLoading, setAiLoading] = useState<string>(t("ai_loading"));

  const { stream: detectionStatusMessage } = useObservable(
    messages$.asObservable().pipe(
      switchMap((message) =>
        merge(
          of(message),
          timer(800).pipe(map(() => ({ message: "" } as Message)))
        )
      ),
      distinctUntilKeyChanged("message")
    )
  );

  const EXPECTED_DETECTION = !configuration.mirror
    ? props.facingMode === "user"
      ? "person"
      : "*"
    : "cell phone";

  useObservable(
    combineLatest([
      createResizableUserMediaStream({
        video: {
          facingMode: props.facingMode,
        },
      }).pipe(attachStreamToVideo(videoEl$)),
      defer(() => detectionService.load()).pipe(tap(() => setLoaded(true))),
    ]).pipe(
      delay(configuration.mirror ? 0 : 3000),
      map(([image]) => image),
      grabVideoFrame(),
      exhaustMap(({ image, width, height, stream }) =>
        defer(() => detectionService.detect(image)).pipe(
          map((predictions) => {
            return predictions.find(
              (prediction) =>
                EXPECTED_DETECTION === "*" ||
                prediction.class === EXPECTED_DETECTION
            );
          }),
          tap((prediction) => {
            if (prediction) setHasDetectedObject((prev) => prev || true);
          }),
          map((prediction) => ({ image, width, height, stream, prediction }))
        )
      ),
      switchMap((value) => {
        const obs$ = of(value).pipe(
          filter((prediction) => !!prediction.prediction?.bbox.length)
        );
        if (!configuration.mirror) return obs$;
        return obs$.pipe(
          filter(
            ({ image: { width, height }, prediction }) =>
              !!prediction &&
              boxEdgesGuard(
                {
                  bbox: prediction.bbox,
                  video: {
                    width,
                    height,
                  },
                },
                (message, context) => messages$.next({ message, context })
              )
          ),
          filter(
            ({ image: { width, height }, prediction }) =>
              !!prediction &&
              objectDetectedPercentageGuard(
                {
                  video: {
                    width,
                    height,
                  },
                  bbox: prediction.bbox,
                  appConfig: props.appConfig,
                  facingMode: props.facingMode,
                },
                (message) => messages$.next({ message })
              )
          ),
          filter(({ image }) =>
            imageBrightnessGuard({ imageData: image }, (message) =>
              messages$.next({ message })
            )
          ),
          filter(
            ({ prediction }) =>
              !!prediction &&
              isStabilizedGuard({ bbox: prediction.bbox }, (message) =>
                messages$.next({ message })
              )
          )
        );
      }),
      take(1),
      tap(({ image, prediction }) => {
        if (!configuration.mirror) return props.photoCallback();
        const threshold = 50;
        const canvas = document.createElement("canvas");
        canvas.height = image.height;
        canvas.width = image.width;
        const ctx = canvas.getContext("2d")!;
        ctx.putImageData(image, 0, 0);
        const [x, y, width, height] = prediction!.bbox;
        const cropped = ctx.getImageData(
          x - threshold,
          y - threshold,
          width + 2 * threshold,
          height + 2 * threshold
        );

        canvas.height = cropped.height;
        canvas.width = cropped.width;

        ctx.putImageData(cropped, 0, 0);
        props.photoCallback(canvas.toDataURL());
      })
    ),
    {
      canTriggerErrorChangeDetection: false,
      canTriggerValueChangeDetection: false,
      onCleanup: () => detectionService.dispose(),
    }
  );

  useEffect(() => {
    const el = detectionStatusEl.current;
    if (!el) return;
    if (detectionStatusMessage?.message)
      el.classList.replace("ease-out", "ease-in");
    else el.classList.replace("ease-in", "ease-out");
  }, [detectionStatusMessage?.message, detectionStatusEl]);

  useEffect(() => {
    videoEl$.next(videoRef.current);
  }, [videoRef]);

  const percentage = useMemo(() => {
    const config = getPercentage(WURFL, props.appConfig);
    return props.facingMode === "user" ? config.frontCamera : config.backCamera;
  }, [props.appConfig, props.facingMode]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAiLoading(t("we_need_some_more_time"));
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, [t]);

  return (
    <>
      {!loaded && <Loader title={aiLoading} />}
      <div
        id="object-detection"
        className={`facing-mode-${props.facingMode}`}
        style={
          {
            filter:
              !configuration.mirror && props.facingMode === "user"
                ? "blur(10px) grayscale(1)"
                : "",
            visibility: loaded ? "visible" : "hidden",
            "--percentage": `${percentage}`,
          } as CSSProperties
        }
      >
        <img
          className="rectangles top-right"
          src="/assets/images/icons/red-rectangle-top-right.svg"
          alt="Red rectangle top right"
        />
        <img
          className="rectangles top-left"
          src="/assets/images/icons/red-rectangle-top-left.svg"
          alt="Red rectangle top left"
        />
        <div id="video-and-canvas">
          <video
            ref={videoRef}
            playsInline
            autoPlay
            style={{
              filter: `blur(${!hasDetectedObject ? "10" : "0"}px)`,
            }}
          />
          {!hasDetectedObject &&
            configuration.mirror &&
            props.instructionsCallback(false)}
          {!configuration.mirror &&
            props.instructionsCallback(hasDetectedObject)}
          <div
            id="detection-status"
            className={`ease-out ${
              WURFL.advertised_device_os === "Android" ? "bottom" : "top"
            }`}
            ref={detectionStatusEl}
          >
            <p>{t(detectionStatusMessage?.message ?? "")}</p>
          </div>
          {detectionStatusMessage?.context?.edge &&
          WURFL.advertised_device_os === "Android" ? (
            <img
              className={
                {
                  left: "arrow-right",
                  right: "arrow-left",
                  top: "arrow-top",
                  bottom: "arrow-bottom",
                }[detectionStatusMessage?.context?.edge]
              }
              src="/assets/images/icons/arrow.svg"
              alt="arrow"
            />
          ) : null}
          {hasDetectedObject === true &&
          WURFL.advertised_device_os === "iOS" &&
          configuration.mirror ? (
            <div id="demo-box" />
          ) : null}
        </div>
        <img
          className="rectangles bottom-right"
          src="/assets/images/icons/red-rectangle-bottom-right.svg"
          alt="Red rectangle bottom right"
        />
        <img
          className="rectangles bottom-left"
          src="/assets/images/icons/red-rectangle-bottom-left.svg"
          alt="Red rectangle bottom left"
        />
      </div>
      {loaded && !configuration.mirror && props.facingMode === "user" && (
        <div className="person">
          <img src="/assets/images/object-detection.svg" alt="" />
          <div id="face-instructions">
            <img src="/assets/images/icons/arrow-top.svg" alt="arrow-top" />
            <Instructions
              title={t("test_camera_page.instructions_store.title")}
              description={t("test_camera_page.instructions_store.description")}
            />
            <p className="no-pictures">
              {t("test_camera_page.instructions_store.no_pictures")}
            </p>
          </div>
        </div>
      )}
      {loaded && !configuration.mirror && props.facingMode === "environment" && (
        <div className="environment">
          <div id="environment-instructions">
            <img
              src="/assets/images/icons/environment-object-icon.svg"
              alt="environment-object-icon"
            />
            <Instructions
              description={t(
                "test_back_camera_page.instructions_store.description"
              )}
            />
            <p className="no-pictures">
              {t("test_back_camera_page.instructions_store.no_pictures")}
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default ObjectDetection;
