import "./trade-option.scss";

import { useContext } from "react";
import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { ButtonBack } from "@greenpanda/ui/button-back";
import { TopTitle } from "@greenpanda/ui/top-title";
import OptionCard from "../../ui/option-card/option-card";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import { AuthContext } from "../../../utils/context";
import { useNavigate } from "react-router-dom";

const tagManagerArgs = {
  dataLayer: {
    event: "Step Trade-in Options",
  },
};

export function TradeOption() {
  TagManager.dataLayer(tagManagerArgs);

  const navigate = useNavigate();

  const { t } = useTranslation();
  const { configuration } = useContext(AuthContext);

  return (
    <div className="trade-option-page">
      <div className="container">
        <NavbarHeader>
          <ButtonBack
            iconType="back"
            backButtonType="link"
            to="../offer"
            imageLink={configuration.images_link}
          />
        </NavbarHeader>
        <TopTitle title={t("trade_option_page.title")} />
        <div className="card-container">
          {configuration.partner.code === "pandas" ? (
            <>
              <OptionCard
                title={t("trade_option_page.option_courier.title")}
                description={t("trade_option_page.option_courier.description")}
                imgSrc={`${configuration.images_link}/images/trade-courier-small.svg`}
                imgDefault="/assets/images/trade-courier-small.svg"
                onClick={() => navigate("../courier")}
              />
              <OptionCard
                title={t("trade_option_page.option_atm.title")}
                description={t("trade_option_page.option_atm.description")}
                imgSrc="/assets/images/trade-atm.svg"
                onClick={() => navigate("../store-locations")}
              />
            </>
          ) : (
            <>
              <OptionCard
                title={t("trade_option_page.option_at_store.title")}
                description={t("trade_option_page.option_at_store.description")}
                imgSrc={`${configuration.images_link}/images/trade-store.svg`}
                imgDefault="/assets/images/trade-store.svg"
                onClick={() => navigate("../store-locations/")}
              />
              <OptionCard
                imgSrc={`${configuration.images_link}/images/trade-courier-small.svg`}
                imgDefault="/assets/images/trade-courier-small.svg"
                title={t("trade_option_page.option_courier_at_store.title")}
                description={t(
                  "trade_option_page.option_courier_at_store.description"
                )}
                onClick={() => navigate("../courier-unavailable/")}
              />
            </>
          )}
        </div>
      </div>
      {/* )} */}
    </div>
  );
}

export default TradeOption;
