import "./sound-performance.scss";

import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useContext } from "react";

import { Modal } from "@greenpanda/ui/modal";
import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { ButtonBack } from "@greenpanda/ui/button-back";
import StepsProgress from "../../ui/steps-progress/steps-progress";
import { TopTitle } from "@greenpanda/ui/top-title";
import { ButtonAction } from "@greenpanda/ui/button-action";

import {
  lastStepSelector,
  evaluationActions,
  lastStepIndexSelector,
} from "../../data-access/evaluation-reducer";
import { useTranslation } from "react-i18next";
import { UiModalMessage } from "@greenpanda/ui/modal-message";
import { nextStep } from "../../../utils/next-step";
import { AuthContext } from "../../../utils/context";
import ExitPopup from "../../../components/exit-popup/exit-popup";

export function SoundPerformance() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const lastStep = useSelector(lastStepSelector);
  const LAST_INDEX = useSelector(lastStepIndexSelector);
  const { configuration } = useContext(AuthContext);

  const [exitModalOpen, setExitModalOpen] = useState(false);
  const [skipModalOpen, setSkipModalOpen] = useState(false);

  const toggleExitModalHandler = () => {
    setExitModalOpen(!exitModalOpen);
  };

  const toggleSkipModalHandler = () => {
    setSkipModalOpen(!skipModalOpen);
  };

  const skipSoundPerformanceHandler = () => {
    dispatch(evaluationActions.setSoundPerformancePassed(false));
    navigate(nextStep(configuration, LAST_INDEX + 1));
  };

  useEffect(() => {
    dispatch(
      evaluationActions.setCurrentStepIndex(
        configuration.assessments.indexOf("sound-performance")
      )
    );
  }, [dispatch, configuration]);

  return (
    <div className="feature-page">
      <div className="container">
        {exitModalOpen && (
          <ExitPopup toggleExitModalHandler={toggleExitModalHandler} />
        )}
        {skipModalOpen && (
          <Modal
            buttonPrimaryContent={t("common.cancel")}
            buttonLinkContent={t("common.continue")}
            buttonPrimaryHandler={() => toggleSkipModalHandler()}
            buttonLinkType="button"
            buttonPrimaryType="button"
            buttonLinkHandler={() => skipSoundPerformanceHandler()}
            firstButtonIsOutline={true}
            secondButtonIsOutline={false}
          >
            <UiModalMessage
              icon="/assets/images/icons/warning-icon.svg"
              title={t("sound_performance_page.skip_modal_title")}
              description={t("common.offer_warning")}
            />
          </Modal>
        )}
        <NavbarHeader>
          <ButtonBack
            backButtonType="button"
            iconType="close"
            onClick={toggleExitModalHandler}
            imageLink={configuration.images_link}
          />
          <StepsProgress
            step={lastStep}
            total={configuration.assessments.length}
          />
        </NavbarHeader>
        <TopTitle
          subtitle={t("common.lets_evaluate")}
          title={t("sound_performance_page.evaluate_title")}
          description={t("sound_performance_page.evaluate_description")}
        />

        <div className="image-container">
          <img
            src={`${configuration.images_link}/images/speaker.svg`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = "/assets/images/speaker.svg";
            }}
            alt=""
          />
        </div>

        <div className="button-container">
          <ButtonAction
            primary={true}
            buttonType="link"
            to="../evaluate-sound-performance"
          >
            {t("sound_performance_page.check_sound")}
          </ButtonAction>
          <ButtonAction
            id={"footer_message"}
            primary={false}
            onClick={toggleSkipModalHandler}
            buttonType="button"
          >
            {t("sound_performance_page.footer_message")}
          </ButtonAction>
        </div>
      </div>
    </div>
  );
}

export default SoundPerformance;
