import { BBox, Feature, Polygon } from "@turf/helpers";
import transformScale from "@turf/transform-scale";
import bboxPolygon from "@turf/bbox-polygon";
import booleanWithin from "@turf/boolean-within";
import { differenceInSeconds, secondsToMilliseconds } from "date-fns";

export interface IsStabilizedOptions {
  scaleFactor?: number;
  timePeriodInSeconds?: number;
}

export function isStabilized(
  bbox: BBox,
  { scaleFactor = 1.5, timePeriodInSeconds = 0.5 }: IsStabilizedOptions = {}
): boolean {
  if (!bbox) return false;

  if (isStabilized["timer"]) {
    clearTimeout(isStabilized["timer"]);
    delete isStabilized["timer"];
  }

  isStabilized["timer"] = setTimeout(() => {
    delete isStabilized["timestamp"];
    delete isStabilized["boundries"];
    delete isStabilized["timer"];
  }, secondsToMilliseconds(timePeriodInSeconds + 1));

  const currentValue: Feature<Polygon> = bboxPolygon(bbox);
  const lastValue: Feature<Polygon> = isStabilized["boundries"];
  const timestamp: Date = isStabilized["timestamp"];

  if (!lastValue || !booleanWithin(currentValue, lastValue)) {
    isStabilized["boundries"] = transformScale(
      currentValue,
      scaleFactor
    ) as Feature<Polygon>;
    isStabilized["timestamp"] = new Date();
    return false;
  }

  return differenceInSeconds(new Date(), timestamp) > timePeriodInSeconds;
}
