import { addMonths, format, startOfTomorrow } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  tradeInActions,
  tradeInCourierSelector,
} from "../../data-access/tradein-reducer";
// @ts-ignore
import DatePicker from "react-datepicker";
import Dropdown from "../dropdown/dropdown";
// @ts-ignore
import { ValueType } from "react-select";
import Holidays from "date-holidays";

export function CourierDate() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tradeInCourier = useSelector(tradeInCourierSelector);

  const timeOptions = [
    { value: "11:00-15:00", label: "11:00-15:00" },
    { value: "15:00-19:00", label: "15:00-19:00" },
  ];

  const hd = new Holidays("GR");
  const tomorrow = startOfTomorrow();
  const endDate = addMonths(new Date(), 2);
  const isWeekdayHoliday = (date: Date) => {
    const day = date.getDay();
    if (hd.isHoliday(date)) return false;
    else return date < endDate && day !== 0 && day !== 6;
  };

  const handleOnChangeDatePicker = (date: Date): void => {
    dispatch(
      tradeInActions.setCourier({
        ...tradeInCourier,
        date: format(date, "yyyy-MM-dd"),
      })
    );
  };

  const handleTimeOption = (
    selections: ValueType<typeof timeOptions[0], true>
  ) => {
    dispatch(
      tradeInActions.setCourier({ ...tradeInCourier, time: selections.value })
    );
  };

  return (
    <div className="date-container">
      <div className="input-wrapper date">
        <DatePicker
          dateFormat="yyyy-MM-dd"
          filterDate={(date: Date) => isWeekdayHoliday(date)}
          minDate={tomorrow}
          selected={new Date(tradeInCourier.date)}
          onChange={(date: Date) => handleOnChangeDatePicker(date)}
        />
        <label htmlFor="date">{t("courier_page.input_date")}</label>
        <img
          className="dropdown-icon"
          src="/assets/images/icons/next.svg"
          placeholder="dd/dd/dd"
          alt=""
        />
      </div>
      <div className="input-wrapper timeslot">
        <Dropdown onChange={handleTimeOption} options={timeOptions} />
        <label>{t("courier_page.input_time")}</label>
        <img
          className="dropdown-icon"
          src="/assets/images/icons/next.svg"
          alt=""
        />
      </div>
    </div>
  );
}

export default CourierDate;
