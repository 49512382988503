import { ConfigResponse } from "../data-access/app-service";

export interface GetPercentageResponse {
  frontCamera: number;
  backCamera: number;
  digitizer: number;
}

export default function getPercentage(
  WURFL: typeof window.WURFL,
  appConfig: ConfigResponse | null
): GetPercentageResponse {
  let frontCamera = 0;
  let backCamera = 0;
  let digitizer = 0;

  const browsers = {
    "Mobile Safari": "safari",
    "Chrome Mobile on iOS": "chrome",
    "Chrome Mobile": "chrome",
    "Mozilla Firefox": "firefox",
    "Opera on Mobile": "opera",
  };

  if (appConfig !== null) {
    const advertisedDeviceOs = WURFL.advertised_device_os.toLocaleLowerCase();

    // appConfig.default
    frontCamera = appConfig.default.frontCamera;
    backCamera = appConfig.default.backCamera;
    digitizer = appConfig.default.digitizer;

    // appConfig[advertisedDeviceOs]
    if (typeof appConfig[advertisedDeviceOs] !== "undefined") {
      frontCamera = appConfig[advertisedDeviceOs].default.frontCamera;
      backCamera = appConfig[advertisedDeviceOs].default.backCamera;
      digitizer = appConfig[advertisedDeviceOs].default.digitizer;

      // appConfig[advertisedDeviceOs][WURFL.model_name]
      if (
        typeof appConfig[advertisedDeviceOs][WURFL.model_name] !== "undefined"
      ) {
        frontCamera =
          appConfig[advertisedDeviceOs][WURFL.model_name].default.frontCamera;
        backCamera =
          appConfig[advertisedDeviceOs][WURFL.model_name].default.backCamera;
        digitizer =
          appConfig[advertisedDeviceOs][WURFL.model_name].default.digitizer;

        // appConfig[advertisedDeviceOs][WURFL.model_name][browsers[WURFL.advertised_browser]]
        if (
          typeof appConfig[advertisedDeviceOs][WURFL.model_name][
            browsers[WURFL.advertised_browser]
          ] !== "undefined"
        ) {
          frontCamera =
            appConfig[advertisedDeviceOs][WURFL.model_name][
              browsers[WURFL.advertised_browser]
            ].frontCamera;
          backCamera =
            appConfig[advertisedDeviceOs][WURFL.model_name][
              browsers[WURFL.advertised_browser]
            ].backCamera;
          digitizer =
            appConfig[advertisedDeviceOs][WURFL.model_name][
              browsers[WURFL.advertised_browser]
            ].digitizer;
        }
      }
    }
  }

  return {
    frontCamera: frontCamera,
    backCamera: backCamera,
    digitizer: digitizer,
  };
}
