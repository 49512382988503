import { browserToCaniuseId } from "caniuse-on-user-agent";
import { BrowserInfo, detect } from "detect-browser";
import { BROWSER_VERSION_URL, WURFL } from "../../constants";
import { ConfigurationResponse } from "../configuration/configuration-response";

export type Case = {
  condition: (() => boolean) | (() => Promise<boolean>);
  iconLink: string;
  message: {
    title: string;
    description: string;
  };
  hasQRCode?: boolean;
  hasClipboard?: boolean;
  canSkip?: boolean;
  canOpenStore?: boolean;
  pricingButton?: boolean;
};

export const requirementCases = (configuration: ConfigurationResponse) => {
  const CASES: Array<Case> = [
    {
      condition: () =>
        WURFL.is_mobile && WURFL.is_app_webview && !configuration.at_store_flow,
      iconLink: "/assets/images/icons/browser-icon.svg",
      message: {
        title: "browser_fallback.mobile.title",
        description: "browser_fallback.mobile.description",
      },
      hasClipboard: true,
    },
    {
      condition: () =>
        WURFL.advertised_device_os === "Android" && configuration.mirror,
      iconLink: "/assets/images/advertised-device-os.png",
      message: {
        title: "browser_fallback.android.title",
        description: "browser_fallback.android.description",
      },
      pricingButton: true,
    },
    {
      condition: () => !WURFL.is_mobile,
      iconLink: "/assets/images/icons/browser-icon.svg",
      message: {
        title: "browser_fallback.desktop.title",
        description: "browser_fallback.desktop.description",
      },
      hasQRCode: true,
    },
    {
      condition: async () => {
        const res = await fetch(BROWSER_VERSION_URL);
        const data = (await res.json()) as {
          versions: {
            [key: string]: string;
          };
        };
        const browser = detect();

        if (!browser || !(browser instanceof BrowserInfo)) return false;

        const browserVersionId = browserToCaniuseId(browser);
        if (!browserVersionId) return false;

        const numBrowserVersion = parseFloat(browser.version);
        const numDataBrowserVersion = parseFloat(
          data.versions[browserVersionId]
        );
        return Math.abs(numDataBrowserVersion - numBrowserVersion) > 5;
      },
      iconLink: "/assets/images/icons/browser-icon.svg",
      message: {
        title: "browser_update.title",
        description: "browser_update.description",
      },
      canSkip: true,
      canOpenStore: true,
    },
  ];
  return CASES;
};
