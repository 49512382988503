import "./location-address.scss";
import { Link } from "react-router-dom";

export interface LocationAddressProps {
  address: string;
}

export function LocationAddress({ address }: LocationAddressProps) {
  return (
    <p className="location-address">
      {address}
      <Link to="../locate-me">
        <img src="/assets/images/icons/edit-icon.svg" alt="edit" />
      </Link>
    </p>
  );
}

export default LocationAddress;
