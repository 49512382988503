import "./allow-microphone.scss";

import { useNavigate } from "react-router";

import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { ButtonBack } from "@greenpanda/ui/button-back";
import { ConfirmMessage } from "@greenpanda/ui/confirm-message";
import { ButtonAction } from "@greenpanda/ui/button-action";
import PrivacyMessage from "../../ui/privacy-message/privacy-message";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { AuthContext } from "../../../utils/context";

export function AllowMicrophone() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { configuration } = useContext(AuthContext);

  function allowMicrophoneHandler() {
    navigator.mediaDevices
      .getUserMedia({
        audio: {
          suppressLocalAudioPlayback: false,
          noiseSuppression: false,
          echoCancellation: false,
          autoGainControl: false,
        },
      })
      .then((stream) => {
        navigate("../evaluate-sound-performance");
      })
      .catch((error) => {
        alert(error);
      });
  }

  return (
    <div className="allow-microphone-page">
      <div className="container">
        <NavbarHeader>
          <ButtonBack
            backButtonType="link"
            to="../sound-performance"
            iconType="back"
            imageLink={configuration.images_link}
          />
        </NavbarHeader>
        <ConfirmMessage
          title={t("allow_microphone_page.title")}
          description={t("allow_microphone_page.description")}
        >
          <img
            src={`${configuration.images_link}/images/microphone.svg`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = "/assets/images/microphone.svg";
            }}
            alt="allow camera"
          />
        </ConfirmMessage>
        <div className="button-container">
          <ButtonAction
            primary={true}
            buttonType="button"
            onClick={allowMicrophoneHandler}
          >
            {t("common.allow_access")}
          </ButtonAction>
        </div>
        <div className="privacy-container">
          <PrivacyMessage
            message={t("allow_microphone_page.privacy_message")}
          />
        </div>
      </div>
    </div>
  );
}

export default AllowMicrophone;
