import { WURFL } from "../../../constants";
import { ConfigurationResponse } from "../../configuration/configuration-response";
import { EvaluationState } from "../../evaluation/data-access/evaluation-reducer";
import { DeviceAssessmentsData } from "../../evaluation/data-access/evaluation-service";

export function createDeviceAssesmentData(
  data: EvaluationState,
  configuration: ConfigurationResponse,
  sessionId: string,
  imei: string
) {
  const brand = WURFL.brand_name;
  const model = WURFL.model_name;

  const digitizer = configuration.assessments.includes("digitizer")
    ? Number(Boolean(data.digitizer.passed))
    : null;
  const sound = configuration.assessments.includes("sound-performance")
    ? Number(Boolean(data.soundPerformance.passed))
    : null;
  const biometrics = configuration.assessments.includes("face-id")
    ? Number(Boolean(data.faceID.passed))
    : null;
  const screenCondition = configuration.assessments.includes("screen")
    ? Number(Boolean(data.screen.passed))
    : null;
  const bodyFrame = configuration.assessments.includes("body-frame")
    ? Number(Boolean(data.bodyFrame.passed))
    : null;
  const frontCamera = configuration.assessments.includes("front-camera")
    ? Number(Boolean(data.frontCamera.photoURL?.length))
    : null;
  const backCamera = configuration.assessments.includes("back-camera")
    ? Number(Boolean(data.backCamera.photoURL?.length))
    : null;

  const deviceAssessmentData: DeviceAssessmentsData = {
    sessionId: sessionId,
    imei: imei,
    brand: brand,
    model: model,
    capacity: "",
    partner: configuration.partner.name,
    digitizer: digitizer,
    sound: sound,
    biometrics: biometrics,
    screenCondition: screenCondition,
    bodyFrame: bodyFrame,
    frontCamera: frontCamera,
    backCamera: backCamera,
    userInput: configuration.at_store_flow
      ? JSON.stringify(data.userInfo)
      : null,
  };

  return deviceAssessmentData;
}
