import "./imei.scss";

import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { TopTitle } from "@greenpanda/ui/top-title";
import { ButtonAction } from "@greenpanda/ui/button-action";
import Input from "../../../tradein/ui/input/input";
import imeiValidation from "../../utils/imei-validation";
import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import StepsProgress from "../../ui/steps-progress/steps-progress";
import { postDeviceAssessments } from "../../data-access/evaluation-service";
import {
  tradeInActions,
  tradeInMaxCapacityPriceSelector,
} from "../../../tradein/data-access/tradein-reducer";
import {
  lastStepSelector,
  evaluationActions,
  evaluationStateSelector,
} from "../../data-access/evaluation-reducer";
import { useTranslation } from "react-i18next";
import { Modal } from "@greenpanda/ui/modal";
import { UiModalMessage } from "@greenpanda/ui/modal-message";
import TagManager from "react-gtm-module";
import { postCheckImei } from "../../../onboarding/data-access/onboarding-service";
import { WURFL } from "../../../../constants";
import { AuthContext } from "../../../utils/context";
import ImeiHelpList from "../../ui/imei-help-list/imei-help-list";
import { calculateOffer } from "../../../tradein/data-access/calculate-offer";
import { createDeviceAssesmentData } from "../../../tradein/data-access/create-device-assessments-data";
import { calculateWebQuote } from "../../../tradein/data-access/calculate-web-quote";

const tagManagerArgs = {
  dataLayer: {
    event: "Step IMEI Form",
  },
};

export function IMEI() {
  TagManager.dataLayer(tagManagerArgs);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const evaluation = useSelector(evaluationStateSelector);
  const lastStep = useSelector(lastStepSelector);
  const maxCapacityPrice = useSelector(tradeInMaxCapacityPriceSelector);
  const { configuration, sessionId } = useContext(AuthContext);

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [imeiValid, setImeiValid] = useState<boolean>(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    dispatch(
      evaluationActions.setCurrentStepIndex(
        configuration.assessments.indexOf("imei")
      )
    );
  }, [dispatch, configuration]);

  const handleOnChangeCallback = (text: string): void => {
    const containsAnyLetter: boolean = /[a-z]/i.test(text);
    const containsAnyCharacter: boolean =
      /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/i.test(text);

    if (containsAnyLetter === false && containsAnyCharacter === false) {
      const imei = parseInt(text.replace(/\s/g, ""));

      if (imeiValidation(imei) === true) {
        setButtonDisabled(false);
        setImeiValid(true);
        dispatch(evaluationActions.setImei(imei));
      } else {
        setButtonDisabled(true);
        setImeiValid(false);
        dispatch(evaluationActions.setImei(0));
      }
    } else {
      setButtonDisabled(true);
      setImeiValid(false);
      dispatch(evaluationActions.setImei(0));
    }
  };

  const handleOnResetCallback = (): void => {
    setButtonDisabled(true);
    dispatch(evaluationActions.setImei(0));
  };

  const handleSubmitImei = async (): Promise<void> => {
    const imei =
      process.env["NODE_ENV"] !== "production"
        ? process.env["NX_IMEI"] ?? ""
        : evaluation.imei.toString();
    setButtonDisabled(true);
    setLoader(true);
    if (configuration.at_store_flow) {
      const data = {
        imei: imei,
      };
      const response = await postCheckImei(data);
      if (response.error) {
        setShowError(true);
        setLoader(false);
      } else {
        dispatch(tradeInActions.setDeviceInfo(response));
        navigate("../device-info");
      }
    }
    if (!configuration.at_store_flow) {
      const deviceAssessmentsData = createDeviceAssesmentData(
        evaluation,
        configuration,
        sessionId,
        imei
      );

      await postDeviceAssessments(deviceAssessmentsData);
      const offer = await calculateOffer(imei, sessionId, configuration);
      window.console.log(offer);

      if (typeof offer === "string") {
        setLoader(false);
        setShowError(true);
      } else {
        dispatch(tradeInActions.setOffer(offer));
        dispatch(
          tradeInActions.setLocalPrice(
            Math.round(
              offer.localPrices.filter(
                (a) =>
                  a.currency === configuration.payment_options.currency.code
              )[0].price
            )
          )
        );
        if (configuration.partner.flow === "pandas") {
          const webQuote = await calculateWebQuote(
            configuration,
            offer,
            deviceAssessmentsData
          );
          window.console.log(webQuote);
          if (webQuote !== null)
            dispatch(tradeInActions.setCashVoucher(webQuote));
        }
        setButtonDisabled(false);
        navigate("../device-identified");
      }
    }
  };

  if (showError) {
    setTimeout(() => {
      setShowError(false);
    }, 3000);

    return (
      <div id="offer-page">
        <div className="container black">
          <Modal>
            <UiModalMessage
              icon="/assets/images/icons/no-offer.svg"
              title={t("offer_page.modal_title")}
            />
          </Modal>
        </div>
      </div>
    );
  }

  return (
    <div className="imei-page">
      <div className="container">
        {!configuration.at_store_flow && (
          <NavbarHeader>
            <StepsProgress
              step={lastStep}
              total={configuration.assessments.length}
            />
          </NavbarHeader>
        )}
        {configuration.at_store_flow && (
          <div className="imei-image">
            <img src="/assets/images/imei.svg" alt="imei" />
          </div>
        )}
        {maxCapacityPrice !== 0 && !configuration.at_store_flow ? (
          <>
            <div className="device-price-container">
              <p className="price">{maxCapacityPrice}€*</p>
              <p className="device">
                {WURFL.brand_name} {WURFL.model_name}
              </p>
            </div>
            <TopTitle title={`${t("imei_page.top_title_price.title")}`} />
          </>
        ) : (
          <TopTitle
            title={
              configuration.at_store_flow
                ? t("imei_page.top_title.title_at_store")
                : t("imei_page.top_title.title")
            }
            description={
              configuration.at_store_flow
                ? ""
                : t("imei_page.top_title.description")
            }
          />
        )}
        <Input
          inputType="input"
          label={t("imei_page.input.label")}
          placeholder={t("imei_page.input.placeholder")}
          onChangeCallback={(text) => handleOnChangeCallback(text)}
          onResetCallback={() => handleOnResetCallback()}
          validation={{
            isValid: imeiValid,
            validMessage: t("imei_page.input.valid_imei"),
            invalidMessage: t("imei_page.input.invalid_imei"),
          }}
        />
        <div className="button-container">
          <ButtonAction
            primary={true}
            disabled={buttonDisabled || !imeiValid}
            buttonType="button"
            onClick={() => handleSubmitImei()}
          >
            {loader ? <span className="loader"></span> : <span></span>}
            <span>
              {loader
                ? t("imei_page.identifying_device")
                : t("imei_page.submit_imei")}{" "}
            </span>
          </ButtonAction>
        </div>
        {maxCapacityPrice !== 0 && !configuration.at_store_flow && (
          <p className="instructions">{t("imei_page.instructions")}</p>
        )}
        <img className="help-icon" src="/assets/images/icons/help.svg" alt="" />
        <p className="how-to-find-imei">{t("imei_page.help_list.title")}</p>
        <ImeiHelpList />
        {WURFL.advertised_device_os === "iOS" ? (
          <img src="/assets/images/find-imei-ios.png" alt="find-imei-ios" />
        ) : (
          <img
            src="/assets/images/find-imei-android.png"
            alt="find-imei-android"
          />
        )}
      </div>
    </div>
  );
}

export default IMEI;
