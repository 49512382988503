import "./login.scss";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "../../../tradein/ui/input/input";
import { ButtonAction } from "@greenpanda/ui/button-action";
import { TopTitle } from "@greenpanda/ui/top-title";

import { useNavigate } from "react-router-dom";

export function Login() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [userIdValid, setUserIdValid] = useState<boolean>(false);

  const handleOnChangeCallback = (text: string) => {
    // TODO: Use the password from the configuration you got.
    if (text === "1234") setUserIdValid(true);
    else setUserIdValid(false);
  };

  return (
    <div className="login-page">
      <div className="container">
        <div>
          <img src="/assets/images/user-login.svg" alt="user-login" />
        </div>
        <TopTitle
          title={t("login_page.title")}
          description={t("login_page.description")}
        />
        <Input
          inputType="input"
          placeholder="Store ID"
          label=""
          validation={{
            isValid: userIdValid,
            validMessage: t("login_page.valid_message"),
            invalidMessage: t("login_page.invalid_message"),
          }}
          onChangeCallback={(text) => handleOnChangeCallback(text)}
        />
        <div className="button-container">
          <ButtonAction
            primary={true}
            disabled={!userIdValid}
            buttonType="button"
            onClick={() => navigate("/imei")}
          >
            {t("common.continue")}
          </ButtonAction>
        </div>
      </div>
    </div>
  );
}

export default Login;
