import "./list-item.scss";

export interface ListItemProps {
  imgSrc: string;
  title: string;
  description?: string;
}

export function ListItem({ imgSrc, title, description }: ListItemProps) {
  return (
    <div className="list-item">
      <img src={imgSrc} alt="" />
      <div className="textbox">
        <p className="title">{title}</p>
        {description && <p className="description">{description}</p>}
      </div>
    </div>
  );
}

export default ListItem;
