import "./thank-you.scss";

import { useNavigate } from "react-router";

import { ButtonBack } from "@greenpanda/ui/button-back";
import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { TopTitle } from "@greenpanda/ui/top-title";
import { ButtonAction } from "@greenpanda/ui/button-action";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import { useContext } from "react";
import { AuthContext } from "../../../utils/context";

const tagManagerArgs = {
  dataLayer: {
    event: "Step Thank You",
  },
};

export function ThankYou() {
  TagManager.dataLayer(tagManagerArgs);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { configuration } = useContext(AuthContext);

  return (
    <div id="trade-in-thank-you-page">
      <NavbarHeader>
        <ButtonBack
          iconType="back"
          backButtonType="button"
          onClick={() => navigate(-1)}
          style={{
            paddingTop: "calc(6px + env(safe-area-inset-top))",
            paddingLeft: 16,
          }}
          imageLink={configuration.images_link}
        />
      </NavbarHeader>
      <img
        className="banner"
        src="/assets/images/thank-you-header.png"
        alt="Thank you"
      />
      <TopTitle
        title={t("tradein_thank_you_page.title")}
        description={t("tradein_thank_you_page.description")}
      />
      <div id="important">
        <div className="pdf-icon">
          <img
            src="/assets/images/icons/file-pdf-white-icon.svg"
            alt="File PDF"
          />
        </div>
        <div className="green-content">
          <p>
            <strong>{t("tradein_thank_you_page.green_content.title")}</strong>
          </p>
          <p>{t("tradein_thank_you_page.green_content.description")}</p>
        </div>
      </div>
      <div className="button-container">
        <ButtonAction primary={true} buttonType="link" to="../label/">
          <img src="/assets/images/icons/download-icon.svg" alt="download" />
          <p>{t("tradein_thank_you_page.button")}</p>
        </ButtonAction>
      </div>
      <p className="a-copy">{t("tradein_thank_you_page.a_copy")}</p>
      <div id="next-steps">
        <p className="title">{t("tradein_thank_you_page.next_steps.title")}</p>
        <div className="list">
          <p className="number">1</p>
          <div className="align-right">
            <p className="top">
              {t("tradein_thank_you_page.next_steps.step_1.top")}
            </p>
            <p className="bottom">
              {t("tradein_thank_you_page.next_steps.step_1.bottom")}
            </p>
          </div>
        </div>
        <div className="list">
          <p className="number">2</p>
          <div className="align-right">
            <p className="top">
              {t("tradein_thank_you_page.next_steps.step_2.top")}
            </p>
            <p className="bottom">
              {t("tradein_thank_you_page.next_steps.step_2.bottom")}
            </p>
          </div>
        </div>
        <div className="list">
          <p className="number">3</p>
          <div className="align-right">
            <p className="top">
              {t("tradein_thank_you_page.next_steps.step_3.top")}
            </p>
            <p className="bottom">
              {t("tradein_thank_you_page.next_steps.step_3.bottom")}
            </p>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="content">
          <div className="top">
            <img
              src="/assets/images/icons/question-white-icon.svg"
              alt="Question"
            />
            <div className="top-right">
              <p className="title">
                {t("tradein_thank_you_page.footer.title")}
              </p>
              <p className="description">
                {t("tradein_thank_you_page.footer.description")}
              </p>
            </div>
          </div>
          <div className="bottom">
            <p className="contact-us">
              {t("tradein_thank_you_page.footer.contact_us")}
            </p>
            <ul>
              <li>
                <img
                  src="/assets/images/icons/phone-white-icon.svg"
                  alt="Phone"
                />
                <a href="tel: 2111982521" title="2111982521">
                  2111982521
                </a>
              </li>
              <li>
                <img
                  src="/assets/images/icons/address-sign-white-icon.svg"
                  alt="Address sign"
                />
                <a href="mailto: hello@pandas.io" title="hello@pandas.io">
                  hello@pandas.io
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThankYou;
