import "./terms-of-services.scss";
import { useTranslation } from "react-i18next";
import Legal from "../../ui/legal/legal";

export function TermsOfServices() {
  const { t } = useTranslation();

  return (
    <Legal
      title={t("terms_of_services_page.title")}
      content={t("terms_of_services_page.terms_of_services")}
    />
  );
}

export default TermsOfServices;
