import "./all-set.scss";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { TopTitle } from "@greenpanda/ui/top-title";
import { ListItem } from "@greenpanda/ui/list-item";
import { ButtonAction } from "@greenpanda/ui/button-action";
import {
  cashVoucherSelector,
  tradeInOfferSelector,
  tradeInSchedulePickupSelector,
} from "../../data-access/tradein-reducer";
import { useContext } from "react";
import { AuthContext } from "../../../utils/context";

export function AllSet() {
  const tradeInOffer = useSelector(tradeInOfferSelector);
  const tradeInSchedulePickup = useSelector(tradeInSchedulePickupSelector);
  const cashVoucher = useSelector(cashVoucherSelector);
  const { t, i18n } = useTranslation();
  const { configuration } = useContext(AuthContext);

  function getDayName(date = new Date(), locale = i18n.language) {
    return date.toLocaleDateString(locale, { weekday: "long" });
  }
  const date =
    tradeInSchedulePickup.date.slice(8, 10) +
    "/" +
    tradeInSchedulePickup.date.slice(5, 7);

  return (
    <div className="all-set-page">
      <div className="container">
        <div className="header">
          <img
            className="banner"
            src="/assets/images/allset-home.png"
            alt="All set"
          />
          {tradeInOffer !== null && typeof tradeInOffer !== "undefined" && (
            <div className="price-tag">
              <p>{t("all_set_page.you_get")}</p>
              <p>
                {(configuration.partner.flow === "pandas" &&
                typeof cashVoucher !== "undefined"
                  ? cashVoucher.cash
                  : tradeInOffer.price) + tradeInOffer.promo}
                <span>€</span>
              </p>
            </div>
          )}
        </div>
        <TopTitle
          title={t("all_set_page.top_title")}
          description={`
            ${t("all_set_page.home.description_1")} ${getDayName(
            new Date(tradeInSchedulePickup.date)
          )}, ${date}
            ${t("all_set_page.home.description_2")}
          `}
        />
        <div className="list-container">
          <ListItem
            key={`list-item-1`}
            imgSrc="/assets/images/icons/battery-high-icon.svg"
            title={t("all_set_page.instructions.battery.title")}
            description={t("all_set_page.instructions.battery.description")}
          />
          <ListItem
            key={`list-item-2`}
            imgSrc="/assets/images/icons/cloud-slash-icon.svg"
            title={t("all_set_page.instructions.turn_off.title")}
            description={t("all_set_page.instructions.turn_off.description")}
          />
          <ListItem
            key={`list-item-3`}
            imgSrc="/assets/images/icons/arrow-counter-clockwise-icon.svg"
            title={t("all_set_page.instructions.factory_reset.title")}
            description={t(
              "all_set_page.instructions.factory_reset.description"
            )}
          />
        </div>
        <div className="button-container">
          <ButtonAction primary={true} buttonType="link" to="../../../start">
            {t("all_set_page.primary_button")}
          </ButtonAction>
        </div>
      </div>
    </div>
  );
}

export default AllSet;
