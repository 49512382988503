import "./app.scss";

import { Route, Routes } from "react-router-dom";
import { useEffect, Suspense, useContext } from "react";
import { useDispatch } from "react-redux";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import EvaluationShell from "./evaluation/feature/evaluation-shell/evaluation-shell";
import OnboardingShell from "./onboarding/feature/onboarding-shell/onboarding-shell";
import TradeinShell from "./tradein/feature/tradein-shell/tradein-shell";
import {
  getConfig,
  ConfigResponse,
  getTranslation,
} from "./data-access/app-service";
import { appActions } from "./data-access/app-reducer";
import { ApplicationRequirements } from "./application-requirements/application-requirements";
import { AuthContext } from "./utils/context";
import { Loader } from "@greenpanda/ui/loader";
import { configStyle } from "./utils/config-style";
import { tagManagerVariables } from "./utils/tag-manager-variables";

export function App() {
  const dispatch = useDispatch();
  const { configuration, sessionId } = useContext(AuthContext);

  useEffect(() => {
    configStyle(configuration);
    if (
      window.location.protocol === "http:" &&
      process.env["NX_ENVIRONMENT"] !== "develop"
    )
      window.location.href = window.location.href.replace("http:", "https:");

    const fetchTranslation = async () => {
      let currentLng = sessionStorage.getItem("i18nextLng");
      if (currentLng === null || ["en", "el"].includes(currentLng) === false)
        currentLng = configuration.language.default_lng;

      const response = await getTranslation(currentLng);

      i18next.use(LanguageDetector).init({
        interpolation: { escapeValue: false },
        fallbackLng: "en",
        defaultNS: "common",
        detection: {
          order: ["sessionStorage", "navigator"],
          caches: ["sessionStorage"],
        },
        resources: {
          [currentLng]: {
            common: response,
          },
        },
      });

      i18next.changeLanguage(currentLng);
      document.documentElement.lang = currentLng;
      tagManagerVariables(configuration);
    };

    const fetchConfig = async () => {
      const config: ConfigResponse = await getConfig();

      dispatch(appActions.setConfig(config));
    };

    fetchTranslation();
    fetchConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <I18nextProvider i18n={i18next}>
        <Routes>
          <Route
            path={
              configuration.at_store_flow
                ? `/${configuration.partner.code}/${sessionId}/*`
                : `/${configuration.partner.code}/*`
            }
            element={<OnboardingShell />}
          />
          <Route
            path={
              configuration.at_store_flow
                ? `/${configuration.partner.code}/${sessionId}/evaluation/*`
                : `/${configuration.partner.code}/evaluation/*`
            }
            element={<EvaluationShell />}
          />
          <Route
            path={
              configuration.at_store_flow
                ? `/${configuration.partner.code}/${sessionId}/trade-in/*`
                : `/${configuration.partner.code}/trade-in/*`
            }
            element={<TradeinShell />}
          />
        </Routes>
        <ApplicationRequirements />
      </I18nextProvider>
    </Suspense>
  );
}

export default App;
