import {
  debounceTime,
  distinctUntilChanged,
  fromEvent,
  map,
  shareReplay,
  startWith,
} from "rxjs";

export const getScreenSize = () => ({
  screenHeight: document.documentElement.clientHeight,
  screenWidth: document.documentElement.clientWidth,
});

export const screenSize$ = fromEvent(window, "resize").pipe(
  startWith(new Event("resize")),
  map(getScreenSize),
  distinctUntilChanged((current, old) => {
    return (
      current.screenHeight === old.screenHeight &&
      current.screenWidth === old.screenWidth
    );
  }),
  debounceTime(300),
  shareReplay(1)
);
