import "./start.scss";

import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import { useContext } from "react";
import { AuthContext } from "../../../utils/context";

const tagManagerArgs = {
  dataLayer: {
    event: "Step Home",
  },
};

export function Start() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { configuration } = useContext(AuthContext);

  TagManager.dataLayer(tagManagerArgs);

  return (
    <div className="start-page">
      <div className="container">
        <div className="title-container">
          {configuration.tutorial && (
            <NavLink className="walkthrough-link" to="../walkthrough">
              <>
                <img src="/assets/images/icons/magicwand-icon.svg" alt="" />
                {t("start_page.how_it_works")}
              </>
            </NavLink>
          )}
          {configuration.partner.flow !== "pandas" ? (
            <div className="slogan">
              <img
                src={`${configuration.images_link}/images/logo.png`}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = "/assets/images/logo.png";
                }}
                alt="logo"
                height={150}
              />
              <p>Trade-in. Do Good.</p>
            </div>
          ) : (
            <p
              className="title"
              dangerouslySetInnerHTML={{ __html: t("start_page.title") }}
            />
          )}
          {configuration.partner.flow !== "pandas" && (
            <div className="powered-by">
              <p>Powered by</p>
              <img src="/assets/images/logo-white.svg" alt="pandas-logo" />
            </div>
          )}
        </div>
        <div className="footer">
          <NavLink className="navlinks" to="../impact">
            <img src="/assets/images/icons/tree-icon.svg" alt="" />
            <>{t("start_page.impact")}</>
          </NavLink>
          <NavLink className="navlinks" to="../more">
            <img src="/assets/images/icons/dots-icon.svg" alt="" />
            <>{t("start_page.more")}</>
          </NavLink>
          <div className="start-button">
            <button onClick={() => navigate("../what-you-need")}>
              {t("common.start")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Start;
