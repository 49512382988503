import "./confirm-message.scss";

export interface ConfirmMessageProps {
  title?: string;
  description?: string;
  emoji?: string;
  iconLink?: string;
  children?: JSX.Element | Array<JSX.Element> | string | Array<string>;
}

export function ConfirmMessage({
  iconLink,
  title,
  description,
  emoji,
  children,
}: ConfirmMessageProps) {
  return (
    <div className="confirm-message">
      {children}
      {emoji && <p className="emoji">{emoji}</p>}
      {iconLink && <img src={iconLink} className="icon" alt="" />}
      {typeof title !== "undefined" && (
        <p className="title" dangerouslySetInnerHTML={{ __html: title }}></p>
      )}
      {typeof description !== "undefined" && (
        <p
          className="description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
    </div>
  );
}

export default ConfirmMessage;
