import "./top-title.scss";

export interface TopTitleProps {
  subtitle?: string;
  title: string;
  description?: string;
}

export function TopTitle({ subtitle, title, description }: TopTitleProps) {
  return (
    <div className="top-title">
      {subtitle && <p className="subtitle">{subtitle}</p>}
      <p className="title" dangerouslySetInnerHTML={{ __html: title }} />
      {description && (
        <p
          className="description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
    </div>
  );
}

export default TopTitle;
