import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";

interface FeatureState {
  skipped: boolean;
  photoURL?: string;
  passed?: boolean;
}

export interface UserInfo {
  fullName: string;
  email: string;
  phoneNumber: string;
}

export interface EvaluationState {
  backCamera: FeatureState;
  frontCamera: FeatureState;
  digitizer: FeatureState;
  screen: FeatureState;
  bodyFrame: FeatureState;
  flash: FeatureState;
  soundPerformance: FeatureState;
  silentButton: FeatureState;
  hardwareButtons: FeatureState;
  faceID: FeatureState;
  sensor: FeatureState;
  deviceMotion: FeatureState;
  wifi: FeatureState;
  bluetooth: FeatureState;
  gps: FeatureState;
  charger: FeatureState;
  lastStepIndex: number;
  imei: number;
  email: string;
  userInfo: UserInfo;
}

type FeatureKeys = keyof Omit<
  EvaluationState,
  "lastStepIndex" | "imei" | "email" | "userInfo"
>;

const initialState: EvaluationState = {
  frontCamera: {
    skipped: false,
    passed: false,
  },
  backCamera: {
    skipped: false,
    passed: false,
  },
  flash: {
    skipped: false,
    passed: false,
  },
  digitizer: {
    skipped: false,
    passed: false,
  },
  soundPerformance: {
    skipped: false,
    passed: false,
  },
  screen: {
    skipped: false,
    passed: false,
  },
  bodyFrame: {
    skipped: false,
    passed: false,
  },
  silentButton: {
    skipped: false,
    passed: false,
  },
  hardwareButtons: {
    skipped: false,
    passed: false,
  },
  faceID: {
    skipped: false,
    passed: false,
  },
  sensor: {
    skipped: false,
    passed: false,
  },
  deviceMotion: {
    skipped: false,
    passed: false,
  },
  wifi: {
    skipped: false,
    passed: false,
  },
  bluetooth: {
    skipped: false,
    passed: false,
  },
  gps: {
    skipped: false,
    passed: false,
  },
  charger: {
    skipped: false,
    passed: false,
  },
  lastStepIndex: -1,
  imei: 0,
  email: "",
  userInfo: {
    fullName: "",
    email: "",
    phoneNumber: "",
  },
};

export function setFeatureURL(
  feature: FeatureKeys,
  state: EvaluationState,
  payload: string | null
) {
  if (!payload) state[feature] = { photoURL: undefined, skipped: true };
  else state[feature] = { photoURL: payload, skipped: false };
}

export function setFeaturePassed(
  feature: FeatureKeys,
  state: EvaluationState,
  payload: boolean
) {
  if (!payload) state[feature] = { passed: false, skipped: true };
  else state[feature] = { passed: payload, skipped: false };
}

const evaluationSlice = createSlice({
  name: "evaluation",
  initialState: initialState,
  reducers: {
    setFrontCameraPassed(
      state: EvaluationState,
      { payload }: PayloadAction<boolean>
    ) {
      setFeaturePassed("frontCamera", state, payload);
    },
    setFrontCameraURL(
      state: EvaluationState,
      { payload }: PayloadAction<string | null>
    ) {
      setFeatureURL("frontCamera", state, payload);
    },
    setBackCameraPassed(
      state: EvaluationState,
      { payload }: PayloadAction<boolean>
    ) {
      setFeaturePassed("backCamera", state, payload);
    },
    setBackCameraURL(
      state: EvaluationState,
      { payload }: PayloadAction<string | null>
    ) {
      setFeatureURL("backCamera", state, payload);
    },
    setFlashURL(
      state: EvaluationState,
      { payload }: PayloadAction<string | null>
    ) {
      setFeatureURL("flash", state, payload);
    },
    setScreenshotURL(
      state: EvaluationState,
      { payload }: PayloadAction<string | null>
    ) {
      setFeatureURL("hardwareButtons", state, payload);
    },
    setDigitizerPassed(
      state: EvaluationState,
      { payload }: PayloadAction<boolean>
    ) {
      setFeaturePassed("digitizer", state, payload);
    },
    setSoundPerformancePassed(
      state: EvaluationState,
      { payload }: PayloadAction<boolean>
    ) {
      setFeaturePassed("soundPerformance", state, payload);
    },
    setScreenPassed(
      state: EvaluationState,
      { payload }: PayloadAction<boolean>
    ) {
      setFeaturePassed("screen", state, payload);
    },
    setBodyFramePassed(
      state: EvaluationState,
      { payload }: PayloadAction<boolean>
    ) {
      setFeaturePassed("bodyFrame", state, payload);
    },
    setSilentButtonPassed(
      state: EvaluationState,
      { payload }: PayloadAction<boolean>
    ) {
      setFeaturePassed("silentButton", state, payload);
    },
    setFaceIDPassed(
      state: EvaluationState,
      { payload }: PayloadAction<boolean>
    ) {
      setFeaturePassed("faceID", state, payload);
    },
    setSensorPassed(
      state: EvaluationState,
      { payload }: PayloadAction<boolean>
    ) {
      setFeaturePassed("sensor", state, payload);
    },
    setDeviceMotionPassed(
      state: EvaluationState,
      { payload }: PayloadAction<boolean>
    ) {
      setFeaturePassed("deviceMotion", state, payload);
    },
    setWifiPassed(state: EvaluationState, { payload }: PayloadAction<boolean>) {
      setFeaturePassed("wifi", state, payload);
    },
    setBluetoothPassed(
      state: EvaluationState,
      { payload }: PayloadAction<boolean>
    ) {
      setFeaturePassed("bluetooth", state, payload);
    },
    setGPSPassed(state: EvaluationState, { payload }: PayloadAction<boolean>) {
      setFeaturePassed("bluetooth", state, payload);
    },
    setChargerPassed(
      state: EvaluationState,
      { payload }: PayloadAction<boolean>
    ) {
      setFeaturePassed("bluetooth", state, payload);
    },
    setCurrentStepIndex(
      state: EvaluationState,
      { payload }: PayloadAction<number>
    ) {
      state.lastStepIndex = payload;
    },
    setImei(state: EvaluationState, { payload }: PayloadAction<number>) {
      state.imei = payload;
    },
    setEmail(state: EvaluationState, { payload }: PayloadAction<string>) {
      state.email = payload;
    },
    setUserInfo(state: EvaluationState, { payload }: PayloadAction<UserInfo>) {
      state.userInfo = payload;
    },
  },
});

export const evaluationStateSelector = (state: {
  evaluation: EvaluationState;
}) => state.evaluation;
export const lastStepIndexSelector = createSelector(
  evaluationStateSelector,
  (state) => state.lastStepIndex
);
export const lastStepSelector = createSelector(
  lastStepIndexSelector,
  (index) => index + 1
);
export const frontCameraSelector = createSelector(
  evaluationStateSelector,
  (state) => state.frontCamera
);
export const backCameraSelector = createSelector(
  evaluationStateSelector,
  (state) => state.backCamera
);
export const emailSelector = createSelector(
  evaluationStateSelector,
  (state) => state.email
);
export const userInfoSelector = createSelector(
  evaluationStateSelector,
  (state) => state.userInfo
);
export const evaluationActions = evaluationSlice.actions;
export default evaluationSlice.reducer;
