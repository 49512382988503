import "./label.scss";

import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

import { ButtonBack } from "@greenpanda/ui/button-back";
import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { tradeInOfferSelector } from "../../data-access/tradein-reducer";
import { evaluationStateSelector } from "../../../evaluation/data-access/evaluation-reducer";
import {
  tradeInAddressSelector,
  tradeInCourierSelector,
} from "../../data-access/tradein-reducer";
import { useContext } from "react";
import { AuthContext } from "../../../utils/context";

export function Label() {
  const navigate = useNavigate();
  const { configuration } = useContext(AuthContext);

  const tradeInOffer = useSelector(tradeInOfferSelector);
  const evaluation = useSelector(evaluationStateSelector);
  const tradeInAddress = useSelector(tradeInAddressSelector);
  const tradeInCourier = useSelector(tradeInCourierSelector);

  return (
    <div id="trade-in-label-page">
      <div className="container">
        <NavbarHeader>
          <ButtonBack
            iconType="back"
            backButtonType="button"
            onClick={() => navigate(-1)}
            style={{
              paddingTop: "calc(6px + env(safe-area-inset-top))",
            }}
            imageLink={configuration.images_link}
          />
        </NavbarHeader>
        <div className="details-container">
          <div className="contact detail">
            <img src="/assets/images/icons/contact-icon.svg" alt="" />
            <div className="textbox">
              <p className="fullname">{`${tradeInCourier.firstName} ${tradeInCourier.lastName}`}</p>
              <p className="email">{tradeInCourier.email}</p>
              <p className="number">{tradeInCourier.phoneNumber}</p>
            </div>
          </div>
          {tradeInOffer !== null && typeof tradeInOffer !== "undefined" && (
            <div className="device detail">
              <img src="/assets/images/icons/device-icon.svg" alt="" />
              <div className="textbox">
                <p className="device">{tradeInOffer.name}</p>
                <p className="specs">{`${tradeInOffer.capacity}, ${tradeInOffer.color}`}</p>
                <p className="imei">{`IMEI: ${evaluation.imei}`}</p>
              </div>
            </div>
          )}
          <div className="address detail">
            <img src="/assets/images/icons/address-icon.svg" alt="" />
            <div className="textbox">
              {tradeInAddress.name !== "" && (
                <p className="address">{tradeInAddress.name}</p>
              )}
              <p className="pickup">
                Pick up: {tradeInCourier.date.split("-").reverse().join("-")},{" "}
                {tradeInCourier.time}
              </p>
            </div>
          </div>
        </div>
        <p className="a-copy">
          A copy of your label is also sent to your email.
        </p>
      </div>
    </div>
  );
}

export default Label;
