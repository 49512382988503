export type SalesAndBuyPrice = {
  veryGood: number;
  good: number;
  poor: number;
  faulty: number;
};

export type LocalPriceType = {
  currency: string;
  price: number;
};

export interface Offer {
  condition: string;
  assessment: string;
  brand: string;
  model: string;
  capacity: string;
  name: string;
  productId: string;
  price: number;
  buyPrices: SalesAndBuyPrice;
  salesPrices: SalesAndBuyPrice;
  coupon: number;
  promo: number;
  localPrices: Array<LocalPriceType>;
  localCoupons: Array<LocalPriceType>;
  localPromo: Array<LocalPriceType>;
  variant: string;
  partsInfo: { battery: { condition: string } };
  batteryStatus: {
    classA: number;
    classB: number;
    classC: number;
    estimate: string;
  };
  partnerInfo: {
    partner: string;
    type: string;
    priceModifier: number;
    absolutePriceModification: number;
  };
}

export interface MaxOfferResponse {
  result: "success" | "error";
  description?: string;
  offer?: Offer;
}
export interface OfferData {
  imei: string;
  deviceType: string;
  currencies: string;
  partner: string;
  parts: string;
}
export interface OfferResponse {
  error?: string;
  brand: string;
  model: string;
  name: string;
  capacity: string;
  color: string;
  productId: string;
  price: number;
  category: string;
  partnerInfo: {
    partner: string;
    type: string;
    priceModifier: number;
    absolutePriceModification: number;
  };
  coupon: number;
  promo: number;
  localPrices: [
    {
      currency: string;
      price: number;
    },
    {
      currency: string;
      price: number;
    }
  ];
  localCoupons: [
    {
      currency: string;
      price: number;
    },
    {
      currency: string;
      price: number;
    }
  ];
  localPromo: [
    {
      currency: string;
      price: number;
    },
    {
      currency: string;
      price: number;
    }
  ];
}
export interface DeviceAssessmentConditionResponse {
  result: "success" | "failure";
  condition: string;
}

export interface WebQuotePartsData {
  brand: string;
  model: string;
  capacity?: string;
  flow: string;
  category: string;
  sort_order?: string;
  parts: Array<object>;
}
export interface WebQuoteResponse {
  result: "success" | "error";
  offers?: [
    {
      batteryStatus: {
        classA: number;
        classB: number;
        classC: number;
        estimate: string;
      };
      ber: number;
      brand: string;
      buyPrices: SalesAndBuyPrice;
      category: string;
      coupon: number;
      faulty: number;
      good: number;
      localCoupons: Array<LocalPriceType>;
      localPrices: Array<LocalPriceType>;
      localPromo: Array<LocalPriceType>;
      model: string;
      name: string;
      partner: string;
      partnerInfo: {
        partner: string;
        type: string;
        priceModifier: number;
        absolutePriceModification: number;
      };
      poor: number;
      price: number;
      productId: string;
      promo: number;
      salesPrices: SalesAndBuyPrice;
      type: string;
      very_good: number;
    }
  ];
}

export interface LocationsResponse {
  id: number;
  store: string;
  warehouse: string;
  name: string;
  name_gr: string;
  partner: string;
  partner_name: string;
  partner_name_gr: string;
  address: string;
  address_gr: string;
  type: string;
  location: string;
  lat: number;
  lng: number;
}

export interface AppointmentScooterData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  country: string;
  city: string;
  zipCode: string;
  productId: string;
  sessionId: string;
  comments: string;
  appointmentDate: string;
  appointmentTime: string;
  type: "scooter";
}

export interface AppointmentScooterResponse {
  result: "success";
}

export interface AppointmentCourierData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  zipCode: string;
  productId: string;
  sessionId: string;
  comments: string;
  appointmentDate: string;
  appointmentTime: string;
  type: "courier";
}

export interface AppointmentCourierResponse {
  result: "success";
}

export async function getOfferMax(query: {
  sessionId: string;
  currencies?: string;
  promoCode: string;
}): Promise<MaxOfferResponse> {
  const response = await fetch(
    `${process.env["NX_IMEI_SERVICE_URL"]}/offer/max?${new URLSearchParams(
      query
    )}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response.json();
}
export async function postOffer(data: OfferData): Promise<OfferResponse> {
  const response = await fetch(
    `${process.env["NX_IMEI_SERVICE_URL"]}/v2/offer`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );

  return response.json();
}

export async function getDeviceAssessmentCondition(query: {
  sessionId: string;
}): Promise<DeviceAssessmentConditionResponse> {
  const response = await fetch(
    `${
      process.env["NX_IMEI_SERVICE_URL"]
    }/deviceAssessmentCondition?${new URLSearchParams(query)}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response.json();
}

export async function getWebQuote(query: {
  brand: string;
  model: string;
  condition: string;
  capacity: string;
  flow: string;
  promoCode?: string;
  sort_order?: string;
}): Promise<WebQuoteResponse> {
  const response = await fetch(
    `${
      process.env["NX_IMEI_SERVICE_URL"]
    }/v2/offers/webquote?${new URLSearchParams(query)}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response.json();
}

export async function postWebQuoteParts(
  data: WebQuotePartsData
): Promise<WebQuoteResponse> {
  const response = await fetch(
    `${process.env["NX_IMEI_SERVICE_URL"]}/v2/offers/webquote/parts`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );

  return response.json();
}

export async function getLocations(query: {
  partner: string;
}): Promise<Array<LocationsResponse>> {
  const response = await fetch(
    `${process.env["NX_SESSION_SERVICE_URL"]}/locations?${new URLSearchParams(
      query
    )}`,
    {
      method: "GET",
    }
  );

  return response.json();
}

export async function getScooterPostcodes(): Promise<Array<string>> {
  const response = await fetch(
    `${process.env["NX_AMAZON_AWS_URL"]}/scooter-postcodes.json`,
    {
      method: "GET",
    }
  );

  return response.json();
}

export async function postAppointmentScooter(
  data: AppointmentScooterData
): Promise<AppointmentScooterResponse> {
  const response = await fetch(
    `${process.env["NX_APPOINTMENT_SCOOTER_ENDPOINT"]}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );

  return response.json();
}

export async function postAppointmentCourier(
  data: AppointmentCourierData
): Promise<AppointmentCourierResponse> {
  const response = await fetch(
    `${process.env["NX_APPOINTMENT_COURIER_ENDPOINT"]}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );

  return response.json();
}
