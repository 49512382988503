import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Courier,
  tradeInActions,
  tradeInCourierSelector,
} from "../../data-access/tradein-reducer";
import Input from "../input/input";

export function CourierInputs() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tradeInCourier = useSelector(tradeInCourierSelector);

  const inputsList = [
    {
      label: t("courier_page.input_first_name"),
      defaultValue: tradeInCourier.firstName,
      key: "firstName" as keyof Courier,
    },
    {
      label: t("courier_page.input_last_name"),
      defaultValue: tradeInCourier.lastName,
      key: "lastName" as keyof Courier,
    },
    {
      label: "Email",
      defaultValue: tradeInCourier.email,
      key: "email" as keyof Courier,
    },
    {
      label: t("courier_page.input_phone"),
      defaultValue: tradeInCourier.phoneNumber,
      key: "phoneNumber" as keyof Courier,
    },
    {
      label: "Address",
      defaultValue: tradeInCourier.address,
      key: "address" as keyof Courier,
    },
    {
      label: "Postal Code",
      defaultValue: tradeInCourier.postalCode,
      key: "postalCode" as keyof Courier,
    },
  ];

  const [phoneNumberValid, setPhoneNumberValid] = useState<boolean>(false);

  const checkPhoneNumber = (value: string) => {
    const PHONE_REGEX =
      /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

    const textMatch = value.match(PHONE_REGEX);
    setPhoneNumberValid(textMatch === null ? true : false);
  };

  const handleInput = (key: keyof Courier, value: string): void => {
    if (key === "phoneNumber") checkPhoneNumber(value);

    dispatch(tradeInActions.setCourier({ ...tradeInCourier, [key]: value }));
  };

  return (
    <div className="contact-details">
      {inputsList.map((element, index) => {
        return (
          <Input
            key={index}
            inputType="text"
            placeholder={element.label}
            label={element.label}
            defaultValue={element.defaultValue}
            onChangeCallback={(value: string) =>
              handleInput(element.key, value)
            }
            onResetCallback={() => handleInput(element.key, "")}
            validation={{
              isValid: element.key === "phoneNumber" ? !phoneNumberValid : true,
              invalidMessage: t("common.invalid_phonenumber"),
            }}
          />
        );
      })}
    </div>
  );
}

export default CourierInputs;
