import "./carousel-item.scss";
export interface CarouselItemProps {
  imgSrc?: string;
  bgColor: string;
  videoSrc?: string;
  title: string;
  description: string;
  adjustVideoSize?: boolean;
}

export function CarouselItem({
  adjustVideoSize,
  imgSrc,
  bgColor,
  videoSrc,
  title,
  description,
}: CarouselItemProps) {
  return (
    <div className="carousel-item" style={{ backgroundColor: bgColor }}>
      {videoSrc && (
        <video
          className={adjustVideoSize ? "adjust-video" : ""}
          loop
          autoPlay={true}
          muted
          playsInline
          controls={false}
        >
          <source src={videoSrc} />
        </video>
      )}
      {imgSrc && <img src={imgSrc} alt="" />}
      <div className="textbox">
        <p className="title" dangerouslySetInnerHTML={{ __html: title }} />
        <p className="description">{description}</p>
      </div>
    </div>
  );
}

export default CarouselItem;
