import { ButtonAction } from "@greenpanda/ui/button-action";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  cashVoucherSelector,
  tradeInOfferSelector,
} from "../../data-access/tradein-reducer";

export function CashVoucherButtons() {
  const { t } = useTranslation();
  const tradeInOffer = useSelector(tradeInOfferSelector);
  const cashVoucher = useSelector(cashVoucherSelector);
  const brand = tradeInOffer?.brand.toLowerCase();
  const model = tradeInOffer?.model
    .toLowerCase()
    .split(" ")
    .join("-")
    .split("/")
    .join("_");
  const capacity =
    tradeInOffer?.capacity === null
      ? "stock"
      : tradeInOffer?.capacity.toLowerCase();
  const condition = tradeInOffer?.condition ?? "good";
  return (
    <div>
      {tradeInOffer && (
        <>
          <a
            style={{ display: "flex" }}
            href={`https://www.pandas.io/pricing/pandas/${brand}/${model}/${capacity}/working/${condition}/email/offer/offerLocations?type=voucher`}
          >
            <ButtonAction primary={true} buttonType="button">
              <span style={{ color: "white", fontWeight: 400 }}>
                {t("offer_page.button_voucher")}
              </span>

              <strong style={{ color: "white" }}>
                {cashVoucher.voucher + tradeInOffer.promo}€
              </strong>
            </ButtonAction>
          </a>
          <a
            style={{ display: "flex", marginTop: "10px" }}
            href={`https://www.pandas.io/pricing/pandas/${brand}/${model}/${capacity}/working/${condition}/email/offer/offerLocations?type=cash`}
          >
            <ButtonAction primary={true} outline buttonType="button">
              <span style={{ fontWeight: 400 }}>
                {t("offer_page.button_cash")}
              </span>

              <strong>{cashVoucher.cash + tradeInOffer.promo}€</strong>
            </ButtonAction>
          </a>
        </>
      )}
    </div>
  );
}

export default CashVoucherButtons;
