import "./scratch.scss";

import { useRef, useEffect, useCallback, useState } from "react";

import getBrushPosition, { Position } from "../../utils/get-brush-position";
import drawDot from "../../utils/draw-dot";
import { usePreventContextMenu } from "../../utils/prevent-context-menu";
import milisecondsToMinutes from "../../utils/miliseconds-to-minutes";
import { Modal } from "@greenpanda/ui/modal";
import { UiModalMessage } from "@greenpanda/ui/modal-message";
import { useTranslation } from "react-i18next";

export interface ScratchProps {
  percentage: number;
  timeout?: number;
  filledCallback: (photo: string) => void;
  continueCallback: () => void;
  tryAgainCallback: () => void;
}

export const EVENTS = ["mousemove", "touchmove"];

let timerInterval: NodeJS.Timer;
let keepSwipingTimeout: NodeJS.Timeout;
let callbackTimeout: NodeJS.Timeout;

export function Scratch({
  percentage,
  timeout,
  filledCallback,
  continueCallback,
  tryAgainCallback,
}: ScratchProps) {
  const greenCanvasRef = useRef<HTMLCanvasElement>(null);
  const redCanvasRef = useRef<HTMLCanvasElement>(null);
  const timerRef = useRef<HTMLParagraphElement>(null);

  const [showKeepSwiping, setShowKeepSwiping] = useState<boolean>(false);
  const [showTouchIsNotWorking, setShowTouchIsNotWorking] =
    useState<boolean>(false);

  usePreventContextMenu();

  const setGreenImage = useCallback(
    (greenCanvasRefCurrent: HTMLCanvasElement) => {
      const canvasEl = document.createElement("canvas");
      canvasEl.width = window.innerWidth;
      canvasEl.height = window.innerHeight;

      const canvasElCtx = canvasEl.getContext("2d");
      if (canvasElCtx !== null) {
        canvasElCtx.fillStyle = "rgba(255, 255, 255, 0.8)";
        canvasElCtx.fillRect(0, 0, window.innerWidth, window.innerHeight);

        const greenImage = new Image();
        greenImage.src = canvasEl.toDataURL();

        greenImage.addEventListener(
          "load",
          () => {
            const greenCanvasRefCurrentCtx =
              greenCanvasRefCurrent.getContext("2d");
            if (greenCanvasRefCurrentCtx !== null)
              greenCanvasRefCurrentCtx.drawImage(
                greenImage,
                0,
                0,
                window.innerWidth,
                window.innerHeight
              );
          },
          false
        );
      }
    },
    []
  );

  const setRedImage = useCallback((redCanvasRefCurrent: HTMLCanvasElement) => {
    const canvasEl = document.createElement("canvas");
    canvasEl.width = window.innerWidth;
    canvasEl.height = window.innerHeight;

    const canvasElCtx = canvasEl.getContext("2d");
    if (canvasElCtx !== null) {
      canvasElCtx.fillStyle = "rgb(240,187,187)";
      canvasElCtx.fillRect(0, 0, window.innerWidth, window.innerHeight);

      const redImage = new Image();
      redImage.src = canvasEl.toDataURL();

      redImage.addEventListener(
        "load",
        () => {
          const redCanvasRefCurrentCtx = redCanvasRefCurrent.getContext("2d");
          if (redCanvasRefCurrentCtx !== null)
            redCanvasRefCurrentCtx.drawImage(
              redImage,
              0,
              0,
              window.innerWidth,
              window.innerHeight
            );
        },
        false
      );
    }
  }, []);

  const handleTimerInterval = useCallback(() => {
    if (timerRef.current !== null) {
      const dataMiliseconds = timerRef.current.getAttribute("data-miliseconds");

      if (dataMiliseconds !== null) {
        let remainedMiliseconds = parseInt(dataMiliseconds);

        timerInterval = setInterval(() => {
          remainedMiliseconds -= 1000;
          timerRef.current?.setAttribute(
            "data-miliseconds",
            remainedMiliseconds.toString()
          );

          if (timerRef.current !== null)
            timerRef.current.innerText =
              milisecondsToMinutes(remainedMiliseconds);

          if (remainedMiliseconds === 0) {
            setShowKeepSwiping(false);
            clearInterval(timerInterval);
          }
        }, 1000);
      }
    }
  }, []);

  const handleKeepSwiping = useCallback(() => {
    clearTimeout(keepSwipingTimeout);

    keepSwipingTimeout = setTimeout(() => {
      setShowKeepSwiping(true);
    }, 5000);
  }, []);

  const handleCallbackTimeout = useCallback(() => {
    if (timerRef.current !== null) {
      const dataMiliseconds = timerRef.current.getAttribute("data-miliseconds");

      if (dataMiliseconds !== null) {
        const remainedMiliseconds = parseInt(dataMiliseconds);

        callbackTimeout = setTimeout(() => {
          setShowTouchIsNotWorking(true);
        }, remainedMiliseconds);
      }
    }
  }, []);

  useEffect(() => {
    handleTimerInterval();
    handleKeepSwiping();
    handleCallbackTimeout();
  }, [handleTimerInterval, handleKeepSwiping, handleCallbackTimeout]);

  useEffect(() => {
    const greenCanvasRefCurrent = greenCanvasRef.current;
    const redCanvasRefCurrent = redCanvasRef.current;

    const handleMove: EventListener = (ev: Event) => {
      ev.preventDefault();

      handleKeepSwiping();

      let eventPosition: Position = { x: 0, y: 0 };
      if (window.TouchEvent && ev instanceof TouchEvent)
        eventPosition = {
          x: ev.targetTouches[0].pageX,
          y: ev.targetTouches[0].pageY,
        };
      else if (window.MouseEvent && ev instanceof MouseEvent)
        eventPosition = {
          x: ev.clientX,
          y: ev.clientY,
        };

      const htmlCanvasElement = ev.target as HTMLCanvasElement;
      const boundingClientRect = htmlCanvasElement.getBoundingClientRect();

      const brushPosition = getBrushPosition(
        htmlCanvasElement,
        {
          top: boundingClientRect.top,
          right: boundingClientRect.right,
          bottom: boundingClientRect.bottom,
          left: boundingClientRect.left,
        },
        eventPosition
      );

      if (greenCanvasRefCurrent === null || redCanvasRefCurrent === null)
        return;

      drawDot(
        htmlCanvasElement,
        redCanvasRefCurrent,
        brushPosition.x,
        brushPosition.y,
        percentage,
        (photo) => {
          EVENTS.forEach((evName: string) => {
            if (greenCanvasRefCurrent !== null)
              greenCanvasRefCurrent.removeEventListener(
                evName,
                handleMove,
                false
              );
          });

          filledCallback(photo);
        }
      );
    };

    if (greenCanvasRefCurrent !== null) {
      setGreenImage(greenCanvasRefCurrent);

      EVENTS.forEach((evName: string) => {
        greenCanvasRefCurrent.addEventListener(evName, handleMove, false);
      });
    }

    if (redCanvasRefCurrent !== null) {
      setRedImage(redCanvasRefCurrent);

      EVENTS.forEach((evName: string) => {
        redCanvasRefCurrent.addEventListener(evName, handleMove, false);
      });
    }

    // START ANIMATION 1
    const hightlightInterval = setInterval(() => {
      if (greenCanvasRefCurrent !== null) {
        if (greenCanvasRefCurrent.classList.contains("ease-in") === true)
          greenCanvasRefCurrent.classList.replace("ease-in", "ease-out");
        else greenCanvasRefCurrent.classList.add("ease-out");

        setTimeout(() => {
          greenCanvasRefCurrent.classList.replace("ease-out", "ease-in");

          // START ANIMATION 2
          setTimeout(() => {
            greenCanvasRefCurrent.classList.replace("ease-in", "ease-out");

            setTimeout(() => {
              greenCanvasRefCurrent.classList.replace("ease-out", "ease-in");

              // START ANIMATION 3
              setTimeout(() => {
                greenCanvasRefCurrent.classList.replace("ease-in", "ease-out");

                setTimeout(() => {
                  greenCanvasRefCurrent.classList.replace(
                    "ease-out",
                    "ease-in"
                  );
                }, 600);
              }, 600);
              // END ANIMATION 3
            }, 600);
          }, 600);
          // END ANIMATION 2
        }, 600);
      }
    }, 10000);
    // END ANIMATION 1

    return () => {
      EVENTS.forEach((evName: string) => {
        if (greenCanvasRefCurrent !== null)
          greenCanvasRefCurrent.removeEventListener(evName, handleMove, false);
      });

      clearTimeout(callbackTimeout);
      clearInterval(hightlightInterval);
      clearInterval(timerInterval);
      clearInterval(callbackTimeout);
    };
  }, [
    filledCallback,
    percentage,
    timeout,
    setGreenImage,
    setRedImage,
    handleKeepSwiping,
  ]);

  const handleButtonPrimary = (): void => {
    setShowKeepSwiping(false);
    handleTimerInterval();
    handleKeepSwiping();
    handleCallbackTimeout();
  };

  const { t } = useTranslation();

  return (
    <>
      <div id="timer">
        <p ref={timerRef} data-miliseconds={timeout}>
          {typeof timeout !== "undefined" && milisecondsToMinutes(timeout)}
        </p>
      </div>
      <canvas
        className="canvas"
        ref={greenCanvasRef}
        width={window.innerWidth}
        height={window.innerHeight}
      />
      <canvas
        className="canvas"
        ref={redCanvasRef}
        width={window.innerWidth}
        height={window.innerHeight}
      />
      {showKeepSwiping === true && (
        <Modal
          buttonPrimaryContent={t("common.continue")}
          buttonPrimaryHandler={() => handleButtonPrimary()}
          buttonLinkType="button"
          buttonPrimaryType="button"
        >
          <UiModalMessage
            icon="assets/images/icons/pop-up-hand-icon.svg"
            title={t("scratch_component.modal_swiping.title")}
            description={t("scratch_component.modal_swiping.description")}
          />
        </Modal>
      )}
      {showTouchIsNotWorking === true && (
        <Modal
          buttonPrimaryContent={t("common.continue")}
          buttonLinkContent={t("common.try_again")}
          buttonPrimaryHandler={() => continueCallback()}
          buttonLinkType="button"
          buttonPrimaryType="button"
          buttonLinkHandler={() => tryAgainCallback()}
          secondButtonIsPrimary={false}
        >
          <UiModalMessage
            icon="assets/images/icons/pop-up-touch-sceennot-working-icon.svg"
            title={t("scratch_component.modal_touch_not_working.title")}
            description={t(
              "scratch_component.modal_touch_not_working.description"
            )}
          />
        </Modal>
      )}
    </>
  );
}

export default Scratch;
