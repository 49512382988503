import { ConfirmMessage } from "@greenpanda/ui/confirm-message";
import { Modal } from "@greenpanda/ui/modal";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../utils/context";
import { goToStartPage } from "../../utils/go-to-start-page";

export interface ExitPopupProps {
  toggleExitModalHandler: () => void;
}

const ExitPopup = (props: ExitPopupProps) => {
  const { t } = useTranslation();
  const { configuration, sessionId } = useContext(AuthContext);

  return (
    <Modal
      buttonPrimaryContent={t("common.resume_evaluation")}
      buttonLinkContent={t("common.exit_evaluation")}
      buttonPrimaryHandler={props.toggleExitModalHandler}
      buttonLinkType="link"
      buttonPrimaryType="button"
      buttonLinkLink={goToStartPage(configuration, sessionId)}
    >
      <ConfirmMessage
        emoji="🧐"
        title={t("common.exit_evaluation") + "?"}
        description={t("common.evaluation_warning")}
      />
    </Modal>
  );
};

export default ExitPopup;
