import "./evaluation-report.scss";

import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { ButtonBack } from "@greenpanda/ui/button-back";
import { TopTitle } from "@greenpanda/ui/top-title";
import { EvaluationItem } from "../../ui/evaluation-item/evaluation-item";
import { ButtonAction } from "@greenpanda/ui/button-action";

export function EvaluationReport() {
  const EVALUATION_LIST = [
    {
      feature: "Front Camera",
      featureIcon: "/assets/images/report-icons/front-camera.svg",
      statusType: "success",
      status: "Works ok",
    },
    {
      feature: "Back Camera",
      featureIcon: "/assets/images/report-icons/back-camera.svg",
      statusType: "poor",
      status: "Suboptimal performance",
    },
    {
      feature: "Face ID",
      featureIcon: "/assets/images/report-icons/face-id.svg",
      statusType: "fail",
      status: "Evaluation failed",
    },
  ];

  return (
    <div className="evaluation-report-page">
      <div className="container">
        <NavbarHeader>
          <ButtonBack iconType="back" backButtonType="link" to="/start" />
        </NavbarHeader>
        <TopTitle
          title="Evaluation report."
          description="View the status of your device’s hardware, based on the Pandas app evaluation."
        />
        <div className="evaluation-list">
          {EVALUATION_LIST.map((item) => {
            return (
              <EvaluationItem
                key={item.feature}
                featureIcon={item.featureIcon}
                featureName={item.feature}
                statusMessage={item.status}
                statusType={item.statusType}
              />
            );
          })}
        </div>
        <div className="button-container">
          <ButtonAction primary={true} buttonType="link" to="/trade-in/offer">
            Trade in now
          </ButtonAction>
        </div>
      </div>
    </div>
  );
}

export default EvaluationReport;
