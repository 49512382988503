import "./courier-unavailable.scss";

import { ButtonAction } from "@greenpanda/ui/button-action";

export function CourierUnavailable() {
  return (
    <div className="courier-unavailable-page">
      <div className="container">
        <div className="header">
          <div style={{ textAlign: "center" }}>
            <img src="/assets/images/courier-unavailable.png" alt="All set" />
          </div>
          <p>Courier drop off is unavailable during testing period</p>
        </div>
        <div className="button-container">
          <ButtonAction primary={true} buttonType="link" to="../pandas-kiosks">
            Drop Off @ Store
          </ButtonAction>
        </div>
      </div>
    </div>
  );
}

export default CourierUnavailable;
