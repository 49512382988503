import "./thank-you-at-store.scss";

import { useTranslation } from "react-i18next";
import { TopTitle } from "@greenpanda/ui/top-title";
import TagManager from "react-gtm-module";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import { evaluationStateSelector } from "../../../evaluation/data-access/evaluation-reducer";
import { useContext, useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import { ENCRYPT_PASSWORD } from "../../../../constants";
import { AuthContext } from "../../../utils/context";

const tagManagerArgs = {
  dataLayer: {
    event: "Step Thank You",
  },
};

export function ThankYouAtStore() {
  TagManager.dataLayer(tagManagerArgs);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sessionId } = useContext(AuthContext);
  const evaluation = useSelector(evaluationStateSelector);
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    const interval = setInterval(() => {
      const encrypted = CryptoJS.AES.encrypt(
        `${sessionId};${Date.now()}`,
        ENCRYPT_PASSWORD
      ).toString();
      setMessage(encrypted);
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, [navigate, sessionId]);

  return (
    <div className="thank-you-at-store-page">
      <div className="container">
        <div>
          <img src="/assets/images/success.svg" alt="thank-you-store" />
        </div>
        <TopTitle
          title={t("thank_you_at_store_page.title")}
          description={t("thank_you_at_store_page.description")}
        />
        <div className="warning">
          <img
            src="/assets/images/icons/wavy-warning-icon.svg"
            alt="wavy-warning-icon"
          />
          <p>{t("thank_you_at_store_page.warning")}</p>
        </div>
        <div className="divider"></div>
        <p className="imei">{evaluation.imei}</p>
        <div className="qr-code">
          <QRCode size={128} value={message} />
        </div>
      </div>
    </div>
  );
}

export default ThankYouAtStore;
