export interface ObjectDetectedPercentageResponse {
  widthPercentage: number;
  heightPercentage: number;
}

export default function objectDetectedPercentage(
  bbox: Array<number>,
  videoSize: Array<number>
): ObjectDetectedPercentageResponse {
  const [w, h] = bbox;
  const [videoWidth, videoHeight] = videoSize;

  const widthPercentage = (w * 100) / videoWidth;
  const heightPercentage = (h * 100) / videoHeight;

  return {
    widthPercentage: widthPercentage,
    heightPercentage: heightPercentage,
  };
}
