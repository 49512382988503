import "./enter-iban.scss";

import Input from "../../ui/input/input";
import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { ButtonBack } from "@greenpanda/ui/button-back";
import { useNavigate } from "react-router";
import { ButtonAction } from "@greenpanda/ui/button-action";
import { TopTitle } from "@greenpanda/ui/top-title";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { AuthContext } from "../../../utils/context";

export function EnterIBAN() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { configuration } = useContext(AuthContext);

  return (
    <div className="enter-iban-page">
      <div className="container">
        <NavbarHeader>
          <ButtonBack
            iconType="back"
            backButtonType="button"
            onClick={() => navigate(-1)}
            imageLink={configuration.images_link}
          />
        </NavbarHeader>
        <TopTitle
          title={t("enter_iban_page.top_title.title")}
          description={t("enter_iban_page.top_title.description")}
        />
        <Input
          inputType="input"
          placeholder={t("enter_iban_page.input_iban.placeholder")}
          label={t("enter_iban_page.input_iban.label")}
        />
        <div className="button-container">
          <ButtonAction primary={true} buttonType="button">
            {t("common.continue")}
          </ButtonAction>
        </div>
      </div>
    </div>
  );
}

export default EnterIBAN;
