import "./thank-you.scss";

import { ButtonAction } from "@greenpanda/ui/button-action";
import { ButtonBack } from "@greenpanda/ui/button-back";
import { ConfirmMessage } from "@greenpanda/ui/confirm-message";
import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { useTranslation } from "react-i18next";
import "./thank-you.scss";
import { useContext } from "react";
import { AuthContext } from "../../../utils/context";

export function ThankYou() {
  const { t } = useTranslation();
  const { configuration } = useContext(AuthContext);

  return (
    <div className="thank-you-page">
      <div className="container">
        <NavbarHeader>
          <ButtonBack
            backButtonType="link"
            to="../contact-us"
            iconType="back"
            imageLink={configuration.images_link}
          />
        </NavbarHeader>

        <ConfirmMessage
          title={t("thank_you_page.title")}
          description={t("thank_you_page.description")}
        >
          <video loop autoPlay={true} muted playsInline controls={false}>
            <source
              src={`${configuration.images_link}/animations/like-heart.mp4`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = "/assets/animations/like-heart.mp4";
              }}
            />
          </video>
        </ConfirmMessage>
        <div className="button-container">
          <ButtonAction primary={true} buttonType="link" to="../start">
            {t("thank_you_page.primary_button")}
          </ButtonAction>
        </div>
      </div>
    </div>
  );
}

export default ThankYou;
