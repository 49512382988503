import { LocationState } from "../../../data-access/location-state";

export const userPosition = async () => {
  const position: LocationState["coordinates"] = await new Promise<
    LocationState["coordinates"]
  >((resolve) => {
    const defaultPosition: LocationState["coordinates"] = {
      longitude: 0,
      latitude: 0,
    };
    if ("geolocation" in navigator)
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          resolve(pos.coords);
        },
        () => resolve(defaultPosition)
      );
    else resolve(defaultPosition);
  });
  return position;
};
