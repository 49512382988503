export default function imageBrightness(imageData: ImageData): number {
  const pixels = imageData.data.length / 4;
  let brightness = 0;

  for (let i = 0; i < imageData.data.length; i += 4) {
    /**
     * Calculate relative luminance per pixel
     * https://en.m.wikipedia.org/wiki/Relative_luminance
     */
    const lum =
      0.2126 * imageData.data[i + 0] +
      0.7152 * imageData.data[i + 1] +
      0.0722 * imageData.data[i + 2];
    brightness += lum / pixels / 255;
  }

  return brightness;
}
