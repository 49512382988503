import "./splash-screen.scss";

import { useNavigate } from "react-router";
import { useEffect, useCallback, useContext } from "react";
import { postDeviceAssessments } from "../../../evaluation/data-access/evaluation-service";
import { AuthContext } from "../../../utils/context";

export function SplashScreen() {
  const navigate = useNavigate();
  const { configuration, sessionId } = useContext(AuthContext);

  useEffect(() => {
    const postSessionId = async () => {
      try {
        await postDeviceAssessments({ sessionId: sessionId });
      } catch (error) {
        //TODO catch error
        window.console.error(error);
      }
    };
    if (configuration.at_store_flow) postSessionId();
  }, [configuration, sessionId]);

  const startNavigation = useCallback(() => {
    if (configuration.at_store_flow)
      navigate(`what-you-need`, { replace: true });
    else navigate("start", { replace: true });
  }, [navigate, configuration]);

  useEffect(() => {
    setTimeout(() => {
      startNavigation();
    }, 5000);
  }, [navigate, startNavigation]);

  return (
    <div className="splash-screen">
      <img
        onClick={startNavigation}
        className="background"
        src={`${configuration.images_link}/images/splash-screen.png`}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = "/assets/images/splash-screen.png";
        }}
        alt="splash-screen"
      />
      <img
        onClick={startNavigation}
        className="logo"
        src="/assets/animations/white-logo.gif"
        alt="Green Panda"
      />
    </div>
  );
}

export default SplashScreen;
