import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./app/app";
import { store, persistor } from "./app/store";
import AuthProvider from "./app/utils/context";

const setInnerHeight = function () {
  const VH = window.innerHeight;
  document.documentElement.style.setProperty("--vh", `${VH}px`);
};

window.addEventListener("resize", setInnerHeight);

window.addEventListener("DOMContentLoaded", setInnerHeight);

const container = document.getElementById("root") as HTMLElement;

const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AuthProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthProvider>
    </PersistGate>
  </Provider>
);
