import "./success-message.scss";

import { useEffect, useCallback, useContext } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { lastStepIndexSelector } from "../../data-access/evaluation-reducer";
import { nextStep } from "../../../utils/next-step";
import { AuthContext } from "../../../utils/context";

export interface SuccessMessageProps {
  reverse?: boolean;
  emoji: string;
  text: string;
  timeout: number;
}

export function SuccessMessage({
  reverse,
  emoji,
  text,
  timeout,
}: SuccessMessageProps) {
  const navigate = useNavigate();
  const { configuration } = useContext(AuthContext);

  const LAST_INDEX = useSelector(lastStepIndexSelector);

  const nextFeature = useCallback(() => {
    if (
      [
        configuration.assessments.indexOf("front-camera") + 1,
        configuration.assessments.indexOf("back-camera") + 1,
      ].includes(LAST_INDEX) === true &&
      configuration.mirror
    )
      if (configuration.mirror) navigate("../image-preview");
      else
        navigate(nextStep(configuration, LAST_INDEX + 1), {
          replace: true,
        });
    else
      navigate(nextStep(configuration, LAST_INDEX), {
        replace: true,
      });
  }, [LAST_INDEX, navigate, configuration]);

  useEffect(() => {
    setTimeout(() => {
      nextFeature();
    }, timeout);
  }, [nextFeature, timeout]);

  return (
    <div className="success-message-page" onClick={nextFeature}>
      <div className="container">
        <div
          className="success-container"
          style={reverse ? { transform: "rotateY(-180deg)" } : {}}
        >
          <p className="emoji">{emoji}</p>
          <p className="text" dangerouslySetInnerHTML={{ __html: text }} />
        </div>
      </div>
    </div>
  );
}

export default SuccessMessage;
