import "./test-body-frame.scss";

import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import DeviceCondition from "../../ui/device-condition/device-condition";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { evaluationActions } from "../../data-access/evaluation-reducer";
import TagManager from "react-gtm-module";
import { AuthContext } from "../../../utils/context";

const tagManagerArgs = {
  dataLayer: {
    event: "Step Evaluation Body Frame",
  },
};

export function TestBodyFrame() {
  TagManager.dataLayer(tagManagerArgs);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { configuration } = useContext(AuthContext);

  const [scratches, setScratches] = useState<boolean | undefined>(undefined);

  const handleContinue = () => {
    if (!scratches) dispatch(evaluationActions.setBodyFramePassed(true));

    dispatch(
      evaluationActions.setCurrentStepIndex(
        configuration.assessments.indexOf("body-frame") + 1
      )
    );
    navigate("../device-condition-success");
  };

  return (
    <DeviceCondition
      title={t("test_body_frame_page.title")}
      perfectButtonOnClick={() => setScratches(false)}
      damagedButtonOnClick={() => setScratches(true)}
      perfectButtonDescription={t("test_body_frame_page.perfect_description")}
      damageButtonDescription={t("test_body_frame_page.damaged_description")}
      checked={scratches}
      onClick={handleContinue}
      disabled={scratches === undefined}
    />
  );
}

export default TestBodyFrame;
