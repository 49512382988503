import { WURFL } from "../../../constants";

export type BoxEdgesContext = {
  edge: "left" | "right" | "top" | "bottom";
};

export default function boxEdgesGuard(
  {
    video,
    bbox,
    margin = 0.05,
  }: {
    video: { height: number; width: number };
    bbox: [number, number, number, number];
    margin?: number;
  },
  notify?: (status: string, context: BoxEdgesContext) => void
) {
  const { height: parentHeight, width: parentWidth } = video;

  const [x, y, width, height] = bbox;

  const [left, right, top, bottom] = [
    x / parentWidth,
    1 - (x + width) / parentWidth,
    y / parentHeight,
    1 - (y + height) / parentHeight,
  ];

  const wrongEdge = (
    [
      { edge: left, name: "left", text: "wrong_edge_right" },
      { edge: right, name: "right", text: "wrong_edge_left" },
      { edge: top, name: "top", text: "wrong_edge_top" },
      { edge: bottom, name: "bottom", text: "wrong_edge_bottom" },
    ] as Array<{ edge: number; name: BoxEdgesContext["edge"]; text: string }>
  ).find((edge) => edge.edge < margin);

  if (wrongEdge) {
    notify?.(
      WURFL.advertised_device_os === "Android"
        ? wrongEdge.text
        : "box_edges_guard",
      { edge: wrongEdge.name }
    );
    return false;
  }

  return true;
}
