import "./reveal-imei-android.scss";

import { useTranslation } from "react-i18next";
import { TopTitle } from "@greenpanda/ui/top-title";
import TagManager from "react-gtm-module";
import { ButtonAction } from "@greenpanda/ui/button-action";
import { createDeviceAssesmentData } from "../../data-access/create-device-assessments-data";
import { postDeviceAssessments } from "../../../evaluation/data-access/evaluation-service";
import { useSelector } from "react-redux";
import { evaluationStateSelector } from "../../../evaluation/data-access/evaluation-reducer";
import { useContext } from "react";
import { AuthContext } from "../../../utils/context";

const tagManagerArgs = {
  dataLayer: {
    event: "Step Reveal IMEI",
  },
};

export function RevealImeiAndroid() {
  TagManager.dataLayer(tagManagerArgs);

  const { t } = useTranslation();
  const evaluation = useSelector(evaluationStateSelector);
  const { configuration, sessionId } = useContext(AuthContext);

  const handleRevealImei = async () => {
    const deviceAssessmentsData = createDeviceAssesmentData(
      evaluation,
      configuration,
      sessionId,
      "0"
    );

    await postDeviceAssessments(deviceAssessmentsData);
    window.location.replace("tel:");
  };

  return (
    <div className="reveal-imei-android-page">
      <div className="container">
        <div style={{ margin: "auto" }}>
          <TopTitle title={t("reveal_imei_page.title")} />
          <div>
            <img src="/assets/images/reveal-imei.png" alt="*#06#" />
          </div>
          <p className="instructions">
            Leave this screen on and insert the device back into the drawer.
          </p>
        </div>
        <div className="button-container">
          <ButtonAction
            primary={true}
            buttonType="button"
            onClick={handleRevealImei}
          >
            Open DialPad
          </ButtonAction>
        </div>
      </div>
    </div>
  );
}

export default RevealImeiAndroid;
