import "./test-digitizer.scss";

import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useCallback, useContext } from "react";
import Instructions from "../../ui/instructions/instructions";
import Scratch from "../../ui/scratch/scratch";
import getPercentage from "../../../utils/get-percentage";
import {
  evaluationActions,
  lastStepIndexSelector,
} from "../../data-access/evaluation-reducer";
import { uploadPhotoOnAmazonS3 } from "../../data-access/evaluation-service";
import { appConfigSelector } from "../../../data-access/app-reducer";
import { useTranslation } from "react-i18next";
import { nextStep } from "../../../utils/next-step";
import TagManager from "react-gtm-module";
import { WURFL } from "../../../../constants";
import { AuthContext } from "../../../utils/context";

const tagManagerArgs = {
  dataLayer: {
    event: "Step Evaluation Digitizer",
  },
};

let timeout: NodeJS.Timeout;

export function TestDigitizer() {
  TagManager.dataLayer(tagManagerArgs);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { configuration, sessionId } = useContext(AuthContext);

  const LAST_INDEX = useSelector(lastStepIndexSelector);
  const appConfig = useSelector(appConfigSelector);

  const [hasPressed, setHasPressed] = useState<boolean>(false);

  const handleTimeout = useCallback(() => {
    timeout = setTimeout(() => {
      setHasPressed(true);
    }, 4000);
  }, []);

  useEffect(() => {
    handleTimeout();

    return () => {
      clearTimeout(timeout);
    };
  }, [handleTimeout]);

  const handleHasPressed = () => {
    if (hasPressed === false) setHasPressed(true);
  };

  const digitizerPassed = (photo: string): void => {
    uploadPhotoOnAmazonS3(photo, sessionId);

    dispatch(
      evaluationActions.setCurrentStepIndex(
        configuration.assessments.indexOf("digitizer") + 1
      )
    );
    dispatch(evaluationActions.setDigitizerPassed(true));
    navigate("../digitizer-success");
  };

  const handleContinue = (): void => {
    dispatch(
      evaluationActions.setCurrentStepIndex(
        configuration.assessments.indexOf("digitizer") + 1
      )
    );
    dispatch(evaluationActions.setDigitizerPassed(false));
    navigate(nextStep(configuration, LAST_INDEX + 1));
  };

  const handleTryAgain = (): void => {
    setHasPressed(false);
    handleTimeout();
  };

  return (
    <div
      className="test-digitizer-page"
      id="test-digitizer-page"
      onClick={() => handleHasPressed()}
    >
      {hasPressed === false ? (
        <>
          <div className="corner-arrows top-left" />
          <div className="corner-arrows top-right" />
          <div id="timer">
            <p>1:00</p>
          </div>
          <div className="container">
            <div className="image-container">
              <video
                className="animation"
                loop
                autoPlay={true}
                muted
                playsInline
                controls={false}
              >
                <source src="assets/animations/evaluate-digitizer.mp4" />
              </video>
              <Instructions
                title={t("test_digitizer_page.instructions.title")}
                description={t("test_digitizer_page.instructions.description")}
              />
            </div>
          </div>
          <div className="corner-arrows bottom-left" />
          <div className="corner-arrows bottom-right" />
        </>
      ) : (
        <Scratch
          percentage={getPercentage(WURFL, appConfig).digitizer}
          timeout={60000}
          filledCallback={(photo: string) => digitizerPassed(photo)}
          continueCallback={() => handleContinue()}
          tryAgainCallback={() => handleTryAgain()}
        />
      )}
    </div>
  );
}

export default TestDigitizer;
