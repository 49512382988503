import { ConfigurationResponse } from "../../configuration/configuration-response";
import { Offer } from "./tradein-reducer";
import { getOfferMax, OfferData, postOffer } from "./tradein-service";

export type OfferError = "error" | "unknown_promo";

export const calculateOffer = async (
  imei: string,
  sessionId: string,
  configuration: ConfigurationResponse
): Promise<Offer | OfferError> => {
  let offer;
  if (configuration.payment_options.parts) {
    const offerData: OfferData = {
      imei: imei,
      deviceType: "smartphone",
      currencies: configuration.payment_options.currency.code,
      partner: configuration.partner.name,
      parts: "yes",
    };
    const responseOffer = await postOffer(offerData);
    if (responseOffer.error) return "error";
    else if (responseOffer.error === "unknown_promo") return "unknown_promo";
    else
      offer = {
        brand: responseOffer.brand,
        model: responseOffer.model,
        name: responseOffer.name,
        capacity: responseOffer.capacity,
        condition: "good",
        color: responseOffer.color,
        productId: responseOffer.productId,
        price: responseOffer.price,
        promo: responseOffer.promo,
        localPrices: responseOffer.localPrices,
      };
    return offer;
  } else {
    const responseOfferMax = await getOfferMax({
      sessionId: sessionId,
      currencies: configuration.payment_options.currency.code,
      promoCode: "",
    });
    if (!responseOfferMax.offer) return "error";
    else if (responseOfferMax.description === "unknown_promo")
      return "unknown_promo";
    else
      offer = {
        brand: responseOfferMax.offer.brand,
        model: responseOfferMax.offer.model,
        name: responseOfferMax.offer.name,
        capacity: responseOfferMax.offer.capacity,
        condition: responseOfferMax.offer.condition,
        color: responseOfferMax.offer.variant,
        productId: responseOfferMax.offer.productId,
        price: responseOfferMax.offer.price,
        promo: responseOfferMax.offer.promo,
        localPrices: responseOfferMax.offer.localPrices,
      };
    return offer;
  }
};
