import "./device-ownership.scss";
import { useTranslation } from "react-i18next";
import Legal from "../../ui/legal/legal";

export function DeviceOwnership() {
  const { t } = useTranslation();

  return (
    <Legal
      title={t("device_ownership_page.title")}
      content={t("device_ownership_page.device_ownership")}
    />
  );
}

export default DeviceOwnership;
