import { LocationsResponse } from "../../../data-access/tradein-service";

export const calculateAveragePosition = (
  tradeInLocations: Array<LocationsResponse>
) => {
  const latitudes: Array<number> = [];

  tradeInLocations
    .filter((element) => element.type === "atm" || element.type === "app")
    .forEach((element) => {
      latitudes.push(element.lat);
    });
  const averageLat = latitudes.reduce((a, b) => a + b, 0) / latitudes.length;
  const longitudes: Array<number> = [];
  tradeInLocations
    .filter((element) => element.type === "atm" || element.type === "app")
    .forEach((element) => {
      longitudes.push(element.lng);
    });
  const averageLng = longitudes.reduce((a, b) => a + b, 0) / longitudes.length;
  return { lat: averageLat, lng: averageLng };
};
