import "./get-quote.scss";

import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { TopTitle } from "@greenpanda/ui/top-title";
import { ButtonAction } from "@greenpanda/ui/button-action";
import Input from "../../../tradein/ui/input/input";
import { useTranslation } from "react-i18next";
import {
  evaluationActions,
  evaluationStateSelector,
} from "../../data-access/evaluation-reducer";
import { postEmailOffer } from "../../data-access/evaluation-service";
import {
  cashVoucherSelector,
  localPriceSelector,
  tradeInOfferSelector,
} from "../../../tradein/data-access/tradein-reducer";
import { Link } from "react-router-dom";
import TagManager from "react-gtm-module";
import { AuthContext } from "../../../utils/context";

const tagManagerArgs = {
  dataLayer: {
    event: "Step Email Form",
  },
};

export function GetQuote() {
  TagManager.dataLayer(tagManagerArgs);

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { configuration, sessionId } = useContext(AuthContext);

  const tradeInOffer = useSelector(tradeInOfferSelector);
  const cashVoucher = useSelector(cashVoucherSelector);
  const localPrice = useSelector(localPriceSelector);
  const evaluation = useSelector(evaluationStateSelector);

  const getQuoteHandler = async (): Promise<void> => {
    if (!tradeInOffer) return;

    const brandLink = tradeInOffer.brand.toLowerCase();
    const modelLink = tradeInOffer.model
      .toLowerCase()
      .split(" ")
      .join("-")
      .split("/")
      .join("_");
    const capacityLink =
      tradeInOffer.capacity === null
        ? "stock"
        : tradeInOffer.capacity.toLowerCase();
    const conditionLink = tradeInOffer.condition ?? "good";
    const { brand, model, price, promo, condition, capacity } = tradeInOffer;
    const response = await postEmailOffer(
      {
        brand,
        model,
        price: localPrice,
        cashPrice:
          typeof cashVoucher !== "undefined" ? cashVoucher.cash : price,
        voucherPrice:
          typeof cashVoucher !== "undefined" ? cashVoucher.voucher : price,
        promo,
        condition: condition ? condition : "good",
        capacity: capacity ? capacity : "",
        lang: i18n.language,
        partner: configuration.partner.name,
        email: evaluation.email,
        category: "smartphone",
        link: `https://www.pandas.io/pricing/pandas/${brandLink}/${modelLink}/${capacityLink}/working/${conditionLink}/email`,
      },
      configuration.partner.flow
    );
    if (response.success === 1)
      navigate(
        configuration.at_store_flow
          ? `/${configuration.partner.code}/${sessionId}/trade-in/offer`
          : `/${configuration.partner.code}/trade-in/offer`
      );
  };

  const handleOnChangeCallback = (text: string): void => {
    const MATCH_EMAIL_REGEX =
      /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const textMatch = text.match(MATCH_EMAIL_REGEX);

    setButtonDisabled(textMatch === null ? true : false);
    dispatch(evaluationActions.setEmail(textMatch === null ? "" : text));
  };

  const handleOnResetCallback = (): void => {
    setButtonDisabled(true);
  };

  return (
    <div className="get-quote-page">
      <div className="container">
        <TopTitle
          title={t("get_quote_page.top_title.title")}
          description={t("get_quote_page.top_title.description")}
        />
        <Input
          inputType="input"
          label={t("get_quote_page.input.label")}
          placeholder={t("get_quote_page.input.placeholder")}
          onChangeCallback={(text) => handleOnChangeCallback(text)}
          onResetCallback={() => handleOnResetCallback()}
          validation={{
            isValid: !buttonDisabled,
            validMessage: t("get_quote_page.input.valid_email"),
            invalidMessage: t("get_quote_page.input.invalid_email"),
          }}
        />
        <div className="privacy-policy">
          <p>{t("get_quote_page.privacy_policy")}</p>
          <Link to="../../privacy-policy">
            {t("get_quote_page.privacy_policy_link")}
          </Link>
        </div>
        <div className="button-container">
          <ButtonAction
            disabled={buttonDisabled}
            primary={true}
            buttonType="button"
            onClick={getQuoteHandler}
          >
            {t("get_quote_page.view_offer")}
          </ButtonAction>
        </div>
      </div>
    </div>
  );
}

export default GetQuote;
