import { ConfigurationResponse } from "../configuration/configuration-response";

export const configStyle = (configuration: ConfigurationResponse) => {
  document.documentElement.style.setProperty(
    "--button-color",
    configuration.colors.primary
  );
  document.documentElement.style.setProperty(
    "--background-homepage",
    `url("${configuration.images_link}/images/homepage.png")`
  );
  document.documentElement.style.setProperty(
    "--background-digitizer",
    `url("${configuration.images_link}/images/digitizer-test-image.png")`
  );
  document.documentElement.style.setProperty(
    "--locate-icon",
    `url("${configuration.images_link}/images/icons/locate-icon.svg")`
  );
};
