import "./instructions.scss";

export interface InstructionsProps {
  title?: string;
  description?: string;
}

export function Instructions({ title, description }: InstructionsProps) {
  return (
    <div className="instructions">
      {typeof title !== "undefined" && (
        <p className="title" dangerouslySetInnerHTML={{ __html: title }}></p>
      )}
      {typeof description !== "undefined" && (
        <p
          className="description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
    </div>
  );
}

export default Instructions;
