import "./ui-loader.scss";

export interface LoaderProps {
  title?: string;
}

export function Loader({ title }: LoaderProps) {
  return (
    <div className="container-spinner">
      {title && (
        <p
          className="title"
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
      )}
      <div className="spinner">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
    </div>
  );
}

export default Loader;
