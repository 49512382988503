import getFilledInPixels from "./get-filled-in-pixels";
import calculateBrushRadius from "./calculate-brush-radius";

export default function drawDot(
  canvasRefCurrent: HTMLCanvasElement,
  redCanvasRefCurrent: HTMLCanvasElement,
  mouseX: number,
  mouseY: number,
  percentage: number,
  filledCallback: (photo: string) => void
): void {
  const canvasRefCtx = canvasRefCurrent.getContext("2d");
  if (canvasRefCtx !== null) {
    const brushRadius = calculateBrushRadius(
      { width: window.innerWidth, height: window.innerHeight },
      { X: mouseX, Y: mouseY }
    );

    canvasRefCtx.beginPath();
    canvasRefCtx.arc(mouseX, mouseY, brushRadius, 0, 2 * Math.PI, true);
    canvasRefCtx.fillStyle = "#000000";
    canvasRefCtx.globalCompositeOperation = "destination-out";
    canvasRefCtx.fill();

    const redCanvasRefCurrentCtx = redCanvasRefCurrent.getContext("2d");
    if (redCanvasRefCurrentCtx !== null) {
      redCanvasRefCurrentCtx.beginPath();
      redCanvasRefCurrentCtx.arc(
        mouseX,
        mouseY,
        brushRadius,
        0,
        2 * Math.PI,
        true
      );
      redCanvasRefCurrentCtx.fillStyle = "#000000";
      redCanvasRefCurrentCtx.globalCompositeOperation = "destination-out";
      redCanvasRefCurrentCtx.fill();
    }

    const imageData: ImageData = canvasRefCtx.getImageData(
      0,
      0,
      canvasRefCurrent.width,
      canvasRefCurrent.height
    );

    const filledInPixels = getFilledInPixels(0, imageData);

    if (filledInPixels >= percentage)
      filledCallback(canvasRefCurrent.toDataURL());
  }
}
