import "./reveal-imei-ios.scss";

import { useTranslation } from "react-i18next";
import { TopTitle } from "@greenpanda/ui/top-title";
import TagManager from "react-gtm-module";
import RevealImeiHelpList from "../../ui/reveal-imei-help-list/reveal-imei-help-list";
import { ButtonAction } from "@greenpanda/ui/button-action";
import { useContext } from "react";
import { AuthContext } from "../../../utils/context";
import { useSelector } from "react-redux";
import { evaluationStateSelector } from "../../../evaluation/data-access/evaluation-reducer";
import { postDeviceAssessments } from "../../../evaluation/data-access/evaluation-service";
import { createDeviceAssesmentData } from "../../data-access/create-device-assessments-data";

const tagManagerArgs = {
  dataLayer: {
    event: "Step Thank You",
  },
};

export function RevealImeiIOS() {
  TagManager.dataLayer(tagManagerArgs);

  const { t } = useTranslation();
  const evaluation = useSelector(evaluationStateSelector);
  const { configuration, sessionId } = useContext(AuthContext);

  const handleRevealImei = async () => {
    const deviceAssessmentsData = createDeviceAssesmentData(
      evaluation,
      configuration,
      sessionId,
      "0"
    );

    await postDeviceAssessments(deviceAssessmentsData);
    window.location.replace("tel:*%2306%23");
  };

  return (
    <div className="reveal-imei-ios-page">
      <div className="container">
        <div>
          <img src="/assets/images/success.svg" alt="thank-you-store" />
        </div>
        <TopTitle
          title={t("reveal_imei_page.title")}
          description={t("reveal_imei_page.description")}
        />
        <RevealImeiHelpList />
        <div className="button-container">
          <ButtonAction
            primary={true}
            buttonType="button"
            onClick={handleRevealImei}
          >
            {t("reveal_imei_page.button")}
          </ButtonAction>
        </div>
      </div>
    </div>
  );
}

export default RevealImeiIOS;
