import { Loader } from "@greenpanda/ui/loader";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { tradeInActions } from "../../../tradein/data-access/tradein-reducer";
import {
  getDeviceAssessmentCondition,
  getWebQuote,
} from "../../../tradein/data-access/tradein-service";
import { evaluationStateSelector } from "../../data-access/evaluation-reducer";
import { postDeviceAssessments } from "../../data-access/evaluation-service";
import { AuthContext } from "../../../utils/context";
import { createDeviceAssesmentData } from "../../../tradein/data-access/create-device-assessments-data";

export function LoadingPage() {
  const evaluation = useSelector(evaluationStateSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { configuration, sessionId } = useContext(AuthContext);

  useEffect(() => {
    dispatch(tradeInActions.setMaxCapacityPrice(0));
    const getMaxCapacityPrice = async () => {
      const imei =
        process.env["NODE_ENV"] !== "production"
          ? process.env["NX_IMEI"] ?? ""
          : evaluation.imei.toString();

      const deviceAssessmentsData = createDeviceAssesmentData(
        evaluation,
        configuration,
        sessionId,
        configuration.at_store_flow ? imei : ""
      );

      await postDeviceAssessments(deviceAssessmentsData);
      const responseDeviceAssessmentCondition =
        await getDeviceAssessmentCondition({
          sessionId: sessionId,
        });

      const response = await getWebQuote({
        brand: deviceAssessmentsData.brand.toLowerCase()!,
        model: deviceAssessmentsData.model.toLowerCase()!,
        capacity: "",
        condition: responseDeviceAssessmentCondition?.condition,
        flow: configuration.partner.flow,
        sort_order: "max",
      });

      if (response.result === "success") {
        const price = response?.offers
          ? response?.offers!.filter((offer) => offer.type === "voucher")
          : [];
        price.sort((a, b) => (a.price > b.price ? -1 : 1));
        dispatch(tradeInActions.setMaxCapacityPrice(price[0].price));
        navigate(
          configuration.at_store_flow
            ? `/${configuration.partner.code}/${sessionId}/trade-in/thank-you-at-store`
            : "../imei"
        );
      } else {
        dispatch(tradeInActions.setMaxCapacityPrice(0));
        navigate(
          configuration.at_store_flow
            ? `/${configuration.partner.code}/${sessionId}/trade-in/thank-you-at-store`
            : "../imei"
        );
      }
    };

    getMaxCapacityPrice();
  }, [evaluation, dispatch, navigate, configuration, sessionId]);
  return <Loader />;
}
export default LoadingPage;
