import { useTranslation } from "react-i18next";
import { WURFL } from "../../../../constants";

export function ImeiHelpList() {
  const { t } = useTranslation();

  const helpList = [
    {
      step: 1,
      text:
        WURFL.advertised_device_os === "iOS"
          ? t("imei_page.help_list.step_1_ios")
          : t("imei_page.help_list.step_1_android"),
    },
    { step: 2, text: t("imei_page.help_list.step_2") },
    { step: 3, text: t("imei_page.help_list.step_3") },
  ];
  return (
    <div className="help-container">
      <div>
        {helpList.map((element, index) => {
          return (
            <div key={index} className="step-container">
              <div>
                <p className="step">{element.step}</p>
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: element.text,
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ImeiHelpList;
