import "./more.scss";
import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { ButtonBack } from "@greenpanda/ui/button-back";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { appLanguagesSelector } from "../../../data-access/app-reducer";
import { useContext } from "react";
import { AuthContext } from "../../../utils/context";
import { goToStartPage } from "../../../utils/go-to-start-page";

export function More() {
  const { t } = useTranslation();
  const appLanguages = useSelector(appLanguagesSelector);
  const { configuration, sessionId } = useContext(AuthContext);

  return (
    <div className="more-page">
      <div className="container">
        <NavbarHeader>
          <ButtonBack
            iconType="back"
            backButtonType="link"
            to={`${goToStartPage(configuration, sessionId)}/start`}
            imageLink={configuration.images_link}
          />
          <p className="title">{t("more_page.title")}</p>
        </NavbarHeader>
        <div className="more-container">
          <div className="header">
            {configuration.our_story && (
              <NavLink className="header-button" to="../our-story">
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      configuration.partner.flow === "pandas"
                        ? t("more_page.our_story_header")
                        : t("more_page.our_story_header_partner"),
                  }}
                />
                <img
                  src={`${configuration.images_link}/images/our-story-btn.png`}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = "/assets/images/our-story-btn.png";
                  }}
                  alt=""
                />
              </NavLink>
            )}
            {configuration.manifesto && (
              <NavLink className="header-button" to="../our-manifesto">
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      configuration.partner.flow === "pandas"
                        ? t("more_page.our_manifesto_header")
                        : t("more_page.our_manifesto_header_partner"),
                  }}
                />
                <img
                  src={`${configuration.images_link}/images/manifesto-btn.png`}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = "/assets/images/manifesto-btn.png";
                  }}
                  alt=""
                />
              </NavLink>
            )}
          </div>
          {appLanguages.length > 1 && (
            <div className="link-set">
              <NavLink className="more-links" to="../languages">
                <>
                  {t("more_page.app_language")}
                  <img src="/assets/images/icons/next.svg" alt="next" />
                </>
              </NavLink>
            </div>
          )}
          <div className="link-set">
            <p className="set-title">{t("more_page.need_help")}</p>
            {configuration.faq && (
              <NavLink className="more-links" to="../faq">
                <>
                  {t("more_page.faq")}
                  <img src="/assets/images/icons/next.svg" alt="next" />
                </>
              </NavLink>
            )}
            {configuration.contact_us && (
              <NavLink className="more-links" to="../contact-us">
                <>
                  {t("more_page.contact_us")}
                  <img src="/assets/images/icons/next.svg" alt="next" />
                </>
              </NavLink>
            )}
          </div>
          <div className="link-set">
            <p className="set-title">{t("more_page.legal")}</p>
            {configuration.privacy_policy_link ? (
              <a
                className="more-links"
                href={configuration.privacy_policy_link}
              >
                {t("more_page.privacy_policy")}
                <img src="/assets/images/icons/next.svg" alt="next" />
              </a>
            ) : (
              <NavLink className="more-links" to="../privacy-policy">
                <>
                  {t("more_page.privacy_policy")}
                  <img src="/assets/images/icons/next.svg" alt="next" />
                </>
              </NavLink>
            )}
            {configuration.terms_of_use_link ? (
              <a className="more-links" href={configuration.terms_of_use_link}>
                {t("more_page.terms_of_services")}
                <img src="/assets/images/icons/next.svg" alt="next" />
              </a>
            ) : (
              <NavLink className="more-links" to="../terms-of-services">
                <>
                  {t("more_page.terms_of_services")}
                  <img src="/assets/images/icons/next.svg" alt="next" />
                </>
              </NavLink>
            )}
          </div>
        </div>
        <div className="more-footer">
          {Object.entries(configuration.social_media).map(([key, value]) => {
            return (
              <a key={key} href={value} title={key} target="_blank">
                <img src={`/assets/images/icons/social-${key}.svg`} alt={key} />
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default More;
