import "./privacy-policy.scss";
import { useTranslation } from "react-i18next";
import Legal from "../../ui/legal/legal";

export function PrivacyPolicy() {
  const { t } = useTranslation();

  return (
    <Legal
      title={t("privacy_policy_page.title")}
      content={t("privacy_policy_page.privacy_policy")}
    />
  );
}

export default PrivacyPolicy;
