import "./offer.scss";

import { useEffect, useState, useRef, ElementRef, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ButtonAction } from "@greenpanda/ui/button-action";
import {
  cashVoucherSelector,
  localPriceSelector,
  tradeInActions,
  tradeInOfferSelector,
} from "../../data-access/tradein-reducer";
import { Modal } from "@greenpanda/ui/modal";
import Input from "../../../tradein/ui/input/input";
import { Loader } from "@greenpanda/ui/loader";
import TagManager from "react-gtm-module";
import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { ButtonBack } from "@greenpanda/ui/button-back";
import { ConfirmMessage } from "@greenpanda/ui/confirm-message";
import { AuthContext } from "../../../utils/context";
import { goToStartPage } from "../../../utils/go-to-start-page";
import DetailedReport from "../../ui/detailed-report/detailed-report";
import CashVoucherButtons from "../../ui/cash-voucher-buttons/cash-voucher-button";
import { calculateOffer } from "../../data-access/calculate-offer";
import { evaluationStateSelector } from "../../../evaluation/data-access/evaluation-reducer";

const tagManagerArgs = {
  dataLayer: {
    event: "Step Offer Page",
  },
};

export function Offer() {
  TagManager.dataLayer(tagManagerArgs);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const evaluation = useSelector(evaluationStateSelector);

  const { configuration, sessionId } = useContext(AuthContext);

  const inputRef = useRef<ElementRef<typeof Input>>(null);

  const tradeInOffer = useSelector(tradeInOfferSelector);
  const cashVoucher = useSelector(cashVoucherSelector);
  const localPrice = useSelector(localPriceSelector);

  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const [showPromoModal, setShowPromoModal] = useState<boolean>(false);
  const [loadingOffer, setLoadingOffer] = useState<boolean>(false);
  const [promoCode, setPromoCode] = useState<string>("");
  const [exitModalOpen, setExitModalOpen] = useState(false);

  useEffect(() => {
    (async () => {
      setLoadingOffer(true);
      try {
        const offer = await calculateOffer(
          evaluation.imei.toString(),
          sessionId,
          configuration
        );

        if (typeof offer === "string") {
          navigate(goToStartPage(configuration, sessionId));
          return;
        } else {
          dispatch(tradeInActions.setOffer(offer));
          dispatch(
            tradeInActions.setLocalPrice(
              Math.round(
                offer.localPrices.filter(
                  (a) =>
                    a.currency === configuration.payment_options.currency.code
                )[0].price
              )
            )
          );
        }
        if (typeof offer === "string" && offer === "unknown_promo")
          setPromoCode("invalid");
      } finally {
        setLoadingOffer(false);
      }
    })();
  }, [promoCode, dispatch, navigate, configuration, sessionId, evaluation]);

  const handleOnClick = async (): Promise<void> => {
    const promo = inputRef.current?.getInputRef()?.value;
    setPromoCode(promo ?? "");
    setShowInfoModal(false);
    setShowPromoModal(false);
  };
  const toggleExitModalHandler = () => {
    setExitModalOpen(!exitModalOpen);
  };

  return (
    <div id="offer-page">
      {loadingOffer ? (
        <Loader title={t("offer_page.loader")} />
      ) : (
        tradeInOffer && (
          <div className="container">
            {exitModalOpen && (
              <Modal
                buttonPrimaryContent={t("common.resume_evaluation")}
                buttonLinkContent={t("offer_page.exit_evaluation")}
                buttonPrimaryHandler={toggleExitModalHandler}
                buttonLinkType="link"
                buttonPrimaryType="button"
                buttonLinkLink={
                  configuration.at_store_flow
                    ? `/${configuration.partner.code}/${sessionId}`
                    : `/${configuration.partner.code}`
                }
              >
                <ConfirmMessage
                  emoji="🧐"
                  title={t("common.exit_evaluation") + "?"}
                  description={t("offer_page.evaluation_warning")}
                />
              </Modal>
            )}
            <NavbarHeader>
              <ButtonBack
                backButtonType="button"
                iconType="close"
                onClick={toggleExitModalHandler}
                imageLink={configuration.images_link}
              />
            </NavbarHeader>
            <div className="title-and-subtitle">
              <p className="title">{`${tradeInOffer.brand} ${tradeInOffer.model}`}</p>
              <p className="subtitle">{`${
                tradeInOffer.capacity === null ? "" : tradeInOffer.capacity
              } ${!tradeInOffer.color ? "" : tradeInOffer.color}`}</p>
            </div>
            <img
              className="tradein-offer"
              src={`${configuration.images_link}/images/tradein-offer.svg`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = "/assets/images/tradein-offer.svg";
              }}
              alt="Trade in offer"
            />
            {promoCode !== "" && promoCode !== "invalid" && !loadingOffer ? (
              <p className="trade-in-for-up-to">
                {t("offer_page.trade_in")}
                <span>{` ${
                  configuration.partner.flow === "pandas"
                    ? cashVoucher.voucher
                    : localPrice
                }€`}</span>
              </p>
            ) : (
              <p className="trade-in-for-up-to">{t("offer_page.trade_in")}</p>
            )}
            <div className="price-and-info-container">
              <div className="price-and-info">
                <p>{`${
                  (configuration.partner.flow === "pandas"
                    ? cashVoucher.voucher
                    : localPrice) +
                  (promoCode !== "" && !loadingOffer ? tradeInOffer.promo : 0)
                }${configuration.payment_options.currency.abbreviation}`}</p>
                <img
                  src="assets/images/icons/info-icon.svg"
                  alt="Info"
                  onClick={() => setShowInfoModal(true)}
                />
              </div>
            </div>
            {promoCode !== "" && promoCode !== "invalid" && !loadingOffer && (
              <div className="promo-container">
                <div className="promo">
                  <strong
                    style={{ marginRight: "4px" }}
                  >{`+${tradeInOffer.promo}€`}</strong>{" "}
                  {t("offer_page.promo_applied")}
                  <img
                    onClick={() => setPromoCode("")}
                    style={{ marginLeft: "16px" }}
                    src="/assets/images/icons/exit-promo-icon.svg"
                    alt=""
                  />
                </div>
              </div>
            )}
            {promoCode === "invalid" && (
              <p className="invalid-promo">Invalid promo code</p>
            )}
            <div className="buttons">
              {configuration.partner.flow === "pandas" && (
                <CashVoucherButtons />
              )}
              {configuration.partner.flow !== "pandas" && (
                <ButtonAction
                  primary={true}
                  buttonType="link"
                  to={
                    configuration.drop_off_options.length > 1
                      ? "../trade-option"
                      : `../${configuration.drop_off_options[0]}`
                  }
                >
                  {t("offer_page.button_action_1")}
                </ButtonAction>
              )}
              {configuration.payment_options.promo && (
                <ButtonAction
                  primary={false}
                  buttonType="button"
                  onClick={() => setShowPromoModal(true)}
                >
                  {t("offer_page.button_action_2")}
                </ButtonAction>
              )}
            </div>
            <p
              className="the-final-price"
              dangerouslySetInnerHTML={{ __html: t("offer_page.final_price") }}
            />
            <DetailedReport />
          </div>
        )
      )}
      {showInfoModal && (
        <Modal>
          <button id="close-modal" onClick={() => setShowInfoModal(false)}>
            <img
              src={`${configuration.images_link}/images/icons/close-icon.svg`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = "/assets/images/icons/close-icon.svg";
              }}
              alt="close-icon"
            />
          </button>
          <p id="info-title">{t("offer_page.info_price_title")}</p>
          <p id="info-description">{t("offer_page.info_price_description")}</p>
        </Modal>
      )}
      {showPromoModal && (
        <Modal>
          <button id="close-modal" onClick={() => setShowPromoModal(false)}>
            <img
              src={`${configuration.images_link}/images/icons/close-icon.svg`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = "/assets/images/icons/close-icon.svg";
              }}
              alt="close-icon"
            />
          </button>
          <p id="promo-title">{t("offer_page.info_promo_title")}</p>
          <Input
            inputType="text"
            label={t("offer_page.info_input_placeholder")}
            placeholder={t("offer_page.info_input_placeholder")}
            ref={inputRef}
            onResetCallback={() => setPromoCode("")}
          />
          <ButtonAction
            disabled={loadingOffer}
            primary={true}
            buttonType="button"
            onClick={() => handleOnClick()}
          >
            {t("offer_page.info_submit")}
          </ButtonAction>
        </Modal>
      )}
    </div>
  );
}

export default Offer;
