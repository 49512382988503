import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";

import { ConfigResponse, LanguagesResponse } from "./app-service";

export interface AppState {
  config: ConfigResponse | null;
  languages: LanguagesResponse | [];
}

const initialState: AppState = {
  config: null,
  languages: [],
};

const appSlice = createSlice({
  name: "app",
  initialState: initialState,
  reducers: {
    setConfig(state: AppState, { payload }: PayloadAction<ConfigResponse>) {
      state.config = payload;
    },
    setLanguages(
      state: AppState,
      { payload }: PayloadAction<LanguagesResponse>
    ) {
      state.languages = payload;
    },
  },
});

const appSelector = (state: { app: AppState }) => state.app;

export const appConfigSelector = createSelector(
  appSelector,
  (state) => state.config
);

export const appLanguagesSelector = createSelector(
  appSelector,
  (state) => state.languages
);

export const appActions = appSlice.actions;
export default appSlice.reducer;
