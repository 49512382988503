import "./courier.scss";
import "react-datepicker/dist/react-datepicker.css";

import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { ButtonBack } from "@greenpanda/ui/button-back";
import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { TopTitle } from "@greenpanda/ui/top-title";
import {
  tradeInOfferSelector,
  tradeInCourierSelector,
  tradeInActions,
} from "../../data-access/tradein-reducer";
import { ButtonAction } from "@greenpanda/ui/button-action";
import {
  postAppointmentCourier,
  AppointmentCourierData,
} from "../../data-access/tradein-service";
import { evaluationStateSelector } from "../../../evaluation/data-access/evaluation-reducer";
import { useTranslation } from "react-i18next";
import { format, parseISO, startOfTomorrow } from "date-fns";
import TagManager from "react-gtm-module";
import CourierInstructions from "../../ui/courier-instructions/courier-instructions";
import CourierInputs from "../../ui/courier-form/courier-inputs";
import CourierDate from "../../ui/courier-form/courier-date";
import CourierCheckboxes from "../../ui/courier-form/courier-checkboxes";
import { AuthContext } from "../../../utils/context";

const tagManagerArgs = {
  dataLayer: {
    event: "Step Courier Form",
  },
};

export function Courier() {
  TagManager.dataLayer(tagManagerArgs);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { configuration, sessionId } = useContext(AuthContext);
  const tradeInOffer = useSelector(tradeInOfferSelector);
  const evaluation = useSelector(evaluationStateSelector);
  const tradeInCourier = useSelector(tradeInCourierSelector);

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

  const tomorrow = startOfTomorrow();

  useEffect(() => {
    dispatch(
      tradeInActions.setCourier({
        ...tradeInCourier,
        checked: false,
        time: tradeInCourier.time,
        email: evaluation.email,
        imei: evaluation.imei,
        date: format(tomorrow, "yyyy-MM-dd"),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirmation = async (): Promise<void> => {
    setButtonDisabled(true);

    const params: AppointmentCourierData = {
      firstName: tradeInCourier.firstName,
      lastName: tradeInCourier.lastName,
      email: tradeInCourier.email,
      phone: tradeInCourier.phoneNumber,
      address: tradeInCourier.address,
      zipCode: tradeInCourier.postalCode,
      productId:
        tradeInOffer !== null && typeof tradeInOffer !== "undefined"
          ? tradeInOffer.productId
          : "",
      sessionId: sessionId,
      comments: "",
      appointmentDate: `${format(parseISO(tradeInCourier.date), "yyyy-MM-dd")}`,
      appointmentTime: tradeInCourier.time,
      type: "courier",
    };

    const appointmentCourier = await postAppointmentCourier(params);
    if (appointmentCourier.result === "success") {
      setButtonDisabled(false);
      navigate("../thank-you/");
    }
  };

  return (
    <div id="trade-in-courier">
      <div className="container">
        <NavbarHeader>
          <ButtonBack
            iconType="back"
            backButtonType="button"
            onClick={() => navigate(-1)}
            imageLink={configuration.images_link}
          />
        </NavbarHeader>
        <div className="banner">
          <TopTitle
            title={t("courier_page.title")}
            description={t("courier_page.description")}
          />
          <img src="/assets/images/courier.svg" alt="" />
        </div>
        <CourierInstructions />
        <CourierInputs />
        <p className="instructions">{t("courier_page.instructions_1")}</p>
        <CourierDate />
        <div className="input-imei">
          <div className="label">IMEI</div>
          <div className="imei">{tradeInCourier.imei}</div>
        </div>
        <CourierCheckboxes />
        <div
          className="instructions"
          style={{ textAlign: "center", fontWeight: "500" }}
        >
          {t("courier_page.instructions_3")}
        </div>
        <div className="button-container">
          <ButtonAction
            primary={true}
            buttonType="button"
            onClick={() => handleConfirmation()}
            disabled={
              buttonDisabled === true ||
              tradeInCourier.firstName === "" ||
              tradeInCourier.lastName === "" ||
              tradeInCourier.email === "" ||
              tradeInCourier.phoneNumber === "" ||
              tradeInCourier.address === "" ||
              tradeInCourier.postalCode === "" ||
              tradeInCourier.checked === false ||
              tradeInCourier.date === "" ||
              tradeInCourier.time === ""
            }
          >
            {t("courier_page.button")}
          </ButtonAction>
        </div>
      </div>
    </div>
  );
}

export default Courier;
