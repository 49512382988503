import "./input.scss";

import {
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";

export interface InputProps {
  customIcon?: undefined | string;
  inputType: string;
  placeholder: string;
  label?: string | undefined;
  defaultValue?: string | undefined;
  validation?: {
    isValid: boolean;
    validMessage?: undefined | string;
    invalidMessage?: undefined | string;
  };
  onChangeCallback?: (text: string) => void;
  onResetCallback?: () => void;
}

type ImperativeRef = {
  getInputRef: () => HTMLInputElement | null;
};

export const Input = forwardRef<ImperativeRef, InputProps>(
  (
    {
      customIcon,
      inputType,
      placeholder,
      label,
      defaultValue,
      validation,
      onChangeCallback,
      onResetCallback,
    }: InputProps,
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const [isTyped, setIsTyped] = useState<boolean>(false);

    useImperativeHandle(ref, () => ({
      getInputRef: () => {
        return inputRef.current;
      },
    }));

    useEffect(() => {
      if (typeof defaultValue !== "undefined" && inputRef.current !== null)
        inputRef.current.value = defaultValue;
    }, [defaultValue]);

    const resetInputValue = function () {
      if (inputRef.current) inputRef.current!.value = "";

      if (typeof onResetCallback !== "undefined") onResetCallback();
    };

    const handleOnChange = (): void => {
      if (
        inputRef.current !== null &&
        typeof onChangeCallback !== "undefined"
      ) {
        onChangeCallback(inputRef.current.value);

        if (isTyped === false) setIsTyped(true);
      }
    };

    return (
      <div className={"input-wrapper" + (customIcon ? " with-icon" : "")}>
        {customIcon && <img className="custom-icon" src={customIcon} alt="" />}
        <input
          className={
            isTyped === true && typeof validation !== "undefined"
              ? validation.isValid === true
                ? "is-valid"
                : "is-invalid"
              : ""
          }
          ref={inputRef}
          type={inputType}
          placeholder={placeholder}
          required={true}
          onChange={() => handleOnChange()}
        />
        {typeof label !== "undefined" && <label>{label}</label>}
        <img
          src="/assets/images/icons/clear.svg"
          alt=""
          onClick={() => resetInputValue()}
        />
        {isTyped === true &&
          typeof validation !== "undefined" &&
          validation.isValid === true &&
          typeof validation.validMessage !== "undefined" && (
            <p className="is-valid">{validation.validMessage}</p>
          )}
        {isTyped === true &&
          typeof validation !== "undefined" &&
          validation.isValid === false &&
          typeof validation.invalidMessage !== "undefined" && (
            <p className="is-invalid">{validation.invalidMessage}</p>
          )}
      </div>
    );
  }
);

export default Input;
