import "./dropdown.scss";
// @ts-ignore
import Select, { StylesConfig, ValueType } from "react-select";

export interface DropdownProps {
  options: ValueType;
  onChange: (selections: ValueType) => void;
}
type OptionType = {
  value: string;
  label: string;
};

export function Dropdown({ options, onChange }: DropdownProps) {
  const dropdownStyles: StylesConfig<OptionType, false> = {
    container: (provided) => ({
      ...provided,
      flexGrow: 1,
    }),
    control: (provided) => ({
      ...provided,
      background: "#F5F5F5",
      border: "none",
      minHeight: "56px",
      boxShadow: "none",
      fontSize: "11px",
      color: "#6C6C6C",
      fontWeight: 800,
      borderRadius: "8px",
    }),
    option: (provided) => ({
      ...provided,
      background: "#FFFFFF",
      borderBottom: "1px solid #E6E6E6",
      color: "black",
      fontSize: "14px",
      margin: "0",
      marginBottom: "4px",
    }),
    menu: (provided) => ({
      ...provided,
      boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.1)",
      padding: "0 10px",
    }),
    singleValue: (provided) => ({
      ...provided,
      position: "absolute",
      bottom: "11px",
      left: "7px",
      fontSize: "14px",
      fontWeight: 600,
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: "56px",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      display: "none",
    }),
  };
  return (
    <Select
      placeholder=""
      onChange={onChange}
      options={options}
      styles={dropdownStyles}
    />
  );
}

export default Dropdown;
