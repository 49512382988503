import "./tradein-shell.scss";

import { Routes, Route } from "react-router-dom";

import Offer from "../offer/offer";
import LocateMe from "../locate-me/locate-me";
import TradeOption from "../trade-option/trade-option";
import SchedulePickup from "../schedule-pickup/schedule-pickup";
import SignoutIcloud from "../signout-icloud/signout-icloud";
import WhatsNextShell from "../whats-next/feature/whats-next-shell/whats-next-shell";
import AllSet from "../all-set/all-set";
import ATMDropoff from "../atm-dropoff/atm-dropoff";
import FindATM from "../find-atm/find-atm";
import EnterIBAN from "../enter-iban/enter-iban";
import StoreLocations from "../store-locations/store-locations";
import PandasKiosks from "../pandas-kiosks/pandas-kiosks";
import Courier from "../courier/courier";
import ThankYou from "../thank-you/thank-you";
import Label from "../label/label";
import ThankYouStore from "../thank-you-partner/thank-you-store";
import CourierUnavailable from "../courier-unavailable/courier-unavailable";
import ThankYouAtStore from "../thank-you-at-store/thank-you-at-store";
import RevealImeiIOS from "../reveal-imei/reveal-imei-ios";
import RevealImeiAndroid from "../reveal-imei/reveal-imei-android";

export function TradeinShell() {
  return (
    <Routes>
      <Route path="/offer/" element={<Offer />} />
      <Route path="/locate-me/" element={<LocateMe />} />
      <Route path="/store-locations/" element={<StoreLocations />} />
      <Route path="/trade-option/" element={<TradeOption />} />
      <Route path="/schedule-pickup/" element={<SchedulePickup />} />
      <Route path="/signout-icloud/" element={<SignoutIcloud />} />
      <Route path="/atm-dropoff/" element={<ATMDropoff />} />
      <Route path="/find-atm/" element={<FindATM />} />
      <Route path="/enter-iban" element={<EnterIBAN />} />
      <Route path="/whats-next/*" element={<WhatsNextShell />} />
      <Route path="/pandas-kiosks/" element={<PandasKiosks />} />
      <Route path="/all-set/" element={<AllSet />} />
      <Route path="/courier/" element={<Courier />} />
      <Route path="/courier-unavailable/" element={<CourierUnavailable />} />
      <Route path="/thank-you/" element={<ThankYou />} />
      <Route path="/reveal-imei-ios/" element={<RevealImeiIOS />} />
      <Route path="/reveal-imei-android/" element={<RevealImeiAndroid />} />
      <Route path="/thank-you-at-store/" element={<ThankYouAtStore />} />
      <Route path="/thank-you-store/" element={<ThankYouStore />} />
      <Route path="/label/" element={<Label />} />
    </Routes>
  );
}

export default TradeinShell;
