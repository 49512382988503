import "./test-face-id.scss";

import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useCallback, useContext } from "react";

import { ButtonBack } from "@greenpanda/ui/button-back";
import { Modal } from "@greenpanda/ui/modal";
import { ConfirmMessage } from "@greenpanda/ui/confirm-message";
import { NavbarHeader } from "@greenpanda/ui/navbar-header";

import {
  evaluationActions,
  lastStepIndexSelector,
} from "../../data-access/evaluation-reducer";
import { useTranslation } from "react-i18next";
import { nextStep } from "../../../utils/next-step";
import TagManager from "react-gtm-module";
import { AuthContext } from "../../../utils/context";

const tagManagerArgs = {
  dataLayer: {
    event: "Step Evaluation Biometrics",
  },
};

export function TestFaceId() {
  TagManager.dataLayer(tagManagerArgs);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { configuration } = useContext(AuthContext);

  const LAST_INDEX = useSelector(lastStepIndexSelector);

  const [skipModalOpen, setSkipModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const biometricHandler = useCallback(async () => {
    if (LAST_INDEX === configuration.assessments.indexOf("face-id"))
      try {
        await navigator.credentials.create({
          publicKey: {
            rp: { name: "Trade In at Home" },
            user: {
              name: "Trade In at Home",
              id: new Uint8Array(new ArrayBuffer(8)),
              displayName: "Trade In at Home",
            },
            pubKeyCredParams: [{ type: "public-key", alg: -7 }],
            challenge: new Uint8Array(new ArrayBuffer(8)),
            authenticatorSelection: {
              authenticatorAttachment: "platform",
              userVerification: "required",
            },
          },
        });

        dispatch(
          evaluationActions.setCurrentStepIndex(
            configuration.assessments.indexOf("face-id") + 1
          )
        );
        dispatch(evaluationActions.setFaceIDPassed(true));
        navigate("../face-id-success");
      } catch (error) {
        setErrorModalOpen(true);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate, LAST_INDEX]);

  useEffect(() => {
    biometricHandler();
  }, [biometricHandler]);

  const toggleSkipModalHandler = () => {
    setSkipModalOpen(!skipModalOpen);
  };

  const skipFaceIDHandler = () => {
    dispatch(evaluationActions.setFaceIDPassed(false));
    navigate(nextStep(configuration, LAST_INDEX + 1));
  };

  return (
    <div className="test-face-id-page">
      <div className="container">
        <NavbarHeader>
          <ButtonBack
            iconType="close"
            onClick={toggleSkipModalHandler}
            backButtonType="button"
            imageLink={configuration.images_link}
          />
        </NavbarHeader>
        {skipModalOpen && (
          <Modal
            buttonPrimaryContent={t("test_face_id_page.skip_modal.button")}
            buttonLinkContent={t("common.skip_step")}
            buttonPrimaryHandler={() => {
              toggleSkipModalHandler();
              biometricHandler();
            }}
            buttonLinkType="button"
            buttonPrimaryType="button"
            buttonLinkHandler={skipFaceIDHandler}
          >
            <ConfirmMessage
              emoji="😔"
              title={t("test_face_id_page.skip_modal.title")}
              description={t("test_face_id_page.skip_modal.description")}
            />
          </Modal>
        )}
        {errorModalOpen && (
          <Modal
            buttonPrimaryContent={t("test_face_id_page.error_modal.button")}
            buttonPrimaryHandler={() => {
              setErrorModalOpen(false);
              biometricHandler();
            }}
            buttonPrimaryType="button"
            buttonLinkContent={t("test_face_id_page.error_modal.return")}
            buttonLinkType="link"
            buttonLinkLink="../face-id"
          >
            <div className="error-image">
              <img
                src="/assets/images/icons/face-id-error-icon.svg"
                alt="face-id-error"
              />
            </div>
            <ConfirmMessage
              title={t("test_face_id_page.error_modal.description")}
            />
          </Modal>
        )}
        <div className="image-container">
          <img
            src={`${configuration.images_link}/images/face-id-small.svg`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = "/assets/images/face-id-small.svg";
            }}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default TestFaceId;
