import "./schedule-pickup.scss";
import "rc-time-picker/assets/index.css";
import "react-datepicker/dist/react-datepicker.css";

import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
// @ts-ignore
import DatePicker from "react-datepicker";

import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { ButtonBack } from "@greenpanda/ui/button-back";
import { TopTitle } from "@greenpanda/ui/top-title";
import LocationAddress from "../../ui/location-address/location-address";
import { ButtonAction } from "@greenpanda/ui/button-action";
import Input from "../../ui/input/input";
import {
  tradeInAddressSelector,
  tradeInOfferSelector,
  tradeInSchedulePickupSelector,
  tradeInActions,
} from "../../data-access/tradein-reducer";
import { postAppointmentScooter } from "../../data-access/tradein-service";
import { AppointmentScooterData } from "../../data-access/tradein-service";
import { emailSelector } from "../../../evaluation/data-access/evaluation-reducer";
// @ts-ignore
import { ValueType } from "react-select";
import Dropdown from "../../ui/dropdown/dropdown";
import { format, parseISO } from "date-fns";
import TagManager from "react-gtm-module";
import { AuthContext } from "../../../utils/context";

const tagManagerArgs = {
  dataLayer: {
    event: "Step Scooter Form",
  },
};

export function SchedulePickup() {
  TagManager.dataLayer(tagManagerArgs);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { configuration, sessionId } = useContext(AuthContext);

  const tradeInAddress = useSelector(tradeInAddressSelector);
  const tradeInOffer = useSelector(tradeInOfferSelector);
  const email = useSelector(emailSelector);
  const tradeInSchedulePickup = useSelector(tradeInSchedulePickupSelector);

  const [buttonDisabled, isButtonDisabled] = useState<boolean>(false);
  const [phoneNumberValid, setPhoneNumberValid] = useState<boolean>(false);

  const timeOptions = [
    {
      value: "morning",
      label: t("time_option.morning"),
    },
    { value: "evening", label: t("time_option.evening") },
  ];

  useEffect(() => {
    dispatch(
      tradeInActions.setSchedulePickup({
        ...tradeInSchedulePickup,
        date: format(new Date(), "yyyy-MM-dd"),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChangeDatePicker = (date: Date): void => {
    const formParams = {
      ...tradeInSchedulePickup,
      date: format(date, "yyyy-MM-dd"),
    };
    dispatch(tradeInActions.setSchedulePickup(formParams));
  };

  const handleTimeOption = (
    selections: ValueType<typeof timeOptions[0], true>
  ) => {
    const formParams = { ...tradeInSchedulePickup, time: selections.value };
    dispatch(tradeInActions.setSchedulePickup(formParams));
  };

  const handleFullName = (fullName: string): void => {
    const formParams = { ...tradeInSchedulePickup, fullName: fullName };
    dispatch(tradeInActions.setSchedulePickup(formParams));
  };

  const handleBellNameFloor = (bellNameFloor: string): void => {
    const formParams = {
      ...tradeInSchedulePickup,
      bellNameFloor: bellNameFloor,
    };
    dispatch(tradeInActions.setSchedulePickup(formParams));
  };

  const handlePhoneNumber = (phoneNumber: string): void => {
    const PHONE_REGEX =
      /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

    const textMatch = phoneNumber.match(PHONE_REGEX);
    setPhoneNumberValid(textMatch === null ? true : false);
    const formParams = { ...tradeInSchedulePickup, phoneNumber: phoneNumber };
    dispatch(tradeInActions.setSchedulePickup(formParams));
  };

  const handleContinue = async (): Promise<void> => {
    isButtonDisabled(true);

    const params: AppointmentScooterData = {
      firstName: tradeInSchedulePickup.fullName,
      lastName: tradeInSchedulePickup.fullName,
      email: email,
      phone: tradeInSchedulePickup.phoneNumber,
      address: tradeInAddress.name,
      country: tradeInAddress.country,
      city: tradeInAddress.city,
      zipCode: tradeInAddress.postalCode,
      productId:
        tradeInOffer !== null && typeof tradeInOffer !== "undefined"
          ? tradeInOffer.productId
          : "",
      sessionId: sessionId,
      comments: "",
      appointmentDate: `${format(
        parseISO(tradeInSchedulePickup.date),
        "yyyy-MM-dd"
      )}`,
      appointmentTime: `${timeOptions[0].value}`,
      type: "scooter",
    };

    const appointmentScooter = await postAppointmentScooter(params);
    if (appointmentScooter.result === "success") {
      isButtonDisabled(false);
      navigate("../all-set/");
    }
  };

  return (
    <div className="schedule-pickup-page">
      <div className="container">
        <NavbarHeader>
          <ButtonBack
            iconType="back"
            backButtonType="button"
            onClick={() => navigate(-1)}
            imageLink={configuration.images_link}
          />
        </NavbarHeader>
        <img className="banner" src="/assets/images/home-pickup.png" alt="" />
        <TopTitle title={t("schedule_pickup_page.title")} />
        <LocationAddress address={tradeInAddress.name} />
        <div className="section-details">
          <p className="title">{t("schedule_pickup_page.details.title")}</p>
          <p className="description">
            {t("schedule_pickup_page.details.description")}
          </p>
        </div>
        <div className="date-container">
          <div className="input-wrapper date">
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={new Date(tradeInSchedulePickup.date)}
              onChange={(date: Date) => handleOnChangeDatePicker(date)}
            />
            <label htmlFor="date">
              {t("schedule_pickup_page.input_date.label")}
            </label>
            <img
              className="dropdown-icon"
              src="/assets/images/icons/next.svg"
              placeholder="dd/dd/dd"
              alt=""
            />
          </div>
          <div className="input-wrapper timeslot">
            <Dropdown onChange={handleTimeOption} options={timeOptions} />
            <label>{t("schedule_pickup_page.input_timeslot.label")}</label>
            <img
              className="dropdown-icon"
              src="/assets/images/icons/next.svg"
              alt=""
            />
          </div>
        </div>
        <div className="section-details">
          <p className="title">
            {t("schedule_pickup_page.contact_details.title")}
          </p>
          <p className="description">
            {t("schedule_pickup_page.contact_details.description")}
          </p>
        </div>
        <div className="contact-details">
          <Input
            inputType="input"
            placeholder={t("schedule_pickup_page.input_name.placeholder")}
            label={t("schedule_pickup_page.input_name.label")}
            onChangeCallback={(fullName: string) => handleFullName(fullName)}
            onResetCallback={() => handleFullName("")}
          />
          <Input
            inputType="input"
            placeholder={t("schedule_pickup_page.input_address.placeholder")}
            label={t("schedule_pickup_page.input_address.label")}
            onChangeCallback={(bellNameFloor: string) =>
              handleBellNameFloor(bellNameFloor)
            }
            onResetCallback={() => handleBellNameFloor("")}
          />
          <Input
            inputType="input"
            placeholder={t("schedule_pickup_page.input_phone.placeholder")}
            label={t("schedule_pickup_page.input_phone.label")}
            onChangeCallback={(phoneNumber: string) =>
              handlePhoneNumber(phoneNumber)
            }
            onResetCallback={() => handlePhoneNumber("")}
            validation={{
              isValid: !phoneNumberValid,
              invalidMessage: t("common.invalid_phonenumber"),
            }}
          />
        </div>
        <div className="button-container">
          <ButtonAction
            primary={true}
            buttonType="button"
            onClick={() => handleContinue()}
            disabled={
              buttonDisabled === true ||
              tradeInSchedulePickup.bellNameFloor === "" ||
              tradeInSchedulePickup.fullName === "" ||
              tradeInSchedulePickup.phoneNumber === "" ||
              tradeInSchedulePickup.time === ""
            }
          >
            {t("common.continue")}
          </ButtonAction>
        </div>
      </div>
    </div>
  );
}

export default SchedulePickup;
