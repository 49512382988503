import { ConfigurationResponse } from "../../configuration/configuration-response";
import { DeviceAssessmentsData } from "../../evaluation/data-access/evaluation-service";

export function createWebQuoteParts(
  deviceAssessmentsData: DeviceAssessmentsData,
  configuration: ConfigurationResponse
) {
  const webQuoteParts = [
    {
      code: "phone_digitizer_works",
      condition: configuration.assessments.includes("digitizer")
        ? deviceAssessmentsData.digitizer
        : null,
    },
    {
      code: "phone_audio_works",
      condition: configuration.assessments.includes("sound-performance")
        ? deviceAssessmentsData.sound
        : null,
    },
    {
      code: "phone_biometrics_work",
      condition: configuration.assessments.includes("face-id")
        ? deviceAssessmentsData.biometrics
        : null,
    },
    {
      code: "phone_front_camera",
      condition: configuration.assessments.includes("front-camera")
        ? deviceAssessmentsData.frontCamera
        : null,
    },
    {
      code: "phone_back_camera",
      condition: configuration.assessments.includes("back-camera")
        ? deviceAssessmentsData.backCamera
        : null,
    },
  ];

  return webQuoteParts;
}
