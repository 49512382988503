import "./faq.scss";

import { useContext, useState } from "react";

import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { ButtonBack } from "@greenpanda/ui/button-back";
import { TopTitle } from "@greenpanda/ui/top-title";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../utils/context";

export type ActiveQuestionState = "" | `question-${number}`;

export function FAQ() {
  const { t } = useTranslation();
  const { configuration } = useContext(AuthContext);
  const [activeQuestion, setActiveQuestion] = useState<ActiveQuestionState>("");

  const handleClickOnQuestion = (question: ActiveQuestionState): void => {
    if (question === activeQuestion) setActiveQuestion("");
    else setActiveQuestion(question);
  };

  return (
    <div className="faq-page">
      <NavbarHeader>
        <ButtonBack
          iconType="white-back"
          backButtonType="link"
          to="../more"
          imageLink={configuration.images_link}
        />
        <p className="title">{t("faq_page.title")}</p>
      </NavbarHeader>
      <div className="container">
        <TopTitle title={t("faq_page.top_title")} />
        <div className="question-container">
          <div
            className={`question ${
              activeQuestion === "question-1" ? "expanded" : "collapsed"
            }`}
          >
            <div
              className="question-top"
              onClick={() => handleClickOnQuestion("question-1")}
            >
              <img src="/assets/images/icons/faq-dropdown.svg" alt="" />
              <p>{t("faq_page.question_1.question")}</p>
            </div>
            <div className="question-bottom">
              <p
                dangerouslySetInnerHTML={{
                  __html: t("faq_page.question_1.answer"),
                }}
              />
            </div>
          </div>
          <div
            className={`question ${
              activeQuestion === "question-2" ? "expanded" : "collapsed"
            }`}
          >
            <div
              className="question-top"
              onClick={() => handleClickOnQuestion("question-2")}
            >
              <img src="/assets/images/icons/faq-dropdown.svg" alt="" />
              <p>{t("faq_page.question_2.question")}</p>
            </div>
            <div className="question-bottom">
              <p
                dangerouslySetInnerHTML={{
                  __html: t("faq_page.question_2.answer"),
                }}
              />
            </div>
          </div>
          <div
            className={`question ${
              activeQuestion === "question-3" ? "expanded" : "collapsed"
            }`}
          >
            <div
              className="question-top"
              onClick={() => handleClickOnQuestion("question-3")}
            >
              <img src="/assets/images/icons/faq-dropdown.svg" alt="" />
              <p>{t("faq_page.question_3.question")}</p>
            </div>
            <div className="question-bottom">
              <p
                dangerouslySetInnerHTML={{
                  __html: t("faq_page.question_3.answer"),
                }}
              />
            </div>
          </div>
          <div
            className={`question ${
              activeQuestion === "question-4" ? "expanded" : "collapsed"
            }`}
          >
            <div
              className="question-top"
              onClick={() => handleClickOnQuestion("question-4")}
            >
              <img src="/assets/images/icons/faq-dropdown.svg" alt="" />
              <p>{t("faq_page.question_4.question")}</p>
            </div>
            <div className="question-bottom">
              <p
                dangerouslySetInnerHTML={{
                  __html: t("faq_page.question_4.answer"),
                }}
              />
            </div>
          </div>
          <div
            className={`question ${
              activeQuestion === "question-5" ? "expanded" : "collapsed"
            }`}
          >
            <div
              className="question-top"
              onClick={() => handleClickOnQuestion("question-5")}
            >
              <img src="/assets/images/icons/faq-dropdown.svg" alt="" />
              <p>{t("faq_page.question_5.question")}</p>
            </div>
            <div className="question-bottom">
              <p
                dangerouslySetInnerHTML={{
                  __html: t("faq_page.question_5.answer"),
                }}
              />
            </div>
          </div>
          <div
            className={`question ${
              activeQuestion === "question-6" ? "expanded" : "collapsed"
            }`}
          >
            <div
              className="question-top"
              onClick={() => handleClickOnQuestion("question-6")}
            >
              <img src="/assets/images/icons/faq-dropdown.svg" alt="" />
              <p>{t("faq_page.question_6.question")}</p>
            </div>
            <div className="question-bottom">
              <p
                dangerouslySetInnerHTML={{
                  __html: t("faq_page.question_6.answer"),
                }}
              />
            </div>
          </div>
          <div
            className={`question ${
              activeQuestion === "question-7" ? "expanded" : "collapsed"
            }`}
          >
            <div
              className="question-top"
              onClick={() => handleClickOnQuestion("question-7")}
            >
              <img src="/assets/images/icons/faq-dropdown.svg" alt="" />
              <p>{t("faq_page.question_7.question")}</p>
            </div>
            <div className="question-bottom">
              <p
                dangerouslySetInnerHTML={{
                  __html: t("faq_page.question_7.answer"),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
