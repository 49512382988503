import "./device-info.scss";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { deviceInfoSelector } from "../../../tradein/data-access/tradein-reducer";
import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { ButtonBack } from "@greenpanda/ui/button-back";
import { useNavigate } from "react-router-dom";
import { ButtonAction } from "@greenpanda/ui/button-action";
import { useContext } from "react";
import { AuthContext } from "../../../utils/context";

export function DeviceInfo() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { configuration } = useContext(AuthContext);

  const deviceInfo = useSelector(deviceInfoSelector);

  return (
    <div className="device-info-page">
      <div className="container">
        <NavbarHeader>
          <ButtonBack
            iconType="back"
            backButtonType="button"
            onClick={() => navigate(-1)}
            imageLink={configuration.images_link}
          />
        </NavbarHeader>
        <div style={{ textAlign: "center" }}>
          <img src="/assets/images/device-info.svg" alt="device-info" />
        </div>
        <div className="device-info">
          <p className="brand-model">{deviceInfo?.brand}</p>
          <p className="brand-model">{deviceInfo?.model}</p>
          {deviceInfo?.brand === "Apple" && (
            <p className="capacity-color">
              {deviceInfo?.capacity} / {deviceInfo?.color}
            </p>
          )}
        </div>
        <div className="instructions">
          <img src="/assets/images/icons/swap-icon.svg" alt="swap-icon" />
          <p
            dangerouslySetInnerHTML={{
              __html: t("device_info_page.instructions"),
            }}
          />
        </div>
        {deviceInfo?.brand === "Apple" && (
          <div className="warning">
            <img
              src="/assets/images/icons/wavy-warning-icon.svg"
              alt="wavy-warning-icon"
            />
            <p>{t("device_info_page.warning")}</p>
          </div>
        )}
        <div className="button-container">
          <ButtonAction primary={true} buttonType="link" to="../what-you-need">
            {t("common.continue")}
          </ButtonAction>
        </div>
      </div>
    </div>
  );
}

export default DeviceInfo;
