export const inputAddress = (place: google.maps.places.PlaceResult) => {
  return {
    name: place.formatted_address ?? "",
    coordinates: {
      latitude: place.geometry?.location?.lat() ?? 0,
      longitude: place.geometry?.location?.lng() ?? 0,
    },
    postalCode:
      place.address_components
        ?.filter((component) => component.types.includes("postal_code"))
        .at(0)?.long_name ?? "",
    city:
      place.address_components
        ?.filter((component) => component.types.includes("locality"))
        .at(0)?.long_name ?? "",
    country:
      place.address_components
        ?.filter((component) => component.types.includes("country"))
        .at(0)?.long_name ?? "",
  };
};
