import "./whats-next-courier.scss";
import WhatsNext from "../../ui/whats-next/whats-next";
import { useTranslation } from "react-i18next";

export function WhatsNextCourier() {
  const { t } = useTranslation();

  const COURIER_DESCRIPTION = t("whats_next_page.courier.description");
  const COURIER_FOOTER_MESSAGE = t("whats_next_page.courier.footer");
  const COURIER_BACK_BUTTON_LINK = "../";
  const COURIER_INSTRUCTION_STEP = [
    {
      imgSrc: "/assets/images/icons/icloud-signout-icon.svg",
      title: t("whats_next_page.icloud.title"),
      description: t("whats_next_page.icloud.description"),
    },
    {
      imgSrc: "/assets/images/icons/enroll-device-icon.svg",
      title: t("whats_next_page.pandas.title"),
      description: t("whats_next_page.icloud.description"),
    },
    {
      imgSrc: "/assets/images/icons/credit-card-icon.svg",
      title: t("whats_next_page.iban.title"),
      description: t("whats_next_page.iban.description"),
    },
  ];

  return (
    <WhatsNext
      description={COURIER_DESCRIPTION}
      listItems={COURIER_INSTRUCTION_STEP}
      footerMessage={COURIER_FOOTER_MESSAGE}
      backButtonLink={COURIER_BACK_BUTTON_LINK}
    />
  );
}

export default WhatsNextCourier;
