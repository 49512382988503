import {
  tap,
  pipe,
  MonoTypeOperatorFunction,
  Observable,
  mergeMap,
  map,
  filter,
} from "rxjs";

export const attachStreamToVideo = <T extends { stream: MediaStream }>(
  videoEl$: Observable<HTMLVideoElement | null>
): MonoTypeOperatorFunction<T> => {
  return pipe(
    mergeMap((data) =>
      videoEl$.pipe(
        filter(Boolean),
        tap((videoEl) => (videoEl.srcObject = data.stream)),
        map(() => data)
      )
    )
  );
};
