import "./whats-next.scss";

import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { ButtonBack } from "@greenpanda/ui/button-back";
import { TopTitle } from "@greenpanda/ui/top-title";
import { ListItem } from "@greenpanda/ui/list-item";
import { ButtonAction } from "@greenpanda/ui/button-action";
import PrivacyMessage from "../../../../../evaluation/ui/privacy-message/privacy-message";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { AuthContext } from "../../../../../utils/context";

interface instructionListInterface {
  imgSrc: string;
  title: string;
  description: string;
}
export interface WhatsNextProps {
  description: string;
  listItems: Array<instructionListInterface>;
  footerMessage: string;
  backButtonLink: string;
}

export function WhatsNext({
  description,
  listItems,
  footerMessage,
  backButtonLink,
}: WhatsNextProps) {
  const { t } = useTranslation();
  const { configuration } = useContext(AuthContext);

  return (
    <div className="whats-next-page">
      <div className="container">
        <NavbarHeader>
          <ButtonBack
            iconType="back"
            backButtonType="link"
            to={backButtonLink}
            imageLink={configuration.images_link}
          />
        </NavbarHeader>
        <TopTitle title="What's next?" description={description} />
        <div className="list-container">
          {listItems.map((item, index) => (
            <ListItem
              key={index}
              imgSrc={item.imgSrc}
              title={item.title}
              description={item.description}
            />
          ))}
        </div>
        <div className="button-container">
          <ButtonAction primary={true} buttonType="link" to="../signout-icloud">
            {t("whats_next_page.signout_icloud")}
          </ButtonAction>
        </div>
        <div className="privacy-container">
          <PrivacyMessage
            customIcon="/assets/images/report-icons/status-poor.svg"
            message={footerMessage}
          />
        </div>
      </div>
    </div>
  );
}

export default WhatsNext;
