import "./user-info.scss";
import "react-datepicker/dist/react-datepicker.css";

import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { ButtonBack } from "@greenpanda/ui/button-back";
import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { TopTitle } from "@greenpanda/ui/top-title";
import { ButtonAction } from "@greenpanda/ui/button-action";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import Input from "../../../tradein/ui/input/input";
import {
  evaluationActions,
  evaluationStateSelector,
} from "../../data-access/evaluation-reducer";
import { AuthContext } from "../../../utils/context";
import { WURFL } from "../../../../constants";

const tagManagerArgs = {
  dataLayer: {
    event: "Step User Info",
  },
};

export function UserInfo() {
  TagManager.dataLayer(tagManagerArgs);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { configuration, sessionId } = useContext(AuthContext);

  const evaluation = useSelector(evaluationStateSelector);

  const [emailValid, setEmailValid] = useState<boolean>(false);
  const [phoneNumberValid, setPhoneNumberValid] = useState<boolean>(false);

  const handleFullName = (fullName: string): void => {
    dispatch(
      evaluationActions.setUserInfo({
        ...evaluation.userInfo,
        fullName: fullName,
      })
    );
  };

  const handleEmail = (email: string): void => {
    const MATCH_EMAIL_REGEX =
      /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const textMatch = email.match(MATCH_EMAIL_REGEX);
    setEmailValid(textMatch === null ? true : false);
    dispatch(
      evaluationActions.setUserInfo({
        ...evaluation.userInfo,
        email: textMatch === null ? "" : email,
      })
    );
  };

  const handlePhoneNumber = (phoneNumber: string): void => {
    const PHONE_REGEX =
      /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    const textMatch = phoneNumber.match(PHONE_REGEX);
    setPhoneNumberValid(textMatch === null ? true : false);
    dispatch(
      evaluationActions.setUserInfo({
        ...evaluation.userInfo,
        phoneNumber: textMatch === null ? "" : phoneNumber,
      })
    );
  };

  const handleConfirmation = async (): Promise<void> => {
    navigate(
      WURFL.advertised_device_os === "iOS"
        ? `/${configuration.partner.code}/${sessionId}/trade-in/reveal-imei-ios`
        : `/${configuration.partner.code}/${sessionId}/trade-in/reveal-imei-android`
    );
  };

  return (
    <div className="user-info-page">
      <div className="container">
        <NavbarHeader>
          <ButtonBack
            iconType="back"
            backButtonType="button"
            onClick={() => navigate(-1)}
            imageLink={configuration.images_link}
          />
        </NavbarHeader>
        <TopTitle
          title="Personal info"
          description="Enter your personal info to continue"
        />
        <div className="contact-details">
          <Input
            inputType="text"
            placeholder={t("courier_page.input_full_name")}
            label={t("courier_page.input_full_name")}
            defaultValue={evaluation.userInfo.fullName}
            onChangeCallback={(fullName: string) => handleFullName(fullName)}
            onResetCallback={() => handleFullName("")}
          />
          <Input
            inputType="text"
            placeholder="Email"
            label="Email"
            defaultValue={evaluation.userInfo.email}
            onChangeCallback={(email: string) => handleEmail(email)}
            onResetCallback={() => handleEmail("")}
            validation={{
              isValid: !emailValid,
              invalidMessage: t("get_quote_page.input.invalid_email"),
            }}
          />
          <Input
            inputType="text"
            placeholder={t("courier_page.input_phone")}
            label="Phone number"
            defaultValue={evaluation.userInfo.phoneNumber}
            onChangeCallback={(phoneNumber: string) =>
              handlePhoneNumber(phoneNumber)
            }
            onResetCallback={() => handlePhoneNumber("")}
            validation={{
              isValid: !phoneNumberValid,
              invalidMessage: t("common.invalid_phonenumber"),
            }}
          />
        </div>
        <div className="button-container">
          <ButtonAction
            primary={true}
            buttonType="button"
            onClick={() => handleConfirmation()}
            disabled={
              evaluation.userInfo.fullName === "" ||
              evaluation.userInfo.email === "" ||
              evaluation.userInfo.phoneNumber === ""
            }
          >
            {t("common.continue")}
          </ButtonAction>
        </div>
      </div>
    </div>
  );
}

export default UserInfo;
