import "./impact.scss";

import { useTranslation } from "react-i18next";

import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { ButtonBack } from "@greenpanda/ui/button-back";
import { ListItem } from "@greenpanda/ui/list-item";
import { TopTitle } from "@greenpanda/ui/top-title";
import { useContext } from "react";
import { AuthContext } from "../../../utils/context";
import { goToStartPage } from "../../../utils/go-to-start-page";

export function Impact() {
  const { t } = useTranslation();
  const { configuration, sessionId } = useContext(AuthContext);

  return (
    <div className="impact-page">
      <div className="container">
        <NavbarHeader>
          <ButtonBack
            backButtonType="link"
            to={`${goToStartPage(configuration, sessionId)}/start`}
            iconType="back"
            imageLink={configuration.images_link}
          />
          <p className="title">{t("impact_page.title")}</p>
        </NavbarHeader>
        <TopTitle title={t("impact_page.top_title")} />
        <div className="list-container">
          <ListItem
            imgSrc="/assets/images/icons/reuse-icon.svg"
            title={t("impact_page.list_item_1.title")}
            description={t("impact_page.list_item_1.content")}
          />
          <ListItem
            imgSrc="/assets/images/icons/device-tree-icon.svg"
            title={t("impact_page.list_item_2.title")}
            description={t("impact_page.list_item_2.content")}
          />
        </div>
        <div className="emission-container">
          <img src="/assets/images/icons/car-eco.svg" alt="" />
          <p
            dangerouslySetInnerHTML={{ __html: t("impact_page.emission_info") }}
          />
        </div>
      </div>
    </div>
  );
}

export default Impact;
