import "./test-camera.scss";

import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useState } from "react";

import { ButtonBack } from "@greenpanda/ui/button-back";
import ObjectDetection from "../../../object-detection/object-detection";
import Instructions from "../../ui/instructions/instructions";
import { Modal } from "@greenpanda/ui/modal";
import { ConfirmMessage } from "@greenpanda/ui/confirm-message";

import {
  evaluationActions,
  lastStepIndexSelector,
} from "../../data-access/evaluation-reducer";
import { appConfigSelector } from "../../../data-access/app-reducer";
import { useTranslation } from "react-i18next";
import { nextStep } from "../../../utils/next-step";
import TagManager from "react-gtm-module";
import { AuthContext } from "../../../utils/context";

const tagManagerArgs = {
  dataLayer: {
    event: "Step Evaluation Front Camera",
  },
};

export function TestCamera() {
  TagManager.dataLayer(tagManagerArgs);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { configuration } = useContext(AuthContext);

  const LAST_INDEX = useSelector(lastStepIndexSelector);

  const appConfig = useSelector(appConfigSelector);

  const [skipModalOpen, setSkipModalOpen] = useState(false);

  const toggleSkipModalHandler = () => {
    setSkipModalOpen(!skipModalOpen);
  };

  const skipFrontCameraHandler = () => {
    dispatch(evaluationActions.setFrontCameraURL(null));
    navigate(nextStep(configuration, LAST_INDEX + 1));
  };

  const handlePhotoCallback = (photo: string | undefined): void => {
    if (photo) dispatch(evaluationActions.setFrontCameraURL(photo));
    else dispatch(evaluationActions.setFrontCameraURL("frontCameraPhoto"));
    dispatch(
      evaluationActions.setCurrentStepIndex(
        configuration.assessments.indexOf("front-camera") + 1
      )
    );
    navigate("../front-camera-success");
  };

  return (
    <div className="test-camera-page">
      {skipModalOpen && (
        <Modal
          buttonPrimaryContent={t("test_camera_page.modal.primary_button")}
          buttonLinkContent={t("common.skip_step")}
          buttonPrimaryHandler={toggleSkipModalHandler}
          buttonLinkType="button"
          buttonPrimaryType="button"
          buttonLinkHandler={skipFrontCameraHandler}
        >
          <ConfirmMessage
            emoji="😔"
            title={t("test_camera_page.modal.title")}
            description={t("test_camera_page.modal.description")}
          />
        </Modal>
      )}
      <ButtonBack
        iconType="close"
        onClick={toggleSkipModalHandler}
        backButtonType="button"
        style={{
          position: "absolute",
          top: "calc(6px + env(safe-area-inset-top))",
          left: 16,
          zIndex: 99,
        }}
        imageLink={configuration.images_link}
      />
      <ObjectDetection
        facingMode="user"
        appConfig={appConfig}
        photoCallback={(photo) => handlePhotoCallback(photo)}
        instructionsCallback={(startDetection) => {
          return (
            <div>
              {!startDetection && configuration.mirror ? (
                <div id="turn-your-device">
                  <img
                    src="/assets/images/icons/rotate-phone.svg"
                    alt={t("test_camera_page.instructions.alt_img")}
                  />
                  <Instructions
                    title={t("test_camera_page.instructions.title")}
                    description={t("test_camera_page.instructions.description")}
                  />
                </div>
              ) : (
                <div></div>
              )}
            </div>
          );
        }}
      />
    </div>
  );
}

export default TestCamera;
