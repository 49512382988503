import "./device-motion.scss";

import { useDispatch, useSelector } from "react-redux";

import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { ButtonBack } from "@greenpanda/ui/button-back";
import { ConfirmMessage } from "@greenpanda/ui/confirm-message";
import { ButtonAction } from "@greenpanda/ui/button-action";

import {
  evaluationActions,
  lastStepIndexSelector,
  lastStepSelector,
} from "../../data-access/evaluation-reducer";
import { useTranslation } from "react-i18next";
import { nextStep } from "../../../utils/next-step";
import { useEffect, useState, useContext } from "react";
import { Modal } from "@greenpanda/ui/modal";

import { useNavigate } from "react-router-dom";
import { UiModalMessage } from "@greenpanda/ui/modal-message";
import StepsProgress from "../../ui/steps-progress/steps-progress";
import { TopTitle } from "@greenpanda/ui/top-title";
import { AuthContext } from "../../../utils/context";

export function DeviceMotion() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { configuration } = useContext(AuthContext);
  const LAST_INDEX = useSelector(lastStepIndexSelector);
  const lastStep = useSelector(lastStepSelector);

  const [exitModalOpen, setExitModalOpen] = useState(false);
  const [skipModalOpen, setSkipModalOpen] = useState(false);

  const toggleExitModalHandler = () => {
    setExitModalOpen(!exitModalOpen);
  };

  const toggleSkipModalHandler = () => {
    setSkipModalOpen(!skipModalOpen);
  };

  const skipDeviceMotionEvaluation = () => {
    dispatch(evaluationActions.setDeviceMotionPassed(false));
    navigate(nextStep(configuration, LAST_INDEX + 1));
  };

  useEffect(() => {
    dispatch(
      evaluationActions.setCurrentStepIndex(
        configuration.assessments.indexOf("device-motion")
      )
    );
  }, [dispatch, configuration]);

  const handleRequestPermission = async () => {
    // WARNING: to resume request permission, reload browser

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((DeviceOrientationEvent as any).requestPermission)
      try {
        const response =
          await // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (DeviceOrientationEvent as any).requestPermission();
        // alert(response);
        if (response === "granted") navigate("../evaluate-device-motion");
        else toggleSkipModalHandler();
      } catch (error) {
        toggleSkipModalHandler();
      }
    else navigate("../evaluate-device-motion");
  };

  return (
    <div className="feature-page face-id-page">
      <div className="container">
        {exitModalOpen && (
          <Modal
            buttonPrimaryContent={t("common.resume_evaluation")}
            buttonLinkContent={t("common.exit_evaluation")}
            buttonPrimaryHandler={toggleExitModalHandler}
            buttonLinkType="link"
            buttonPrimaryType="button"
            buttonLinkLink="/start"
          >
            <ConfirmMessage
              emoji="🧐"
              title={t("common.exit_evaluation") + "?"}
              description={t("common.evaluation_warning")}
            />
          </Modal>
        )}
        {skipModalOpen && (
          <Modal
            buttonPrimaryContent={t("common.cancel")}
            buttonLinkContent={t("common.continue")}
            buttonPrimaryHandler={toggleSkipModalHandler}
            buttonLinkType="button"
            buttonPrimaryType="button"
            buttonLinkHandler={skipDeviceMotionEvaluation}
            firstButtonIsOutline={true}
            secondButtonIsOutline={false}
          >
            <UiModalMessage
              icon="/assets/images/icons/warning-icon.svg"
              title={t("device_motion_page.skip_modal.title")}
              description={t("common.offer_warning")}
            />
          </Modal>
        )}

        <NavbarHeader>
          <ButtonBack
            backButtonType="button"
            iconType="close"
            onClick={toggleExitModalHandler}
          />
          <StepsProgress
            step={lastStep}
            total={configuration.assessments.length}
          />
        </NavbarHeader>
        <TopTitle
          subtitle={t("common.lets_evaluate")}
          title={t("device_motion_page.evaluate_title")}
          description={t("device_motion_page.evaluate_description")}
        />

        <div className="image-container">
          <img src="/assets/images/device-motion.svg" alt="" />
        </div>

        <div className="button-container">
          <ButtonAction
            primary={true}
            buttonType="button"
            onClick={handleRequestPermission}
          >
            {t("device_motion_page.check_device_motion")}
          </ButtonAction>
          <ButtonAction
            primary={false}
            onClick={toggleSkipModalHandler}
            buttonType="button"
          >
            {t("device_motion_page.no_device_motion")}
          </ButtonAction>
        </div>
      </div>
    </div>
  );
}

export default DeviceMotion;
