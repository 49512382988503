export default function getFilledInPixels(
  stride: number,
  imageData: ImageData
): number {
  const newStride = !stride || stride < 1 ? 1 : stride;
  const pdata = imageData?.data ?? [];
  const l = pdata?.length ?? 0;
  const total = l / newStride;
  let count = 0;

  for (let i = 0; i < l; i += newStride) if (+pdata[i] === 0) count++;

  return (count / total) * 100;
}
