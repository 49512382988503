import { useTranslation } from "react-i18next";
import "./courier-instructions.scss";

export function CourierInstructions() {
  const { t } = useTranslation();

  const instructionsList = [
    { img: "note-pencil-icon", text: t("courier_page.fill_in_your_details") },
    {
      img: "package-icon",
      text: t("courier_page.a_courier_picks_up_your_device"),
    },
    {
      img: "mobile-cash-icon",
      text: t("courier_page.you_get_an_offer"),
    },
    {
      img: "accept-icon",
      text: t("courier_page.you_are_paid_instantly"),
    },
  ];
  return (
    <div>
      {instructionsList.map((element, index) => {
        return (
          <div key={index} className="instruction-item">
            <img src={`assets/images/icons/${element.img}.svg`} alt="" />
            <div className="text">
              <p>{element.text}</p>
              <div
                className={
                  index !== instructionsList.length - 1 ? "divider" : ""
                }
              ></div>
            </div>
          </div>
        );
      })}
      <div className="continue">
        <img
          src="/assets/images/icons/arrow-down-icon.svg"
          alt="arrow-down-icon"
        />
        <p>{t("courier_page.continue_to_the_form")}</p>
        <img
          src="/assets/images/icons/arrow-down-icon.svg"
          alt="arrow-down-icon"
        />
      </div>
    </div>
  );
}

export default CourierInstructions;
