import "./option-card.scss";
import { useContext } from "react";
import { AuthContext } from "../../../utils/context";

export interface OptionCardProps {
  title: string;
  description: string;
  imgSrc: string;
  imgDefault?: string;
  onClick: () => void;
}

export function OptionCard({
  title,
  description,
  imgSrc,
  imgDefault,
  onClick,
}: OptionCardProps) {
  const { configuration } = useContext(AuthContext);

  return (
    <div
      className={`trade-card ${
        configuration.partner.code === "orangepl" && "store"
      }`}
      onClick={onClick}
    >
      <img
        src={imgSrc}
        onError={
          imgDefault
            ? ({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = imgDefault;
              }
            : undefined
        }
        alt=""
      />
      <div
        style={{
          margin: configuration.partner.code === "orangepl" ? "auto 0" : "0",
        }}
      >
        <p className="title">{title}</p>
        <p
          className="description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </div>
  );
}

export default OptionCard;
