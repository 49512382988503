export interface ContactEmailData {
  first_name: string;
  last_name: string;
  email: string;
  message: string;
}

export interface CheckImeiData {
  imei: string;
}

export interface CheckImeiResponse {
  error?: string;
  brand: string;
  model: string;
  color: string;
  capacity: string;
}

export async function postEmailContact(
  data: ContactEmailData,
  link: string
): Promise<number> {
  const response = await fetch(`${link}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return response.status;
}

export async function postCheckImei(
  data: CheckImeiData
): Promise<CheckImeiResponse> {
  const response = await fetch(`${process.env["NX_IMEI_SERVICE_URL"]}/check`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return response.json();
}
