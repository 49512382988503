import {
  DetectedObject,
  ObjectDetection,
  load,
} from "@tensorflow-models/coco-ssd";
import { WorkerConnector } from "./connector";

export class DetectionService {
  private static instance?: DetectionService;

  private model?: ObjectDetection;
  private worker?: WorkerConnector;

  constructor() {
    if (DetectionService.instance) return DetectionService.instance;
    DetectionService.instance = this;
  }

  dispose(): void {
    this.model?.dispose();
    this.worker?.terminate();
    delete this.worker;
    delete this.model;
    delete DetectionService.instance;
  }

  async load() {
    if (this.model) return;
    await Promise.all([
      import("@tensorflow/tfjs-backend-cpu"),
      import("@tensorflow/tfjs-backend-webgl"),
    ]);

    this.model = await load();
  }

  async detect(
    img: ImageData,
    maxNumBoxes?: number | undefined,
    minScore?: number | undefined
  ): Promise<Array<DetectedObject>> {
    if (this.model) return this.model.detect(img, maxNumBoxes, minScore);
    if (!this.worker) await this.load();
    const response = await this.worker!.sendMessage({
      type: "DETECT",
      data: img,
    });
    return response.payload;
  }
}
