import "./legal.scss";
import { ButtonBack } from "@greenpanda/ui/button-back";
import { useNavigate } from "react-router";
import { AuthContext } from "../../../utils/context";
import { useContext } from "react";

export interface LegalProps {
  title: string;
  content: string;
}

export function Legal({ title, content }: LegalProps) {
  const navigate = useNavigate();
  const { configuration } = useContext(AuthContext);

  return (
    <div id="privacy-policy-page">
      <ButtonBack
        onClick={() => navigate(-1)}
        iconType="back"
        backButtonType="button"
        style={{
          position: "absolute",
          top: "calc(6px + env(safe-area-inset-top))",
          left: 16,
          zIndex: 99,
        }}
        imageLink={configuration.images_link}
      />
      <h1>{title}</h1>
      <div
        id="content"
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    </div>
  );
}

export default Legal;
