import "./how-to.scss";
import React from "react";

export interface HowToProps {
  instructions: Array<string>;
  title: string;
}

export function HowTo({ instructions, title }: HowToProps) {
  return (
    <div className="how-to-instructions">
      <p className="title">{title}</p>
      <ul>
        {instructions.map((instruction, index) => (
          <li key={index}>
            <span>{index + 1}</span>
            {instruction}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default HowTo;
