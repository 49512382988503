import "./ui-modal-message.scss";

export interface UiModalMessageProps {
  icon: string;
  title: string;
  description?: string;
  items?: Array<{ icon: string; title: string }>;
}

export function UiModalMessage(props: UiModalMessageProps) {
  return (
    <div className="ui-modal-message">
      <img src={props.icon} alt={props.title} />
      <p className="title">{props.title}</p>
      {typeof props.description !== "undefined" && (
        <p
          className="description"
          dangerouslySetInnerHTML={{ __html: props.description }}
        />
      )}
      {typeof props.items !== "undefined" && (
        <ul>
          {props.items.map((item, key) => {
            return (
              <li key={`ui-modal-message-item-${key}`}>
                <img src={item.icon} alt={item.title} />
                <p>{item.title}</p>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

export default UiModalMessage;
