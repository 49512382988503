import "./languages.scss";

import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { ButtonBack } from "@greenpanda/ui/button-back";
import {
  appActions,
  appLanguagesSelector,
} from "../../../data-access/app-reducer";
import {
  getLanguages,
  LanguagesResponse,
  getTranslation,
} from "../../../data-access/app-service";
import { AuthContext } from "../../../utils/context";

export function Languages() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { configuration } = useContext(AuthContext);

  const appLanguages = useSelector(appLanguagesSelector);

  useEffect(() => {
    const fetchLanguages = async () => {
      const response: LanguagesResponse = await getLanguages(
        configuration.language.endpoint
      );
      dispatch(appActions.setLanguages(response));
    };
    fetchLanguages();
  }, [dispatch, configuration]);

  const handleLanguageChange = async (event: { target: { value: string } }) => {
    const response = await getTranslation(event.target.value);

    i18n.addResourceBundle(event.target.value, "common", response);
    i18n.changeLanguage(event.target.value);
    document.documentElement.lang = event.target.value;
  };

  return (
    <div className="languages-page">
      <div className="container">
        <NavbarHeader>
          <ButtonBack
            iconType="back"
            backButtonType="link"
            to="../more"
            imageLink={configuration.images_link}
          />
          <p className="title">{t("language_page.language")}</p>
        </NavbarHeader>
        {appLanguages.length > 0 && (
          <div className="language-container">
            {appLanguages.map((language, index) => {
              return (
                <div key={`language-${index}`} className="language">
                  <img
                    src={`/assets/images/flags/${language.slug}.svg`}
                    alt={language.name}
                  />
                  <div className="input-container">
                    <p>{language.name}</p>
                    <div className="input-label">
                      <input
                        onChange={handleLanguageChange}
                        checked={
                          i18n.language === language.slug ||
                          (i18n.language === "en-US" && language.slug === "en")
                        }
                        value={language.slug}
                        type="radio"
                        id={language.name.toLowerCase()}
                      />
                      <label htmlFor={language.name.toLowerCase()} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default Languages;
