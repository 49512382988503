import { ConfigurationResponse } from "../configuration/configuration-response";

export const nextStep = (
  configuration: ConfigurationResponse,
  lastindex: number
) => {
  let path;
  if (
    configuration.assessments[configuration.assessments.length - 1] ===
    configuration.assessments[lastindex]
  )
    if (configuration.at_store_flow) path = `../user-info`;
    else if (configuration.max_capacity_price) path = `../loading`;
    else path = path = `../${configuration.assessments[lastindex]}`;
  else path = `../${configuration.assessments[lastindex]}`;

  return path;
};
