import "./status-message.scss";

import { useEffect } from "react";
import { useNavigate } from "react-router";
export interface StatusMessageProps {
  imgSrc?: string;
  imgDefault?: string;
  videoSrc?: string;
  videoDefault?: string;
  title: string;
  link: string;
  device?: string;
  specs?: string;
  marginTop?: number;
  timeout?: number;
}

export function StatusMessage({
  imgSrc,
  imgDefault,
  videoSrc,
  videoDefault,
  title,
  device,
  specs,
  link,
  marginTop,
  timeout,
}: StatusMessageProps) {
  const navigate = useNavigate();

  useEffect(() => {
    if (typeof timeout !== "undefined") {
      const handleTimeout: NodeJS.Timeout = setTimeout(() => {
        navigate(link);
      }, timeout);

      return () => {
        clearTimeout(handleTimeout);
      };
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, [link, navigate, timeout]);

  const nextPageHandler = function () {
    navigate(link);
  };

  return (
    <div className="status-message-page" onClick={nextPageHandler}>
      <div className="container">
        <div className="message-container">
          {videoSrc && (
            <video loop autoPlay={true} muted playsInline controls={false}>
              <source
                src={videoSrc}
                onError={
                  videoDefault
                    ? ({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = videoDefault;
                      }
                    : undefined
                }
              />
            </video>
          )}
          {imgSrc && (
            <img
              src={imgSrc}
              onError={
                imgDefault
                  ? ({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = imgDefault;
                    }
                  : undefined
              }
              alt=""
            />
          )}
          <p
            className="title"
            style={{ marginTop: `${marginTop}px` }}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          {device && (
            <div className="device-specs">
              <p className="device">{device}</p>
              <p className="specs">{specs}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default StatusMessage;
