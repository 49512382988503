import "./our-manifesto.scss";

import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { ButtonBack } from "@greenpanda/ui/button-back";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { AuthContext } from "../../../utils/context";

export function OurManifesto() {
  const { t } = useTranslation();
  const { configuration } = useContext(AuthContext);

  return (
    <div className="our-manifesto-page">
      <NavbarHeader>
        <ButtonBack
          iconType="white-back"
          backButtonType="link"
          to="../more"
          imageLink={configuration.images_link}
        />
        <p className="title">
          {configuration.partner.flow === "pandas"
            ? t("our_manifesto_page.title")
            : t("our_manifesto_page.title_partner")}
        </p>
      </NavbarHeader>
      <div className="our-banner">
        <img
          src={`${configuration.images_link}/images/manifesto-banner.png`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = "/assets/images/manifesto-banner.png";
          }}
          alt=""
        />
        <p
          dangerouslySetInnerHTML={{
            __html:
              configuration.partner.flow === "pandas"
                ? t("our_manifesto_page.header_title")
                : t("our_manifesto_page.header_title_partner"),
          }}
        />
        <img
          className="like-heart"
          src="/assets/images/like-heart-purple.svg"
          alt=""
        />
      </div>
      <div className="container">
        <h1
          className="article-title"
          dangerouslySetInnerHTML={{
            __html: t("our_manifesto_page.article_header"),
          }}
        />
        <p
          className="article-content"
          dangerouslySetInnerHTML={{
            __html: t("our_manifesto_page.article_content"),
          }}
        />
      </div>
    </div>
  );
}

export default OurManifesto;
