import "./store-locations.scss";

import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { Status, Wrapper } from "@googlemaps/react-wrapper";

import { ButtonAction } from "@greenpanda/ui/button-action";
import {
  tradeInActions,
  tradeInAddressSelector,
  tradeInLocationsSelector,
} from "../../data-access/tradein-reducer";
import { LocationState } from "../../data-access/location-state";
import { useTranslation } from "react-i18next";
import { ButtonBack } from "@greenpanda/ui/button-back";
import { GoogleAutocomplete } from "@greenpanda/ui/google-autocomplete";
import TagManager from "react-gtm-module";
import { GOOGLE_API_KEY } from "../../../../constants";
import { AuthContext } from "../../../utils/context";
import { TopTitle } from "@greenpanda/ui/top-title";
import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { goToStartPage } from "../../../utils/go-to-start-page";
import { Loader } from "@greenpanda/ui/loader";
import { getLocations } from "../../data-access/tradein-service";
import { userPosition } from "./utils/user-position";
import { calculateAveragePosition } from "./utils/calculate-average-position";
import { creatMarkerPopup } from "./utils/create-marker-popup";
import { inputAddress } from "./utils/input-address";
import { userAddress } from "./utils/user-addres";

const tagManagerArgs = {
  dataLayer: {
    event: "Step Location Confirmation",
  },
};

export function StoreLocations() {
  TagManager.dataLayer(tagManagerArgs);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const tradeInLocations = useSelector(tradeInLocationsSelector);
  const tradeInAddress = useSelector(tradeInAddressSelector);
  const { configuration, sessionId } = useContext(AuthContext);
  const state = location.state as LocationState | undefined;
  const googleMapsRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [address, setAddress] = useState(state);
  const [geocodeLoading, setGeocodeLoading] = useState(false);
  const [maps, setMaps] = useState<google.maps.Map>();

  useEffect(() => {
    const fetchData = async () => {
      const locations = await getLocations({
        partner: configuration.partner.name,
      });
      dispatch(tradeInActions.setLocations(locations));
    };
    fetchData();

    if (location.state || !maps) return;
    locateUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, configuration, maps, location]);

  useEffect(() => {
    if (!maps || !address) return;
    maps.setCenter({
      lat: address.coordinates.latitude,
      lng: address.coordinates.longitude,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {
    if (!maps || !address) return;

    if (typeof maps !== "undefined" && tradeInLocations.length > 0) {
      creatMarkerPopup(configuration, maps, tradeInLocations);

      maps.setZoom(11);
    }
    const marker = new window.google.maps.Marker({
      map: maps,
      position: {
        lat: address.coordinates.latitude,
        lng: address.coordinates.longitude,
      },
    });

    return () => marker.setMap(null);
  }, [maps, tradeInLocations, tradeInAddress, configuration, address]);

  const handleWrapperCallback = useCallback(
    (status: Status) => {
      if (status === "SUCCESS" && googleMapsRef.current !== null) {
        const map = new window.google.maps.Map(googleMapsRef.current, {
          mapTypeControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          zoomControl: true,
          center: calculateAveragePosition(tradeInLocations),
        });
        setMaps(map);
      }
    },
    [tradeInLocations]
  );

  const locateUser = useCallback(
    async (force = false) => {
      if (!maps || (address?.name && !force)) return;
      if (inputRef.current) inputRef.current.value = "";
      setGeocodeLoading(true);

      try {
        const position = await userPosition();

        const geocoder = new window.google.maps.Geocoder();
        const result = await geocoder.geocode({
          location: {
            lat: position.latitude,
            lng: position.longitude,
          },
          language: configuration.partner.country,
          address: force ? "" : address?.name ?? "",
        });

        const components = result.results?.[0].address_components ?? [];

        setAddress(userAddress(components, position));

        if (inputRef.current)
          inputRef.current.value = userAddress(components, position).name;
      } catch (err) {
        window.console.error(err);
      } finally {
        setGeocodeLoading(false);
      }
    },
    [maps, address?.name, configuration]
  );

  if (!GOOGLE_API_KEY) return <div></div>;
  return (
    <div id="trade-store-locations-page">
      <div id="status-bar">
        <NavbarHeader>
          <ButtonBack
            iconType="back"
            backButtonType="link"
            to="../offer"
            imageLink={configuration.images_link}
          />
        </NavbarHeader>
        <TopTitle
          title={t("store_locations_page.title")}
          description={t("store_locations_page.subtitle")}
        />
        <form>
          <Wrapper apiKey={GOOGLE_API_KEY} libraries={["places"]}>
            <GoogleAutocomplete
              ref={inputRef}
              apiKey={GOOGLE_API_KEY}
              defaultValue={address?.name}
              country={configuration.partner.country}
              placeholder={t("locate_me_page.input.placeholder")}
              onChange={(ev) => {
                if (!ev.currentTarget.value) setAddress(undefined);
              }}
              size="small"
              onPlaceSelected={(place) => {
                setAddress(inputAddress(place));
                navigate("../store-locations");
              }}
              imgSrc="/assets/images/icons/search-icon.svg"
              resetButton={() => {
                if (inputRef.current) inputRef.current.value = "";
              }}
            />
          </Wrapper>
          <div>
            <button
              onClick={() => {
                locateUser(true);
              }}
              type="button"
              className="locate-me"
            />
          </div>
        </form>
      </div>
      <div id="google-map">
        {geocodeLoading ? (
          <Loader />
        ) : (
          <Wrapper
            apiKey={GOOGLE_API_KEY}
            libraries={["places"]}
            render={() => {
              return <div ref={googleMapsRef} />;
            }}
            callback={(status) => handleWrapperCallback(status)}
          >
            <div ref={googleMapsRef} />
          </Wrapper>
        )}
      </div>
      <div id="button-holder">
        <ButtonAction
          primary={true}
          buttonType="button"
          onClick={() => {
            navigate(goToStartPage(configuration, sessionId));
          }}
          disabled={!address || geocodeLoading}
        >
          {t("store_locations_page.return_home")}
        </ButtonAction>
      </div>
    </div>
  );
}

export default StoreLocations;
