import { distinctUntilKeyChanged, map, Observable, switchMap } from "rxjs";
import { screenSize$ } from "./screen-size.observable";

export const createUserMediaObservable = (
  constraints?: MediaStreamConstraints
): Observable<{ stream: MediaStream; height: number; width: number }> => {
  return new Observable<{
    stream: MediaStream;
    height: number;
    width: number;
  }>((subscriber) => {
    let innerStream: MediaStream;
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        innerStream = stream;
        const { height = 0, width = 0 } =
          stream.getVideoTracks().at(0)?.getSettings() ?? {};
        subscriber.next({
          stream,
          height,
          width,
        });
      })
      .catch((error) => {
        subscriber.error(error.message);
      });

    return {
      unsubscribe: () => {
        innerStream?.getTracks().forEach((track) => track.stop());
      },
    };
  });
};

export const createResizableUserMediaStream = (
  constraints?: MediaStreamConstraints
) =>
  screenSize$.pipe(
    map(() => ({
      size: Math.max(window.screen.width, window.screen.height),
    })),
    distinctUntilKeyChanged("size"),
    switchMap(({ size }) =>
      createUserMediaObservable({
        ...constraints,
        video: {
          ...(typeof constraints?.video === "boolean"
            ? {}
            : constraints?.video),
          height: size,
          width: size,
        },
      })
    )
  );
