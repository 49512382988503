import "./resume-evaluation.scss";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { ButtonBack } from "@greenpanda/ui/button-back";
import { ButtonAction } from "@greenpanda/ui/button-action";
import { TopTitle } from "@greenpanda/ui/top-title";

import { lastStepIndexSelector } from "../../../evaluation/data-access/evaluation-reducer";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { AuthContext } from "../../../utils/context";

export function ResumeEvaluation() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { configuration } = useContext(AuthContext);

  const LAST_STEP_INDEX = useSelector(lastStepIndexSelector);
  const STEPS_REMAINING = configuration.assessments.length - LAST_STEP_INDEX;

  function resumeEvaluationHandler() {
    navigate(`../evaluation/${configuration.assessments[LAST_STEP_INDEX]}`);
  }

  return (
    <div className="resume-evaluation-page">
      <div className="container">
        <NavbarHeader>
          <ButtonBack
            iconType="back"
            backButtonType="link"
            to="../home"
            imageLink={configuration.images_link}
          />
        </NavbarHeader>
        <TopTitle
          title={t("resume_page.top_title.title")}
          description={t("resume_page.top_title.description")}
        />
        <div className="evaluation-steps">
          <p className="steps-remaining">{STEPS_REMAINING}</p>
          <p className="message">{t("resume_page.evaluation_steps")}</p>
        </div>
        <div className="button-container">
          <ButtonAction
            primary={true}
            buttonType="button"
            onClick={resumeEvaluationHandler}
          >
            {t("resume_page.resume")}
          </ButtonAction>
          <ButtonAction primary={false} buttonType="link" to="/start">
            {t("resume_page.get_quote")}
          </ButtonAction>
        </div>
      </div>
    </div>
  );
}

export default ResumeEvaluation;
