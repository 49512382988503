import "./google-autocomplete.scss";
import Autocomplete, {
  ReactGoogleAutocompleteInputProps,
} from "react-google-autocomplete";
import { forwardRef } from "react";

export interface GoogleAutocompleteProps
  extends Omit<
    ReactGoogleAutocompleteInputProps,
    "libraries" | "googleMapsScriptBaseUrl"
  > {
  size: "small" | "large";
  imgSrc?: string;
  country: string;
  resetButton?: () => void;
}

export type Props = GoogleAutocompleteProps;

export const GoogleAutocomplete = forwardRef<HTMLInputElement, Props>(
  (
    {
      size = "large",
      imgSrc,
      className,
      country = "gr",
      placeholder,
      resetButton,
      ...props
    }: Props,
    ref
  ) => {
    return (
      <div className="googleAutocomplete-container">
        <Autocomplete
          className={`google-autocomplete ${size} ${className}`}
          {...props}
          placeholder={placeholder}
          options={{
            types: [],
            componentRestrictions: {
              country,
            },
          }}
          ref={ref}
        />
        {imgSrc && <img className="search-icon" src={imgSrc} alt="" />}
        {resetButton && (
          <img
            className="reset-button"
            src="/assets/images/icons/clear.svg"
            alt=""
            onClick={resetButton}
          />
        )}
      </div>
    );
  }
);

export default GoogleAutocomplete;
