import "./contact-us.scss";

import { useContext, useRef, useState } from "react";

import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { ButtonBack } from "@greenpanda/ui/button-back";
import { TopTitle } from "@greenpanda/ui/top-title";
import Input from "../../../../../tradein/ui/input/input";
import { ButtonAction } from "@greenpanda/ui/button-action";
import { useTranslation } from "react-i18next";
import {
  ContactEmailData,
  postEmailContact,
} from "../../../../data-access/onboarding-service";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../../utils/context";

export function ContactUs() {
  const navigate = useNavigate();
  const { configuration } = useContext(AuthContext);

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [formParams, setFormParams] = useState<ContactEmailData>({
    first_name: "",
    last_name: "",
    email: "",
    message: "",
  });

  const { t } = useTranslation();

  const handleFirstName = (firstName: string): void => {
    setFormParams({ ...formParams, first_name: firstName });
  };
  const handleLastName = (lastName: string): void => {
    setFormParams({ ...formParams, last_name: lastName });
  };
  const handleEmail = (email: string): void => {
    setFormParams({ ...formParams, email: email });
  };

  const resetInputValue = function () {
    if (textareaRef.current) {
      textareaRef.current!.value = "";
      setFormParams({ ...formParams, message: "" });
    }
  };

  const handleOnChange = () => {
    if (textareaRef.current)
      setFormParams({ ...formParams, message: textareaRef!.current.value });
  };

  const sendEmail = async (): Promise<void> => {
    const response = await postEmailContact(
      formParams,
      configuration.contact_us_email
    );
    if (response === 200) navigate("../thank-you");
  };

  return (
    <div className="contact-us-page">
      <NavbarHeader>
        <ButtonBack
          iconType="white-back"
          backButtonType="link"
          to="../more"
          imageLink={configuration.images_link}
        />
        <p className="title">{t("contact_us_page.title")}</p>
      </NavbarHeader>
      <div className="container">
        <TopTitle title={t("contact_us_page.top_title")} />
        <div className="input-container">
          <Input
            inputType="text"
            placeholder={t("contact_us_page.form.first_name")}
            label={t("contact_us_page.form.first_name")}
            onChangeCallback={(firstName: string) => handleFirstName(firstName)}
            onResetCallback={() => handleFirstName("")}
          />
          <Input
            inputType="text"
            placeholder={t("contact_us_page.form.last_name")}
            label={t("contact_us_page.form.last_name")}
            onChangeCallback={(lastName: string) => handleLastName(lastName)}
            onResetCallback={() => handleLastName("")}
          />
          <Input
            inputType="email"
            placeholder={t("contact_us_page.form.email")}
            label={t("contact_us_page.form.email")}
            onChangeCallback={(email: string) => handleEmail(email)}
            onResetCallback={() => handleEmail("")}
          />
          <div className="textarea-container">
            <textarea
              ref={textareaRef}
              placeholder={t("contact_us_page.form.your_message")}
              onChange={() => handleOnChange()}
            />
            <label>{t("contact_us_page.form.your_message")}</label>
            <img
              className="textarea-reset"
              src="/assets/images/icons/clear.svg"
              alt=""
              onClick={() => resetInputValue()}
            />
          </div>
        </div>
        <div className="button-container">
          <ButtonAction primary={true} buttonType="button" onClick={sendEmail}>
            {t("contact_us_page.form.send")}
          </ButtonAction>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
