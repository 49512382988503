import "./button-back.scss";

import { ComponentProps } from "react";
import { Link, LinkProps } from "react-router-dom";

export interface BackButtonCommonProps {
  iconType: "close" | "back" | "white-back";
}

export interface BackButtonProps extends ComponentProps<"button"> {
  backButtonType: "button";
}

export interface BackLinkProps extends LinkProps {
  backButtonType: "link";
}

export interface ImageLinkProps {
  imageLink?: string;
}

export type Props = BackButtonCommonProps &
  (BackButtonProps | BackLinkProps) &
  ImageLinkProps;

export function ButtonBack({
  imageLink,
  iconType,
  backButtonType,
  ...restProps
}: Props) {
  const IMG = (
    <img
      src={
        iconType === "close"
          ? `${imageLink}/images/icons/close-icon.svg`
          : iconType === "white-back"
          ? "assets/images/icons/back-white-icon.svg"
          : `${imageLink}/images/icons/back-icon.svg`
      }
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src =
          iconType === "close"
            ? "assets/images/icons/close-icon.svg"
            : iconType === "white-back"
            ? "assets/images/icons/back-white-icon.svg"
            : "assets/images/icons/back-icon.svg";
      }}
      alt={iconType}
    />
  );

  if (backButtonType === "button")
    return (
      <button
        {...(restProps as ComponentProps<"button">)}
        className="back-button"
      >
        {IMG}
      </button>
    );
  else if (backButtonType === "link")
    return (
      <Link {...(restProps as LinkProps)} className="back-button">
        {IMG}
      </Link>
    );
  else return null;
}

export default ButtonBack;
