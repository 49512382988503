import { ResourceLanguage } from "i18next";

export interface ConfigBrowserResponse {
  frontCamera: number;
  backCamera: number;
  digitizer: number;
  audioMeter: number;
}

export interface ConfigResponse {
  ios?: {
    [key: string]:
      | {
          safari?: ConfigBrowserResponse;
          chrome?: ConfigBrowserResponse;
          firefox?: ConfigBrowserResponse;
          opera?: ConfigBrowserResponse;
          default: ConfigBrowserResponse;
        }
      | ConfigBrowserResponse;
    default: ConfigBrowserResponse;
  };
  android?: {
    [key: string]:
      | {
          safari?: ConfigBrowserResponse;
          chrome?: ConfigBrowserResponse;
          firefox?: ConfigBrowserResponse;
          opera?: ConfigBrowserResponse;
          default: ConfigBrowserResponse;
        }
      | ConfigBrowserResponse;
    default: ConfigBrowserResponse;
  };
  default: ConfigBrowserResponse;
}

export interface LanguageResponse {
  name: string;
  slug: string;
  endpoint: string;
}

export type LanguagesResponse = Array<LanguageResponse>;

export async function getConfig(): Promise<ConfigResponse> {
  const response = await fetch(
    `${process.env["NX_AMAZON_AWS_URL"]}/config.json`,
    {
      method: "GET",
    }
  );

  return response.json();
}

export async function getTranslation(
  language: string
): Promise<ResourceLanguage> {
  const response = await fetch(
    `${process.env["NX_AMAZON_AWS_URL"]}/translations/${language}.json`,
    {
      method: "GET",
    }
  );

  return response.json();
}

export async function getLanguages(
  partner: string
): Promise<LanguagesResponse> {
  const response = await fetch(
    `${process.env["NX_AMAZON_AWS_URL"]}/${partner}.json`,
    {
      method: "GET",
    }
  );

  return response.json();
}
