import { ConfigurationResponse } from "../../../../configuration/configuration-response";
import { LocationsResponse } from "../../../data-access/tradein-service";

export const creatMarkerPopup = (
  configuration: ConfigurationResponse,
  maps: google.maps.Map,
  tradeInLocations: Array<LocationsResponse>
) => {
  const infoWindow = new google.maps.InfoWindow();

  tradeInLocations
    .filter(
      (tradeInLocation) =>
        tradeInLocation.type === "atm" || tradeInLocation.type === "app"
    )
    .forEach((tradeInLocation) => {
      const marker = new window.google.maps.Marker({
        map: maps,
        position: {
          lat: tradeInLocation.lat,
          lng: tradeInLocation.lng,
        },
        icon: `${configuration.images_link}/images/icons/kiosk-icon.svg`,
      });
      marker.addListener("click", () => {
        infoWindow.setContent(`<div>
          <p class="title">${tradeInLocation.name}</p>
          <p class="description">${tradeInLocation.partner_name}</p>
          <p class="description">${tradeInLocation.address}</p>
          <a class="link" href=${tradeInLocation.location}>Google Maps</a>
          </div>`);
        infoWindow.open({
          anchor: marker,
          map: maps,
          shouldFocus: true,
        });
      });
    });
};
