import "./whats-next-home.scss";
import WhatsNext from "../../ui/whats-next/whats-next";
import { useTranslation } from "react-i18next";

export function WhatsNextHome() {
  const { t } = useTranslation();

  const HOME_DESCRIPTION = t("whats_next_page.home.description");
  const HOME_FOOTER_MESSAGE = t("whats_next_page.home.footer");
  const HOME_BACK_BUTTON_LINK = "../schedule-pickup";
  const HOME_INSTRUCTION_LIST = [
    {
      imgSrc: "/assets/images/icons/icloud-signout-icon.svg",
      title: t("whats_next_page.icloud.title"),
      description: t("whats_next_page.icloud.description"),
    },
    {
      imgSrc: "/assets/images/icons/enroll-device-icon.svg",
      title: t("whats_next_page.pandas.title"),
      description: t("whats_next_page.icloud.description"),
    },
  ];
  return (
    <WhatsNext
      description={HOME_DESCRIPTION}
      listItems={HOME_INSTRUCTION_LIST}
      footerMessage={HOME_FOOTER_MESSAGE}
      backButtonLink={HOME_BACK_BUTTON_LINK}
    />
  );
}

export default WhatsNextHome;
