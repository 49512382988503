import "./whats-next-atm.scss";
import WhatsNext from "../../ui/whats-next/whats-next";
import { useTranslation } from "react-i18next";

export function WhatsNextAtm() {
  const { t } = useTranslation();

  const ATM_DESCRIPTION = t("whats_next_page.atm.description");
  const ATM_FOOTER_MESSAGE = t("whats_next_page.atm.footer");
  const ATM_BACK_BUTTON_LINK = "../";
  const ATM_INSTRUCTION_LIST = [
    {
      imgSrc: "/assets/images/icons/icloud-signout-icon.svg",
      title: t("whats_next_page.icloud.title"),
      description: t("whats_next_page.icloud.description"),
    },
    {
      imgSrc: "/assets/images/icons/enroll-device-icon.svg",
      title: t("whats_next_page.pandas.title"),
      description: t("whats_next_page.icloud.description"),
    },
  ];

  return (
    <WhatsNext
      description={ATM_DESCRIPTION}
      listItems={ATM_INSTRUCTION_LIST}
      footerMessage={ATM_FOOTER_MESSAGE}
      backButtonLink={ATM_BACK_BUTTON_LINK}
    />
  );
}

export default WhatsNextAtm;
