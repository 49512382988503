import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { evaluationStateSelector } from "../../../evaluation/data-access/evaluation-reducer";
import { AuthContext } from "../../../utils/context";

export function DetailedReport() {
  const { t } = useTranslation();
  const evaluation = useSelector(evaluationStateSelector);
  const { configuration } = useContext(AuthContext);

  const frontCamera = {
    title: "front_camera",
    image: "front-camera",
    passed: Boolean(evaluation.frontCamera.photoURL),
    skipped: evaluation.frontCamera.skipped,
  };
  const backCamera = {
    title: "back_camera",
    image: "back-camera",
    passed: Boolean(evaluation.backCamera.photoURL),
    skipped: evaluation.backCamera.skipped,
  };
  const digitizer = {
    title: "digitizer",
    image: "screen-icon",
    passed: Boolean(evaluation.digitizer.passed),
    skipped: evaluation.digitizer.skipped,
  };
  const soundPerformance = {
    title: "speakers",
    image: "speakers-icon",
    passed: Boolean(evaluation.soundPerformance.passed),
    skipped: evaluation.soundPerformance.skipped,
  };
  const faceId = {
    title: "face_id_touch_id",
    image: "face-id",
    passed: Boolean(evaluation.faceID.passed),
    skipped: evaluation.faceID.skipped,
  };
  const screen = {
    title: "screen",
    image: "cosmetics-icon",
    passed: Boolean(evaluation.screen.passed),
    skipped: evaluation.screen.skipped,
  };
  const bodyFrame = {
    title: "body_frame",
    image: "cosmetics-icon",
    passed: Boolean(evaluation.bodyFrame.passed),
    skipped: evaluation.bodyFrame.skipped,
  };
  const assessments = [];
  assessments[configuration.assessments.indexOf("digitizer")] = digitizer;
  assessments[configuration.assessments.indexOf("sound-performance")] =
    soundPerformance;
  assessments[configuration.assessments.indexOf("face-id")] = faceId;
  assessments[configuration.assessments.indexOf("screen")] = screen;
  assessments[configuration.assessments.indexOf("body-frame")] = bodyFrame;
  assessments[configuration.assessments.indexOf("front-camera")] = frontCamera;
  assessments[configuration.assessments.indexOf("back-camera")] = backCamera;

  return (
    <>
      <p className="detailed-report">{t("offer_page.detailed_report")}</p>
      <div className="detailed-report-boxes">
        {assessments.map((element, index) => {
          return (
            <div key={index} className="row">
              <img
                src={`assets/images/report-icons/${element.image}.svg`}
                alt={t(`offer_page.evaluation_list.${element.title}`)}
              />
              <div
                className={`box-right ${
                  element.passed ? "works-ok" : "evaluation-failed"
                }`}
              >
                <p className="top">
                  {t(`offer_page.evaluation_list.${element.title}`)}
                </p>
                <p className="bottom">
                  {element.passed
                    ? t("common.works_ok")
                    : element.skipped
                    ? t("common.evaluation_skiped")
                    : t("common.evaluation_failed")}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default DetailedReport;
