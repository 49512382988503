import objectDetectedPercentage, {
  ObjectDetectedPercentageResponse,
} from "../../evaluation/utils/object-detected-percentage";
import { FacingModeType } from "../object-detection";
import getPercentage from "../../utils/get-percentage";
import { ConfigResponse } from "../../data-access/app-service";
import { WURFL } from "../../../constants";

export default function objectDetectedPercentageGuard(
  {
    video,
    bbox,
    facingMode,
    appConfig,
  }: {
    video: { height: number; width: number };
    bbox: [number, number, number, number];
    facingMode: FacingModeType;
    appConfig: ConfigResponse | null;
  },
  notify?: (status: string) => void
) {
  const response: ObjectDetectedPercentageResponse = objectDetectedPercentage(
    [bbox[2], bbox[3]],
    [video.width, video.height]
  );
  const percentage = getPercentage(WURFL, appConfig);
  const frontOrBackCameraPercentage: number =
    facingMode === "user" ? percentage.frontCamera : percentage.backCamera;

  if (
    response.widthPercentage >= frontOrBackCameraPercentage ||
    response.heightPercentage >= frontOrBackCameraPercentage
  )
    return true;

  notify?.("object_detected_percentage_guard");

  return false;
}
