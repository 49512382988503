import "./thank-you-store.scss";

import { useTranslation } from "react-i18next";
import { TopTitle } from "@greenpanda/ui/top-title";
import { ListItem } from "@greenpanda/ui/list-item";
import { ButtonAction } from "@greenpanda/ui/button-action";
import TagManager from "react-gtm-module";
import { useContext } from "react";
import { AuthContext } from "../../../utils/context";

const tagManagerArgs = {
  dataLayer: {
    event: "Step Thank You",
  },
};

export function ThankYouStore() {
  TagManager.dataLayer(tagManagerArgs);

  const { t } = useTranslation();
  const { configuration } = useContext(AuthContext);

  return (
    <div className="thank-you-store-page">
      <div className="container">
        <div className="header">
          <div style={{ textAlign: "center" }}>
            <img
              className="banner"
              src={`${configuration.images_link}/images/thank-you-store.png`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = "/assets/images/thank-you-store.png";
              }}
              alt="All set"
            />
          </div>
          <TopTitle
            title={t("thank_you_store_page.title")}
            description={t("thank_you_store_page.description_orange")}
          />
          <div className="list-container">
            <ListItem
              key={`list-item-1`}
              imgSrc="/assets/images/icons/battery-high-icon.svg"
              title={t("all_set_page.instructions.battery.title")}
              description={t("all_set_page.instructions.battery.description")}
            />
          </div>
        </div>
        <div className="button-container">
          <ButtonAction primary={true} buttonType="link" to="../../../start">
            {t("thank_you_store_page.return_home")}
          </ButtonAction>
        </div>
      </div>
    </div>
  );
}

export default ThankYouStore;
