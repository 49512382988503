import {
  animationFrameScheduler,
  repeat,
  timer,
  pipe,
  defer,
  OperatorFunction,
  exhaustMap,
} from "rxjs";
import { queueFor } from "../../utils/queue";
import { cover } from "intrinsic-scale";
import { getScreenSize } from "../observables/screen-size.observable";

export const grabVideoFrame = <
  T extends {
    stream: MediaStream;
    height: number;
    width: number;
  }
>(): OperatorFunction<T, T & { image: ImageData }> => {
  const canvas = document.createElement("canvas") as HTMLCanvasElement;
  const ctx = canvas.getContext("2d", {
    willReadFrequently: true,
  })!;
  return pipe(
    exhaustMap((streamData) =>
      defer(async () => {
        const { stream, height, width } = streamData;
        const track = stream.getVideoTracks().at(0);
        const imageCapture = new ImageCapture(track!);
        const frame = await imageCapture.grabFrame();
        const screenSize = getScreenSize();
        const {
          height: offsetHeight,
          width: offsetWidth,
          x,
          y,
        } = cover(
          screenSize.screenWidth,
          screenSize.screenHeight,
          width,
          height
        );
        canvas.width = screenSize.screenWidth;
        canvas.height = screenSize.screenHeight;
        ctx.drawImage(frame, x, y, offsetWidth, offsetHeight);
        await queueFor();
        const image: ImageData = ctx.getImageData(
          0,
          0,
          screenSize.screenWidth,
          screenSize.screenHeight
        )!;
        return { ...streamData, image };
      }).pipe(
        repeat({
          delay: () => timer(1000 / 60, animationFrameScheduler),
        })
      )
    )
  );
};
