import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../utils/context";
import { goToStartPage } from "../../../utils/go-to-start-page";
import {
  tradeInActions,
  tradeInCourierSelector,
} from "../../data-access/tradein-reducer";

export function CourierCheckboxes() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { configuration, sessionId } = useContext(AuthContext);
  const tradeInCourier = useSelector(tradeInCourierSelector);

  const [checkboxData, setCheckboxData] = useState({
    checkboxes: [
      {
        id: 1,
        label: (
          <div>
            <span>{t("courier_page.terms_conditions")}</span>
            <Link
              to={`${goToStartPage(
                configuration,
                sessionId
              )}/terms-of-services`}
            >
              {t("courier_page.terms_conditions_link")}
            </Link>
          </div>
        ),
        checked: false,
      },
      {
        id: 2,
        label: (
          <div>
            <span>{t("courier_page.privacy_policy")}</span>
            <Link
              to={`${goToStartPage(configuration, sessionId)}/privacy-policy`}
            >
              {t("courier_page.privacy_policy_link")}
            </Link>
          </div>
        ),
        checked: false,
      },
      {
        id: 3,
        label: (
          <div>
            <span>{t("courier_page.device_ownership")}</span>
            <Link
              to={`${goToStartPage(configuration, sessionId)}/device-ownership`}
            >
              {t("courier_page.device_ownership_link")}
            </Link>
          </div>
        ),
        checked: false,
      },
    ],
  });

  const { checkboxes } = checkboxData;

  const updateState = (id: number) => {
    const updatedItems = [...checkboxData.checkboxes];
    const itemId = updatedItems.findIndex((obj) => obj.id === id);
    updatedItems[itemId].checked = !updatedItems[itemId].checked;
    setCheckboxData({
      ...checkboxData,
      checkboxes: updatedItems,
    });
    const checkbox = checkboxData.checkboxes.filter(
      (obj) => obj.checked === true
    );
    if (checkbox.length > 2) {
      const formParams = { ...tradeInCourier, checked: true };
      dispatch(tradeInActions.setCourier(formParams));
    } else {
      const formParams = { ...tradeInCourier, checked: false };
      dispatch(tradeInActions.setCourier(formParams));
    }
  };

  return (
    <>
      {checkboxes.map((item, index) => (
        <div key={index} className="checkbox-container">
          <div>
            <input
              onChange={() => updateState(item.id)}
              type="checkbox"
              id="check"
              checked={item.checked}
            />
            <div onClick={() => updateState(item.id)} id="checkmark"></div>
            <label htmlFor="checks">{item.label}</label>
          </div>
        </div>
      ))}
    </>
  );
}

export default CourierCheckboxes;
