import "./atm-dropoff.scss";

import { ButtonBack } from "@greenpanda/ui/button-back";
import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { TopTitle } from "@greenpanda/ui/top-title";
import { ButtonAction } from "@greenpanda/ui/button-action";

import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "@greenpanda/ui/modal";
import { ConfirmMessage } from "@greenpanda/ui/confirm-message";
import { AuthContext } from "../../../utils/context";

export function ATMDropoff() {
  const { t } = useTranslation();
  const { configuration } = useContext(AuthContext);

  const [exitModalOpen, setExitModalOpen] = useState(false);

  const toggleExitModalHandler = () => {
    setExitModalOpen(!exitModalOpen);
  };

  return (
    <div className="atm-dropoff-page">
      <div className="container">
        {exitModalOpen && (
          <Modal
            buttonPrimaryContent={t("atm_dropoff_page.modal.resume_tradein")}
            buttonLinkContent={t("atm_dropoff_page.modal.go_back")}
            buttonPrimaryHandler={toggleExitModalHandler}
            buttonLinkType="link"
            buttonPrimaryType="button"
            buttonLinkLink="../../start"
          >
            <ConfirmMessage
              emoji="🧐"
              title={t("atm_dropoff_page.modal.title")}
            />
          </Modal>
        )}
        <NavbarHeader>
          <ButtonBack
            iconType="back"
            backButtonType="link"
            to="../trade-option"
            imageLink={configuration.images_link}
          />
        </NavbarHeader>
        <img className="banner" src="/assets/images/atm-dropoff.png" alt="" />
        <TopTitle
          title={t("atm_dropoff_page.top_title.title")}
          description={t("atm_dropoff_page.top_title.description")}
        />
        <div className="button-container">
          <ButtonAction primary={true} buttonType="link" to="../pandas-kiosks">
            {t("atm_dropoff_page.view_pandas")}
          </ButtonAction>
          <ButtonAction
            primary={false}
            outline={false}
            buttonType="button"
            onClick={toggleExitModalHandler}
          >
            {t("atm_dropoff_page.return_home")}
          </ButtonAction>
        </div>
      </div>
    </div>
  );
}

export default ATMDropoff;
