import "./device-condition.scss";

import { ButtonAction } from "@greenpanda/ui/button-action";
import { TopTitle } from "@greenpanda/ui/top-title";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
export interface DeviceConditionProps {
  title: string;
  perfectButtonOnClick: React.MouseEventHandler<HTMLButtonElement>;
  damagedButtonOnClick: React.MouseEventHandler<HTMLButtonElement>;
  damageButtonDescription: string;
  perfectButtonDescription: string;
  checked: boolean | undefined;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled: boolean;
  tryAgain?: boolean;
}

export function DeviceCondition({
  title,
  perfectButtonOnClick,
  damagedButtonOnClick,
  perfectButtonDescription,
  damageButtonDescription,
  checked,
  onClick,
  disabled,
  tryAgain,
}: DeviceConditionProps) {
  const { t } = useTranslation();

  return (
    <div className="feature-page device-condition">
      <div className="container">
        <TopTitle
          title={title}
          description="Please select from the options below, based on the evaluation performed."
        />
        <div className="radio-container">
          <button
            className={`radio ${checked === false && "checked"}`}
            onClick={perfectButtonOnClick}
          >
            <img src="/assets/images/perfect.png" alt="perfect-icon" />
            <div style={{ textAlign: "start", padding: "0 20px" }}>
              <h2>{t("device_condition.perfect")}</h2>
              <p>{perfectButtonDescription}</p>
            </div>
            {checked === false ? (
              <img
                className="radio-icon"
                src="/assets/images/icons/checked-icon.svg"
                alt="checked-icon"
              />
            ) : (
              <img
                className="radio-icon"
                src="/assets/images/icons/unchecked-icon.svg"
                alt="unchecked-icon"
              />
            )}
          </button>
          <button
            className={`radio ${checked && "checked"}`}
            onClick={damagedButtonOnClick}
          >
            <img src="/assets/images/damaged.png" alt="damaged-icon" />
            <div style={{ textAlign: "start", padding: "0 20px" }}>
              <h2>{t("device_condition.damaged")}</h2>
              <p>{damageButtonDescription}</p>
            </div>
            {checked ? (
              <img
                className="radio-icon"
                src="/assets/images/icons/checked-icon.svg"
                alt="checked-icon"
              />
            ) : (
              <img
                className="radio-icon"
                src="/assets/images/icons/unchecked-icon.svg"
                alt="unchecked-icon"
              />
            )}
          </button>
        </div>
        <div className="button-container">
          <ButtonAction
            primary={true}
            onClick={onClick}
            buttonType="button"
            disabled={disabled}
          >
            {t("device_condition.continue")}
          </ButtonAction>
          {tryAgain && (
            <Link to="../evaluate-yellowings" className="try-again">
              {t("device_condition.try_again")}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default DeviceCondition;
