import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { format } from "date-fns";
import { LocationState } from "./location-state";

import { LocationsResponse } from "./tradein-service";

export interface SchedulePickup {
  date: string;
  time: string;
  fullName: string;
  bellNameFloor: string;
  phoneNumber: string;
}

export interface Courier {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  address: string;
  postalCode: string;
  checked: boolean;
  date: string;
  time: string;
  imei: number;
}

export interface CashVoucher {
  cash: number;
  voucher: number;
}

export interface DeviceInfo {
  brand: string;
  model: string;
  color: string;
  capacity: string;
}
export type LocalPriceType = {
  currency: string;
  price: number;
};
export interface Offer {
  brand: string;
  model: string;
  name: string;
  capacity: string;
  condition?: string;
  color?: string;
  productId: string;
  price: number;
  localPrices: Array<LocalPriceType>;
  promo: number;
}

export interface TradeInState {
  offer: Offer | null;
  address: LocationState;
  locations: Array<LocationsResponse>;
  scooterPostcodes: Array<string>;
  schedulePickup: SchedulePickup;
  courier: Courier;
  cashVoucher: CashVoucher;
  max_capacity_price: number;
  localPrice: number;
  device_info: DeviceInfo | null;
}

const initialState: TradeInState = {
  offer: null,
  cashVoucher: {
    cash: 0,
    voucher: 0,
  },
  address: {
    name: "",
    coordinates: {
      latitude: 0,
      longitude: 0,
    },
    postalCode: "",
    city: "",
    country: "",
  },
  locations: [],
  scooterPostcodes: [],
  schedulePickup: {
    date: format(new Date(), "yyyy-MM-dd"),
    time: "",
    fullName: "",
    bellNameFloor: "",
    phoneNumber: "",
  },
  courier: {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: "",
    postalCode: "",
    checked: false,
    date: format(new Date(), "yyyy-MM-dd"),
    time: "11:00-15:00",
    imei: 0,
  },
  max_capacity_price: 0,
  localPrice: 0,
  device_info: null,
};

const tradeInSlice = createSlice({
  name: "tradeIn",
  initialState: initialState,
  reducers: {
    setMaxCapacityPrice(
      state: TradeInState,
      { payload }: PayloadAction<number>
    ) {
      state.max_capacity_price = payload;
    },
    setOffer(state: TradeInState, { payload }: PayloadAction<Offer>) {
      state.offer = payload;
    },
    setCashVoucher(
      state: TradeInState,
      { payload }: PayloadAction<CashVoucher>
    ) {
      state.cashVoucher = payload;
    },
    setLocalPrice(state: TradeInState, { payload }: PayloadAction<number>) {
      state.localPrice = payload;
    },
    setAddress(state: TradeInState, { payload }: PayloadAction<LocationState>) {
      state.address = payload;
    },
    setLocations(
      state: TradeInState,
      { payload }: PayloadAction<Array<LocationsResponse>>
    ) {
      state.locations = payload;
    },
    setScooterPostcodes(
      state: TradeInState,
      { payload }: PayloadAction<Array<string>>
    ) {
      state.scooterPostcodes = payload;
    },
    setSchedulePickup(
      state: TradeInState,
      { payload }: PayloadAction<SchedulePickup>
    ) {
      state.schedulePickup = payload;
    },
    setCourier(state: TradeInState, { payload }: PayloadAction<Courier>) {
      state.courier = payload;
    },
    setDeviceInfo(state: TradeInState, { payload }: PayloadAction<DeviceInfo>) {
      state.device_info = payload;
    },
  },
});

const tradeInSelector = (state: { tradeIn: TradeInState }) => state.tradeIn;

export const tradeInMaxCapacityPriceSelector = createSelector(
  tradeInSelector,
  (state) => state.max_capacity_price
);

export const tradeInOfferSelector = createSelector(
  tradeInSelector,
  (state) => state.offer
);

export const cashVoucherSelector = createSelector(
  tradeInSelector,
  (state) => state.cashVoucher
);

export const localPriceSelector = createSelector(
  tradeInSelector,
  (state) => state.localPrice
);

export const tradeInAddressSelector = createSelector(
  tradeInSelector,
  (state) => state.address
);

export const tradeInLocationsSelector = createSelector(
  tradeInSelector,
  (state) => state.locations
);

export const tradeInScooterPostcodesSelector = createSelector(
  tradeInSelector,
  (state) => state.scooterPostcodes
);

export const tradeInSchedulePickupSelector = createSelector(
  tradeInSelector,
  (state) => state.schedulePickup
);

export const tradeInCourierSelector = createSelector(
  tradeInSelector,
  (state) => state.courier
);

export const deviceInfoSelector = createSelector(
  tradeInSelector,
  (state) => state.device_info
);

export const tradeInActions = tradeInSlice.actions;

export default tradeInSlice.reducer;
