import "./homepage.scss";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useState } from "react";

import { lastStepSelector } from "../../../evaluation/data-access/evaluation-reducer";

export function Homepage() {
  const lastStep = useSelector(lastStepSelector);
  const navigate = useNavigate();

  const [startRotate, setStartRotate] = useState(false);

  const startResumeHandler = function () {
    setStartRotate(true);

    setTimeout(() => {
      setStartRotate(false);
      if (lastStep === 0) navigate("/what-you-need");
      else navigate("/resume-evaluation");
    }, 400);
  };

  return (
    <div className="homepage">
      <div className="banner">
        <img src="/assets/images/homepage-banner.png" alt="homepage banner" />
      </div>
      <p className="title">
        Trade in.
        <br />
        Save money.
        <br />
        Do good.
      </p>
      <div className="start-circle">
        <div className="outer" onClick={startResumeHandler}>
          <img
            src="/assets/images/icons/circle.svg"
            className={"circle-svg" + (startRotate ? " rotate" : "")}
            alt=""
          />
          <div className="inner">
            <span>{lastStep === 0 ? "Start" : "Resume"}</span>
          </div>
        </div>
      </div>
      <div className="button-container">
        <p className="button-secondary">
          Get a trade in quote for your smartphone
        </p>
      </div>
    </div>
  );
}

export default Homepage;
