import "./test-sound-performance.scss";

import { useEffect, useState, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { ButtonBack } from "@greenpanda/ui/button-back";
import { Modal } from "@greenpanda/ui/modal";
import { ConfirmMessage } from "@greenpanda/ui/confirm-message";
import Instructions from "../../ui/instructions/instructions";
import { ButtonAction } from "@greenpanda/ui/button-action";
import {
  evaluationActions,
  lastStepIndexSelector,
} from "../../data-access/evaluation-reducer";
import { UiModalMessage } from "@greenpanda/ui/modal-message";
import PrivacyMessage from "../../ui/privacy-message/privacy-message";
import avg from "../../utils/arithmetic-mean-from-array";
import { nextStep } from "../../../utils/next-step";
import TagManager from "react-gtm-module";
import { AuthContext } from "../../../utils/context";

const tagManagerArgs = {
  dataLayer: {
    event: "Step Evaluation Sound",
  },
};
export interface ContentState {
  img: {
    src: string;
    srcDefault: string;
    alt: string;
  };
  instructions: {
    title: string;
    description: string;
  };
}

const messageEncoded =
  "GREENPANDA2022PANDAS-GREENPANDA2022PANDAS-GREENPANDA2022PANDAS-GREENPANDA2022PANDAS-GREENPANDA2022PANDAS-GREENPANDA2022PANDAS";
let ggwaveFactory: null | unknown = null;
let ggwaveInstance: null | unknown = null;
let audioBufferSourceNode: null | AudioBufferSourceNode = null;
let audioContext: null | AudioContext = null;
let mediaStream: null | MediaStream = null;
let firstTimeout: null | NodeJS.Timeout = null;
let secondTimeout: null | NodeJS.Timeout = null;
const retries = 0;

const volume: Array<number> = [];

export function TestSoundPerformance() {
  TagManager.dataLayer(tagManagerArgs);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { configuration } = useContext(AuthContext);
  const LAST_INDEX = useSelector(lastStepIndexSelector);

  const [skipModalOpen, setSkipModalOpen] = useState(false);
  const [alreadySetVolume, setAlreadySetVolume] = useState(false);
  const [content, setContent] = useState<ContentState>({
    img: {
      src: `${configuration.images_link}/images/volume-up-button.svg`,
      srcDefault: "/assets/images/volume-up-button.svg",
      alt: t("test_sound_performance_page.content_volume_up.alt_img"),
    },
    instructions: {
      title: t("test_sound_performance_page.content_volume_up.title"),
      description: t(
        "test_sound_performance_page.content_volume_up.description"
      ),
    },
  });
  const [showRetryModal, setShowRetryModal] = useState<boolean>(false);
  const [showIsNotFunctionalModal, setShowIsNotFunctionalModal] =
    useState<boolean>(false);
  const [permissionsAllowed, setPermissionsAllowed] = useState<boolean>(false);

  const stopAudioTracks = useCallback(() => {
    try {
      if (mediaStream !== null) {
        const audioTracks: Array<MediaStreamTrack> =
          mediaStream.getAudioTracks();
        for (let i = 0; i < audioTracks.length; i++) audioTracks[i].stop();
      }
      audioBufferSourceNode?.stop();
    } catch (error) {
      window.console.error(error);
    }
  }, []);

  const ggwaveEncode = useCallback((): void => {
    audioContext = new AudioContext();

    // @ts-ignore
    const waveform = ggwaveFactory.encode(
      // @ts-ignore
      ggwaveInstance,
      messageEncoded,
      // @ts-ignore
      ggwaveFactory.ProtocolId.GGWAVE_PROTOCOL_AUDIBLE_FAST,
      10
    );
    // @ts-ignore
    const buf = convertTypedArray(waveform, Float32Array);
    const buffer = audioContext.createBuffer(
      1,
      buf.length,
      audioContext.sampleRate
    );

    buffer.getChannelData(0).set(buf);
    audioBufferSourceNode = audioContext.createBufferSource();
    audioBufferSourceNode.buffer = buffer;

    // gain control
    const gainNode = audioContext.createGain();
    audioBufferSourceNode.connect(gainNode);
    gainNode.connect(audioContext.destination);
    gainNode.gain.setValueAtTime(
      window.WURFL.advertised_device_os === "Android" ? 1 : 30,
      audioContext.currentTime
    );
  }, []);

  const ggwaveDecode = useCallback((): void => {
    const bufferSize = 16 * 1024;
    const numberOfInputChannels = 1;
    const numberOfOutputChannels = 1;
    let recorder;

    if (audioContext !== null && mediaStream !== null) {
      if (audioContext.createScriptProcessor)
        recorder = audioContext.createScriptProcessor(
          bufferSize,
          numberOfInputChannels,
          numberOfOutputChannels
        );
      else
        recorder = audioContext["createJavaScriptNode"](
          bufferSize,
          numberOfInputChannels,
          numberOfOutputChannels
        );

      // START AUDIO METER
      const analyzer = audioContext.createAnalyser();
      analyzer.fftSize = 512;
      analyzer.smoothingTimeConstant = 0.1;
      const audioMeterStreamSource =
        audioContext.createMediaStreamSource(mediaStream);
      audioMeterStreamSource.connect(analyzer);
      // END AUDIO METER

      // @ts-ignore
      recorder.onaudioprocess = async function (o) {
        // START AUDIO METER
        const frequencyRangeData = new Uint8Array(analyzer.frequencyBinCount);
        analyzer.getByteFrequencyData(frequencyRangeData);
        const sum = frequencyRangeData.reduce((p, c) => p + c, 0);
        const audioMeter = Math.sqrt(sum / frequencyRangeData.length);
        volume.push(audioMeter);
        const maxVolume = Math.max(...volume);
        const normalized = volume.map((v) => (v / maxVolume) * 100);
        const SPLIT_CONDITION = Math.round(volume.length / 2);
        const volumeUp = normalized.filter(
          // eslint-disable-next-line @typescript-eslint/naming-convention
          (_, index) => index < SPLIT_CONDITION
        );
        const volumeDown = normalized.filter(
          // eslint-disable-next-line @typescript-eslint/naming-convention
          (_, index) => index >= SPLIT_CONDITION
        );
        const percentage = Math.min(
          (avg(volumeDown) / avg(volumeUp)) * 100,
          100
        );

        firstTimeout = setTimeout(() => {
          setContent({
            img: {
              src: `${configuration.images_link}/images/volume-down-button.svg`,
              srcDefault: "/assets/images/volume-down-button.svg",
              alt: t("test_sound_performance_page.content_volume_down.alt_img"),
            },
            instructions: {
              title: t("test_sound_performance_page.content_volume_down.title"),
              description: t(
                "test_sound_performance_page.content_volume_down.description"
              ),
            },
          });
        }, 3000);

        const source2 = o.inputBuffer;
        const convertedTypedArray = convertTypedArray(
          new Float32Array(source2.getChannelData(0)),
          Int8Array
        );

        // @ts-ignore
        let res = ggwaveFactory.decode(ggwaveFactory, convertedTypedArray);
        if (res && res.length > 0) {
          window.console.log("can decode", res.length, percentage);
          res = new TextDecoder("utf-8").decode(res);
          if (res.includes(messageEncoded.substring(11, 19))) {
            audioContext?.close();

            if (firstTimeout !== null) clearTimeout(firstTimeout);

            if (secondTimeout !== null) clearTimeout(secondTimeout);

            dispatch(
              evaluationActions.setCurrentStepIndex(
                configuration.assessments.indexOf("sound-performance") + 1
              )
            );
            dispatch(evaluationActions.setSoundPerformancePassed(true));

            navigate("../sound-performance-success");
          }
        }
      };

      const mediaStreamSource =
        audioContext.createMediaStreamSource(mediaStream);
      mediaStreamSource.connect(recorder);
      recorder.connect(audioContext.destination);

      if (secondTimeout === null)
        secondTimeout = setTimeout(() => {
          if (retries <= 2) {
            audioContext?.close();
            setShowRetryModal(true);
          } else {
            audioContext?.close();
            setShowIsNotFunctionalModal(true);
          }
        }, 10000);
    }
  }, [t, dispatch, navigate, configuration]);

  const initGgwave = useCallback((): void => {
    // @ts-ignore
    ggwave_factory()
      // @ts-ignore
      .then((ggwave) => {
        // @ts-ignore
        ggwaveFactory = ggwave;
        // @ts-ignore
        ggwaveInstance = ggwave.init(ggwave.getDefaultParameters());
        ggwaveEncode();
      })
      .catch((error: unknown) => error);
  }, [ggwaveEncode]);

  useEffect(() => {
    initGgwave();

    return () => {
      if (firstTimeout !== null) clearTimeout(firstTimeout);

      if (secondTimeout !== null) clearTimeout(secondTimeout);

      stopAudioTracks();
    };
  }, [initGgwave, stopAudioTracks]);

  const toggleSkipModalHandler = (): void => {
    setSkipModalOpen(!skipModalOpen);
  };

  const skipSoundPerformanceHandler = (): void => {
    dispatch(evaluationActions.setSoundPerformancePassed(false));
    navigate(nextStep(configuration, LAST_INDEX + 1));
  };

  const handleAllowAccess = async (): Promise<void> => {
    try {
      mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: {
          suppressLocalAudioPlayback: false,
          noiseSuppression: false,
          echoCancellation: false,
          autoGainControl: false,
        },
      });

      setPermissionsAllowed(true);
    } catch (error) {
      alert(error);
    }
  };

  // @ts-ignore
  const convertTypedArray = (src, type) => {
    const buffer = new ArrayBuffer(src.byteLength);
    new src.constructor(buffer).set(src);

    return new type(buffer);
  };

  const startAudio = (): void => {
    ggwaveDecode();
    setAlreadySetVolume(true);
    audioBufferSourceNode?.start();
  };

  if (permissionsAllowed === false)
    return (
      <div className="allow-microphone-page">
        <div className="container">
          <NavbarHeader>
            <ButtonBack
              backButtonType="link"
              to="../sound-performance"
              iconType="back"
              imageLink={configuration.images_link}
            />
          </NavbarHeader>
          <ConfirmMessage
            title={t("allow_microphone_page.title")}
            description={t("allow_microphone_page.description")}
          >
            <img
              src={`${configuration.images_link}/images/microphone.svg`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = "/assets/images/microphone.svg";
              }}
              alt="allow camera"
            />
          </ConfirmMessage>
          <div className="button-container">
            <ButtonAction
              primary={true}
              buttonType="button"
              onClick={() => handleAllowAccess()}
            >
              {t("common.allow_access")}
            </ButtonAction>
          </div>
          <div className="privacy-container">
            <PrivacyMessage
              message={t("allow_microphone_page.privacy_message")}
            />
          </div>
        </div>
      </div>
    );

  return (
    <div className="test-sound-page">
      <div className="container">
        {skipModalOpen && (
          <Modal
            buttonPrimaryContent={t(
              "test_sound_performance_page.skip_modal.button"
            )}
            buttonLinkContent={t("common.skip_step")}
            buttonPrimaryHandler={() => toggleSkipModalHandler()}
            buttonLinkType="button"
            buttonPrimaryType="button"
            buttonLinkHandler={() => skipSoundPerformanceHandler()}
          >
            <ConfirmMessage
              emoji="😔"
              title={t("test_sound_performance_page.skip_modal.title")}
              description={t(
                "test_sound_performance_page.skip_modal.description"
              )}
            />
          </Modal>
        )}
        {showRetryModal === true && (
          <Modal
            buttonPrimaryContent={t(
              "test_sound_performance_page.retry_modal.primary_button"
            )}
            buttonLinkContent={t(
              "test_sound_performance_page.retry_modal.link_button"
            )}
            buttonPrimaryHandler={() => window.location.reload()}
            buttonLinkType="button"
            buttonPrimaryType="button"
            buttonLinkHandler={() => skipSoundPerformanceHandler()}
            secondButtonIsPrimary={false}
            secondButtonIsOutline={true}
          >
            <UiModalMessage
              icon="assets/images/pop-up-speakers-fail-icon.png"
              title={t("test_sound_performance_page.retry_modal.title")}
              description={t(
                "test_sound_performance_page.retry_modal.description"
              )}
              items={[
                {
                  icon: "assets/images/icons/speaker-high-icon.svg",
                  title: t(
                    "test_sound_performance_page.retry_modal.items_list.item_1"
                  ),
                },
                {
                  icon: "assets/images/icons/increase-volume-icon.svg",
                  title: t(
                    "test_sound_performance_page.retry_modal.items_list.item_2"
                  ),
                },
                {
                  icon: "assets/images/icons/no-headphones-icon.svg",
                  title: t(
                    "test_sound_performance_page.retry_modal.items_list.item_3"
                  ),
                },
                {
                  icon: "assets/images/icons/turn-down-loud-sounds-icon.svg",
                  title: t(
                    "test_sound_performance_page.retry_modal.items_list.item_4"
                  ),
                },
              ]}
            />
          </Modal>
        )}
        {showIsNotFunctionalModal === true && (
          <Modal
            buttonPrimaryContent={t("common.continue")}
            buttonLinkContent={t("common.try_again")}
            buttonPrimaryHandler={() => skipSoundPerformanceHandler()}
            buttonLinkType="button"
            buttonPrimaryType="button"
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            buttonLinkHandler={() => {}}
            secondButtonIsPrimary={false}
            secondButtonIsOutline={true}
          >
            <UiModalMessage
              icon="assets/images/pop-up-speakers-fail-icon.png"
              title={t("test_sound_performance_page.not_working_modal.title")}
            />
          </Modal>
        )}
        <NavbarHeader>
          <ButtonBack
            iconType="close"
            onClick={() => toggleSkipModalHandler()}
            backButtonType="button"
            imageLink={configuration.images_link}
          />
        </NavbarHeader>
        <div className="initial">
          <Instructions
            title={content.instructions.title}
            description={content.instructions.description}
          />
          <div className="instruction-icon-container">
            <img
              src={content.img.src}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = content.img.srcDefault;
              }}
              alt={content.img.alt}
            />
          </div>
          <div className="button-container">
            {alreadySetVolume === false && (
              <ButtonAction
                primary={true}
                buttonType="button"
                onClick={() => startAudio()}
              >
                {t("test_sound_performance_page.already_button")}
              </ButtonAction>
            )}
          </div>
        </div>
      </div>
      <div className="audio-container"></div>
    </div>
  );
}

export default TestSoundPerformance;
