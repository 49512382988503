import "./find-atm.scss";

import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { ButtonBack } from "@greenpanda/ui/button-back";
import { useContext } from "react";
import { AuthContext } from "../../../utils/context";

export function FindATM() {
  const { configuration } = useContext(AuthContext);
  return (
    <div className="find-atm-page">
      <NavbarHeader>
        <ButtonBack
          iconType="back"
          backButtonType="link"
          to="../atm-dropoff"
          imageLink={configuration.images_link}
        />
        <p className="title">Pandas ATMs</p>
        <img
          className="locate-icon"
          src={`${configuration.images_link}/images/icons/locate-icon.svg`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = "/assets/images/icons/locate-icon.svg";
          }}
          alt=""
        />
      </NavbarHeader>
      <div className="map-container">Map</div>
    </div>
  );
}

export default FindATM;
