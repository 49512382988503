import "./whats-next-shell.scss";

import { Routes, Route } from "react-router-dom";

import WhatsNextHome from "../whats-next-home/whats-next-home";
import WhatsNextAtm from "../whats-next-atm/whats-next-atm";
import WhatsNextCourier from "../whats-next-courier/whats-next-courier";

export function WhatsNextShell() {
  return (
    <Routes>
      <Route path="/home" element={<WhatsNextHome />} />
      <Route path="/atm" element={<WhatsNextAtm />} />
      <Route path="/courier" element={<WhatsNextCourier />} />
    </Routes>
  );
}

export default WhatsNextShell;
