import "./locate-me.scss";
import { useNavigate } from "react-router";
import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { ButtonBack } from "@greenpanda/ui/button-back";
import { TopTitle } from "@greenpanda/ui/top-title";
import { ButtonAction } from "@greenpanda/ui/button-action";
import { GoogleAutocomplete } from "@greenpanda/ui/google-autocomplete";
import { useTranslation } from "react-i18next";
import { Wrapper } from "@googlemaps/react-wrapper";
import { LocationState } from "../../data-access/location-state";
import TagManager from "react-gtm-module";
import { GOOGLE_API_KEY } from "../../../../constants";
import { useContext } from "react";
import { AuthContext } from "../../../utils/context";

const tagManagerArgs = {
  dataLayer: {
    event: "Step Location Form",
  },
};

export function LocateMe() {
  TagManager.dataLayer(tagManagerArgs);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { configuration } = useContext(AuthContext);

  if (!GOOGLE_API_KEY) return <div></div>;
  else
    return (
      <div className="locate-me">
        <div className="container">
          <NavbarHeader>
            <ButtonBack
              iconType="back"
              backButtonType="link"
              to="../offer"
              imageLink={configuration.images_link}
            />
          </NavbarHeader>
          <img
            className="banner"
            src={`${configuration.images_link}/images/offer-accept.png`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = "/assets/images/offer-accept.png";
            }}
            alt=""
          />
          <TopTitle
            title={t("locate_me_page.top_title.title")}
            description={t("locate_me_page.top_title.description")}
          />
          <Wrapper apiKey={GOOGLE_API_KEY} libraries={["places"]}>
            <GoogleAutocomplete
              apiKey={GOOGLE_API_KEY}
              country={configuration.partner.country}
              placeholder={t("locate_me_page.input.placeholder")}
              size="large"
              onPlaceSelected={(place) => {
                const state: LocationState = {
                  name: place.formatted_address ?? "",
                  coordinates: {
                    latitude: place.geometry?.location?.lat() ?? 0,
                    longitude: place.geometry?.location?.lng() ?? 0,
                  },
                  postalCode:
                    place.address_components
                      ?.filter((component) =>
                        component.types.includes("postal_code")
                      )
                      .at(0)?.long_name ?? "",
                  city:
                    place.address_components
                      ?.filter((component) =>
                        component.types.includes("locality")
                      )
                      .at(0)?.long_name ?? "",
                  country:
                    place.address_components
                      ?.filter((component) =>
                        component.types.includes("country")
                      )
                      .at(0)?.long_name ?? "",
                };

                navigate("../confirm-address", {
                  state,
                });
              }}
              imgSrc="/assets/images/icons/search-icon.svg"
            />
          </Wrapper>
          <div className="button-container">
            <ButtonAction
              primary={false}
              buttonType="button"
              onClick={() => navigate("../confirm-address")}
            >
              <>
                <img src="/assets/images/icons/location-icon.svg" alt="" />
                {t("locate_me_page.locate_me")}
              </>
            </ButtonAction>
          </div>
        </div>
      </div>
    );
}

export default LocateMe;
