import "./signout-icloud.scss";

import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { ButtonBack } from "@greenpanda/ui/button-back";
import { TopTitle } from "@greenpanda/ui/top-title";
import { ButtonAction } from "@greenpanda/ui/button-action";
import { HowTo } from "@greenpanda/ui/how-to";

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper";
import React, { useContext } from "react";

import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../utils/context";

export function SignoutIcloud() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { configuration } = useContext(AuthContext);

  const INSTRUCTIONS_SIGNOUT = [
    t("signout_icloud_page.instructions.item_1"),
    t("signout_icloud_page.instructions.item_2"),
    t("signout_icloud_page.instructions.item_3"),
    t("signout_icloud_page.instructions.item_4"),
  ];

  return (
    <div className="signout-icloud-page">
      <div className="container">
        <NavbarHeader>
          <ButtonBack
            iconType="back"
            backButtonType="button"
            onClick={() => navigate(-1)}
            imageLink={configuration.images_link}
          />
        </NavbarHeader>
        <TopTitle
          title={t("signout_icloud_page.top_title.title")}
          description={t("signout_icloud_page.top_title.description")}
        />
        <div className="button-container">
          <ButtonAction primary={true} buttonType="button">
            {t("signout_icloud_page.already_signed_out")}
          </ButtonAction>
        </div>
        <HowTo
          instructions={INSTRUCTIONS_SIGNOUT}
          title={t("signout_icloud_page.instructions.title")}
        />
        <div className="how-to-slider">
          <Swiper
            modules={[FreeMode]}
            spaceBetween={6}
            effect={"coverflow"}
            slidesPerView={1.2}
            freeMode={true}
            slidesOffsetAfter={16}
            pagination={{ clickable: true }}
          >
            <SwiperSlide>
              <img src="/assets/images/icloud-signout-1.png" alt="" />
              <p>{t("signout_icloud_page.walkthrough.slide_1")}</p>
            </SwiperSlide>
            <SwiperSlide>
              <img src="/assets/images/icloud-signout-2.png" alt="" />
              <p>{t("signout_icloud_page.walkthrough.slide_2")}</p>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="button-container settings">
          <ButtonAction
            small={true}
            primary={true}
            buttonType="link"
            to="../device-identified"
          >
            {t("signout_icloud_page.goto_settings")}
          </ButtonAction>
        </div>
      </div>
    </div>
  );
}

export default SignoutIcloud;
