import "./pandas-kiosks.scss";

import { useEffect, useRef, useCallback, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Wrapper, Status } from "@googlemaps/react-wrapper";

import { NavbarHeader } from "@greenpanda/ui/navbar-header";
import { ButtonBack } from "@greenpanda/ui/button-back";
import { getLocations } from "../../data-access/tradein-service";
import {
  tradeInActions,
  tradeInLocationsSelector,
  tradeInAddressSelector,
} from "../../data-access/tradein-reducer";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { ButtonAction } from "@greenpanda/ui/button-action";
import TagManager from "react-gtm-module";
import { GOOGLE_API_KEY } from "../../../../constants";
import { AuthContext } from "../../../utils/context";

const tagManagerArgs = {
  dataLayer: {
    event: "Step All Map Locations",
  },
};

export function PandasKiosks() {
  TagManager.dataLayer(tagManagerArgs);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { configuration } = useContext(AuthContext);

  const googleMapsRef = useRef<HTMLDivElement>(null);

  const tradeInLocations = useSelector(tradeInLocationsSelector);
  const tradeInAddress = useSelector(tradeInAddressSelector);

  const [googleMaps, setGoogleMaps] = useState<google.maps.Map>();

  const handleWrapperCallback = useCallback((status: Status) => {
    if (status === "SUCCESS" && googleMapsRef.current !== null) {
      const map = new window.google.maps.Map(googleMapsRef.current, {
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        zoomControl: false,
      });

      setGoogleMaps(map);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const locations = await getLocations({
        partner: configuration.partner.name,
      });
      dispatch(tradeInActions.setLocations(locations));
    };

    fetchData();
  }, [dispatch, configuration]);

  useEffect(() => {
    if (typeof googleMaps !== "undefined" && tradeInLocations.length > 0) {
      const infoWindow = new google.maps.InfoWindow();

      tradeInLocations
        .filter(
          (tradeInLocation) =>
            tradeInLocation.type === "atm" || tradeInLocation.type === "app"
        )
        .forEach((tradeInLocation) => {
          const marker = new window.google.maps.Marker({
            map: googleMaps,
            position: {
              lat: tradeInLocation.lat,
              lng: tradeInLocation.lng,
            },
            icon: `${configuration.images_link}/images/icons/kiosk-icon.svg`,
          });
          marker.addListener("click", () => {
            infoWindow.setContent(`<div>
            <p class="title">${tradeInLocation.name}</p>
            <p class="description">${tradeInLocation.partner_name}</p>
            <p class="description">${tradeInLocation.address}</p>
            <a class="link" href=${tradeInLocation.location}>Google Maps</a>
            </div>`);
            infoWindow.open({
              anchor: marker,
              map: googleMaps,
              shouldFocus: true,
            });
          });
        });

      new window.google.maps.Marker({
        map: googleMaps,
        position: {
          lat: tradeInAddress.coordinates.latitude,
          lng: tradeInAddress.coordinates.longitude,
        },
      });

      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend(
        new window.google.maps.LatLng(
          configuration.partner.flow === "orange"
            ? tradeInLocations[0].lat
            : tradeInAddress.coordinates.latitude,
          configuration.partner.flow === "orange"
            ? tradeInLocations[0].lng
            : tradeInAddress.coordinates.longitude
        )
      );
      googleMaps.fitBounds(bounds);
      googleMaps.setZoom(11);
    }
  }, [googleMaps, tradeInLocations, tradeInAddress, configuration]);
  if (!GOOGLE_API_KEY) return <div></div>;
  else
    return (
      <div id="trade-in-pandas-kiosks-page">
        <NavbarHeader>
          <ButtonBack
            iconType="back"
            backButtonType="button"
            onClick={() => navigate(-1)}
            style={{ marginTop: 6, marginLeft: 16 }}
            imageLink={configuration.images_link}
          />
          <p className="title">
            {configuration.partner.flow === "pandas"
              ? t("trade-in-pandas-kiosks-page.title")
              : t(
                  `trade-in-pandas-kiosks-page.title_${configuration.partner.flow}`
                )}
          </p>
        </NavbarHeader>
        <div
          className={`google-maps ${configuration.at_store_flow && "store"}`}
        >
          <Wrapper
            apiKey={GOOGLE_API_KEY}
            libraries={["places"]}
            render={() => {
              return <div ref={googleMapsRef} />;
            }}
            callback={(status) => handleWrapperCallback(status)}
          >
            <div ref={googleMapsRef} />
          </Wrapper>
        </div>
        {configuration.at_store_flow && (
          <div id="button-holder">
            <ButtonAction
              primary={true}
              buttonType="button"
              onClick={() => {
                navigate("../thank-you-store");
              }}
            >
              {t("trade-in-pandas-kiosks-page.button_store")}
            </ButtonAction>
          </div>
        )}
      </div>
    );
}

export default PandasKiosks;
