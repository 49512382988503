import { useCallback, useEffect, useRef, useState } from "react";
import { Observable } from "rxjs";

export type UseObservableOptions<T> = {
  canTriggerValueChangeDetection?: boolean;
  canTriggerErrorChangeDetection?: boolean;
  nextCallback?: (value: T) => void;
  errorCabllback?: (error: Error) => void;
  completeCallback?: () => void;
  onCleanup?: () => void;
  runIf?: () => boolean;
};

export function useObservable<T>(
  observable: Observable<T>,
  {
    canTriggerValueChangeDetection = true,
    canTriggerErrorChangeDetection = true,
    nextCallback,
    errorCabllback,
    completeCallback,
    onCleanup,
  }: UseObservableOptions<T> = {}
) {
  const [stream, setStream] = useState<T>();
  const [error, setError] = useState<Error>();
  const effectRan = useRef(false);

  const setStateOrError = useCallback(
    (value: T | Error) => {
      if (value instanceof Error) {
        errorCabllback?.(value);
        if (canTriggerErrorChangeDetection) setError(value);
      } else {
        nextCallback?.(value);
        if (canTriggerValueChangeDetection) setStream(value);
      }
    },
    [
      canTriggerValueChangeDetection,
      canTriggerErrorChangeDetection,
      errorCabllback,
      nextCallback,
    ]
  );

  useEffect(() => {
    if (effectRan.current) return;
    effectRan.current = true;
    const subscription = observable.subscribe({
      next: (v) => setStateOrError(v),
      error: (e) => setStateOrError(e),
      complete: () => completeCallback?.(),
    });
    return () => {
      effectRan.current = false;
      subscription.unsubscribe();
      onCleanup?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { stream, error };
}
