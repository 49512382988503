import "./navbar-header.scss";

export interface NavbarHeaderProps {
  children?: JSX.Element | Array<JSX.Element>;
}

export function NavbarHeader(props: NavbarHeaderProps) {
  return <div className="navbar-header">{props.children}</div>;
}

export default NavbarHeader;
