import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";

import evaluationReducer from "./evaluation/data-access/evaluation-reducer";
import tradeInReducer from "./tradein/data-access/tradein-reducer";
import appReducer from "./data-access/app-reducer";

const reducersCombined = combineReducers({
  app: appReducer,
  evaluation: evaluationReducer,
  tradeIn: tradeInReducer,
});

const persistedReducer = persistReducer(
  { key: "root", storage: storageSession },
  reducersCombined
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
});

export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
